import Header from "../../component/Header"
import Footer from "../../component/Footer"
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { ApiService } from "../../component/services/apiServices";
import MobileHeader from "../../component/Element/mobile_header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accountsidebar from "../Account/account_sidebar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import moment from "moment";
import Skeleton from "react-loading-skeleton";



const Orderdetails = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  let apiServices = new ApiService();
  const [OrderData, setOrderdata] = useState()
  const [detailsVisibility, setDetailsVisibility] = useState([]);
  const [Orderdetails, setOrderdetails] = useState();
  const [ordertransid, setordertransid] = useState("")
  const [showskeleton, setshowskeleton] = useState(false)
  const [showmodal, setshowmodal] = useState(false)
  const [multipleorderdetail, setmultipleorderdetail] = useState([])

  const [UserData, setUserdata] = useState("")
  const didMountRef = useRef(true)
  const Userdata = JSON.parse(localStorage.getItem("USER_SESSION"));
  useEffect(() => {
    if (didMountRef.current) {
      setshowskeleton(true)
      const dataString = {
        trans_order_number: id
      }

      apiServices.getorderdetailsPostRequest(dataString).then(res => {

        if (res.data.status == 'success') {
          setOrderdetails(res?.data)
          setOrderdata(res?.data?.orderData)
          setmultipleorderdetail(res?.data?.transDataByDay)
          setshowskeleton(false)
        }
        else if (res?.data?.status == "session_expired") {
          localStorage.removeItem("USER_SESSION")
          setshowskeleton(false)
        }
        else {
          setshowskeleton(false)
        }
      })

    }
    didMountRef.current = false;
  })
  const toggleDetailsMon = (index) => {
    const updatedVisibility = [...detailsVisibility];
    updatedVisibility[index] = !updatedVisibility[index];
    setDetailsVisibility(updatedVisibility);
  };


  const handleClose = () => {
    setshowmodal(false)
  }

  const groupByCategory = (data, index) => {
    return data.reduce((result, currentItem) => {
      const { ta_tiffin_cat_name, ...rest } = currentItem;
      if (!result[ta_tiffin_cat_name]) {
        result[ta_tiffin_cat_name] = [currentItem?.td_tiffin_item_name];
      }
      else {
        result[ta_tiffin_cat_name].push(currentItem?.td_tiffin_item_name);
      }
      // if (currentIndex === index) {

      // }
      return result;
    }, {});
  };

  const Orderstataus = (status) => {
    switch (status) {
      case 1:
        return "Confirmed"
        break;
      case 2:
        return "Order Recieved";
        break;
      case 3:
        return "Preparing";
        break;
      case 4:
        return "Ready";
        break;
      case 5:
        return "On the way";
        break;
      case 6:
        return 'Delivered';
        break;
      case 7:
        return 'Refund';
        break;
      case 8:
        return 'canceled';
        break;
      default:
        return 'Order status';
        break;
    }
  }


  return (<>
    <BrowserView>
      <Header />
      <div className="subheader">
        <Container>
          <Row>
            <Col lg={12}>
              <Breadcrumb className="breadcrumb-inner">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/account/orders">Orders</Breadcrumb.Item>
                <Breadcrumb.Item active>Order Detail</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="">
        <section className="secmedium">
          <Container>
            <Row>
              <Accountsidebar />
              <Col lg={9}>
                <div className="acpanel">
                  <div className="acpanel-header">
                    <h4>{id}</h4>
                  </div>
                  <div className="acpanel-body">


                    <div className="order-box">
                      <div className="info">
                        <div className="info-delivery">
                          {showskeleton ? <><Skeleton height={"200px"}></Skeleton></> : <>
                            <h6 class="mb-1 tx-14">Delivery Address</h6>
                            <p class="mb-0 tx-13"><strong>{OrderData?.trans_user_name}</strong></p>
                            <p class="mb-0 tx-13"> {OrderData?.trans_delivery_address}</p>
                            <p class="mb-0 tx-13">Email Id : {OrderData?.trans_user_email}</p>
                            <p class="tx-13">Mobile No : {OrderData?.trans_user_mobile}</p>
                          </>}
                        </div>

                      </div>
                      <div className="bcode">
                        {showskeleton ? <>
                          <Skeleton height={"200px"}></Skeleton>
                        </> : <>

                          <div class="orderid-box mb-5"><h6 class="mb-0">ORDER ID</h6><p class="mb-0 tx-13">{OrderData?.trans_order_number}</p></div>
                          <p class="tx-color-03 mb-0 tx-13">ORDER ON</p>
                          <p class="tx-12">
                            {moment(OrderData?.created_at).format('MMM D, YYYY')}
                          </p>
                        </>}
                      </div>
                    </div>
                    <div class="card-table">
                      {showskeleton ? <>
                        <Skeleton></Skeleton>
                        <Skeleton></Skeleton>
                        <Skeleton></Skeleton>
                        <Skeleton></Skeleton>
                      </> : <>
                        {multipleorderdetail &&
                          Object.entries(multipleorderdetail).length > 0 && Object.entries(multipleorderdetail).map(([childitem_day, childitem], index) => {
                            return (<>
                              {childitem?.rowOrdersData?.length > 0 ? <>
                                <div class="card-table-header" key={index} style={{ width: '100%', background: '#f5f5f5', padding: '12px' }}>
                                  {childitem_day} Order Items  <span className="morderbox-status bg-light-yellow " style={{ display: 'inline-block', float: "right", marginRight: "20px" }}>{Orderstataus(childitem?.dayWiseStatusDate?.td_order_status)}</span></div>
                                <div class="card-table-section">
                                  <table class="table table-hover">
                                    <thead>
                                      <tr>
                                        <th class="text-center">S.No</th>
                                        <th>Image</th>
                                        <th>Title</th>
                                        <th class="text-center">QTY</th>
                                        <th class="text-center">Price</th>
                                        <th class="text-center">Amount</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        childitem?.rowOrdersData?.map((items, index) => {
                                          return (<>
                                            <tr>
                                              <td class="text-center">{index + 1}</td>
                                              <td class="text-center">
                                                <img src={items?.td_item_image ? items?.td_item_image : "./img/no_img1.jpg"} alt="https://www.balhath.com/csadmin/public/img/uploads/products/22011700219723.jpeg" style={{ width: "35px" }} />
                                              </td>
                                              <td>{items?.td_item_title}<br />  {items?.tiffin_items?.length > 0 ? <>

                                                <p onClick={() => toggleDetailsMon(index)}>
                                                  {detailsVisibility[index] ? "Hide details " : " View Details"}
                                                </p>
                                                {detailsVisibility[index] && (<>

                                                  {Object?.entries(groupByCategory(items?.tiffin_items)).map((category, index) => {
                                                    return (<>
                                                      <ul>
                                                        <p key={index}>{index + 1}. {category[0]}</p>
                                                        {category[1]?.length > 0 && category[1]?.map((items, index) => {
                                                          return (<>
                                                            <li key={index}>{items}</li>
                                                          </>)
                                                        })}
                                                      </ul>
                                                    </>)
                                                  })}
                                                </>
                                                )}
                                              </> : ""}
                                              </td>
                                              <td class="text-center">{items?.td_item_qty}</td>
                                              <td class="text-center">${(parseFloat(items?.td_item_net_price) + parseFloat(items?.td_addon_total)).toFixed(2)}</td>
                                              <td class="text-center">${((parseFloat(items?.td_item_net_price) + parseFloat(items?.td_addon_total)) * parseFloat(items?.td_item_qty)).toFixed(2)}</td>
                                            </tr>
                                          </>)
                                        })}
                                      <tr>
                                        <td colspan="3"></td>
                                        <td colspan=""></td>
                                        <td>Sub Total</td>
                                        <td class="text-center">${(parseFloat(childitem?.dayWiseAmount?.itemTotal)).toFixed(2)}</td>
                                      </tr>
                                      <tr>
                                        <td colspan="4"></td>
                                        <td>Discount</td>
                                        <td class="text-center">-${(parseFloat(childitem?.dayWiseAmount?.totalDiscount)).toFixed(2)}</td>
                                      </tr>
                                      <tr>
                                        <td colspan="4"></td>
                                        <td>Delivery Charges</td>
                                        <td class="text-center">${(parseFloat(childitem?.dayWiseAmount?.shippingAmount)).toFixed(2)}</td>
                                      </tr>
                                      <tr>
                                        <td colspan="4"></td>
                                        <td><strong>Item total Amount</strong></td>
                                        <td class="text-center"><strong>${(parseFloat(childitem?.dayWiseAmount?.transTotal)).toFixed(2)}</strong></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </> : ""}
                            </>)
                          })}
                        {/* cart Summary start */}

                        <div style={{ width: '100%', background: '#f5f5f5', padding: '12px' }}>Cart Summary</div>
                        <div class="card-table-section">
                          <table class="table table-hover">
                            <tbody>
                              <tr>
                                <td colspan="3"></td>
                                <td colspan=""></td>
                                <td>Sub Total</td>
                                <td class="text-center">${Orderdetails ? (parseFloat(Orderdetails?.finalItemTotal)).toFixed(2) : 0.00}</td>
                              </tr>
                              <tr>
                                <td colspan="4"></td>
                                <td>Discount</td>
                                <td class="text-center">-${Orderdetails ? (parseFloat(Orderdetails?.finalTotalDiscount)).toFixed(2) : 0.00}</td>
                              </tr>
                              <tr>
                                <td colspan="4"></td>
                                <td>Coupon Discount</td>
                                <td class="text-center">-${Orderdetails ? (parseFloat(Orderdetails?.orderData?.trans_coupon_dis_amt)).toFixed(2) : 0.00}</td>
                              </tr>
                              <tr>
                                <td colspan="4"></td>
                                <td>Delivery Charges</td>
                                <td class="text-center">${Orderdetails ? (parseFloat(Orderdetails?.finalShippingTotal)).toFixed(2) : 0.00}</td>
                              </tr>
                              <tr>
                                <td colspan="4"></td>
                                <td><strong>Item total Amount</strong></td>
                                <td class="text-center"><strong>${Orderdetails ? (parseFloat(Orderdetails?.finalOrderTotal) - parseFloat(Orderdetails?.orderData?.trans_coupon_dis_amt)).toFixed(2) : 0.00}</strong></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {/* cart Summary End */}
                      </>}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>

    </BrowserView>

    <MobileView>
      <div className="mwapper">
        <header className="mheader">
          <div className="mheader-left">
            <i
              className="d-icon-arrow-left tx-22"
              onClick={() => {
                navigate("/account/orders");
              }}
            ></i>
            <p className="mb-0 mt-1 ms-3">Order details</p>
          </div>
        </header>
        <div className="panel p-3" style={{ background: '#fff', border: '0px' }}>
          <div className="panel-header mb-3">Shipping & Billing Address</div>
          <p className="mb-0 mt-0">
            <strong>{OrderData?.trans_user_name}</strong>
          </p>
          {OrderData?.trans_delivery_address ?<> <p className="tx-12">{OrderData?.trans_delivery_address}</p></>:""}      
          <p className="mb-0 mt-0">
            <strong>Email Id: </strong>
            {OrderData?.trans_user_email}
          </p>
          <p className="mb-0 mt-0">
            <strong>Phone No: </strong>
            {OrderData?.trans_user_mobile}
          </p>
        </div>
        <div className="bcode p-3">
                      <div className="orderid-box"><h6 className="mb-0">ORDER ID</h6><p className="mb-0 tx-13">{id}</p></div>
                      <p className="tx-color-03 mb-0 tx-13">ORDER ON</p>
                      <p className="tx-12">
                        {moment(OrderData?.created_at).format('MMM D, YYYY')}
                      </p>
                     
        </div>
        <div className="acpanel">
                <div className="acpanel-body">
                  <div class="card-table">
                    {showskeleton ? <>
                      <Skeleton></Skeleton>
                      <Skeleton></Skeleton>
                      <Skeleton></Skeleton>
                      <Skeleton></Skeleton>
                    </> : <>
                      {multipleorderdetail &&
                        Object.entries(multipleorderdetail).length > 0 && Object.entries(multipleorderdetail).map(([childitem_day, childitem], index) => {
                          return (<>
                            {childitem?.rowOrdersData?.length > 0 ? <>
                              <div class="card-table-header" key={index} style={{ width: '100%', background: '#f5f5f5', padding: '12px' }}>
                                {childitem_day} Order Items  <span className="morderbox-status bg-light-yellow " style={{ display: 'inline-block', float: "right", marginRight: "20px" }}>{Orderstataus(childitem?.dayWiseStatusDate?.td_order_status)}</span></div>
                              <div class="card-table-section">
                                <table class="table table-hover">
                                  <thead>
                                    <tr>
                                      <th class="text-center">S.No</th>
                                      <th>Image</th>
                                      <th>Title</th>
                                      <th class="text-center">QTY</th>
                                      <th class="text-center">Price</th>
                                      <th class="text-center">Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      childitem?.rowOrdersData?.map((items, index) => {
                                        return (<>
                                          <tr>
                                            <td class="text-center">{index + 1}</td>
                                            <td class="text-center">
                                              <img src={items?.td_item_image ? items?.td_item_image : "./img/no_img1.jpg"} alt="https://www.balhath.com/csadmin/public/img/uploads/products/22011700219723.jpeg" style={{ width: "35px" }} />
                                            </td>
                                            <td>{items?.td_item_title}<br />  {items?.tiffin_items?.length > 0 ? <>

                                              <p onClick={() => toggleDetailsMon(index)}>
                                                {detailsVisibility[index] ? "Hide details " : " View Details"}
                                              </p>
                                              {detailsVisibility[index] && (<>

                                                {Object?.entries(groupByCategory(items?.tiffin_items)).map((category, index) => {
                                                  return (<>
                                                    <ul>
                                                      <p key={index}>{index + 1}. {category[0]}</p>
                                                      {category[1]?.length > 0 && category[1]?.map((items, index) => {
                                                        return (<>
                                                          <li key={index}>{items}</li>
                                                        </>)
                                                      })}
                                                    </ul>
                                                  </>)
                                                })}
                                              </>
                                              )}
                                            </> : ""}
                                            </td>
                                            <td class="text-center">{items?.td_item_qty}</td>
                                            <td class="text-center">${(parseFloat(items?.td_item_net_price) + parseFloat(items?.td_addon_total)).toFixed(2)}</td>
                                            <td class="text-center">${((parseFloat(items?.td_item_net_price) + parseFloat(items?.td_addon_total)) * parseFloat(items?.td_item_qty)).toFixed(2)}</td>
                                          </tr>
                                        </>)
                                      })}
                                    <tr>
                                      <td colspan="3"></td>
                                      <td colspan=""></td>
                                      <td>Sub Total</td>
                                      <td class="text-center">${(parseFloat(childitem?.dayWiseAmount?.itemTotal)).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                      <td colspan="4"></td>
                                      <td>Discount</td>
                                      <td class="text-center">-${(parseFloat(childitem?.dayWiseAmount?.totalDiscount)).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                      <td colspan="4"></td>
                                      <td>Delivery Charges</td>
                                      <td class="text-center">${(parseFloat(childitem?.dayWiseAmount?.shippingAmount)).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                      <td colspan="4"></td>
                                      <td><strong>Item total Amount</strong></td>
                                      <td class="text-center"><strong>${(parseFloat(childitem?.dayWiseAmount?.transTotal)).toFixed(2)}</strong></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </> : ""}
                          </>)
                        })}

                      <div style={{ width: '100%', background: '#f5f5f5', padding: '12px' }}>Cart Summary</div>
                      <div class="card-table-section">
                        <table class="table table-hover">
                          <tbody>
                            <tr>
                              <td colspan="3"></td>
                              <td colspan=""></td>
                              <td>Sub Total</td>
                              <td class="text-center">${Orderdetails ? (parseFloat(Orderdetails?.finalItemTotal)).toFixed(2) : 0.00}</td>
                            </tr>
                            <tr>
                              <td colspan="4"></td>
                              <td>Discount</td>
                              <td class="text-center">-${Orderdetails ? (parseFloat(Orderdetails?.finalTotalDiscount)).toFixed(2) : 0.00}</td>
                            </tr>
                            <tr>
                              <td colspan="4"></td>
                              <td>Coupon Discount</td>
                              <td class="text-center">-${Orderdetails ? (parseFloat(Orderdetails?.orderData?.trans_coupon_dis_amt)).toFixed(2) : 0.00}</td>
                            </tr>
                            <tr>
                              <td colspan="4"></td>
                              <td>Delivery Charges</td>
                              <td class="text-center">${Orderdetails ? (parseFloat(Orderdetails?.finalShippingTotal)).toFixed(2) : 0.00}</td>
                            </tr>
                            <tr>
                              <td colspan="4"></td>
                              <td><strong>Item total Amount</strong></td>
                              <td class="text-center"><strong>${Orderdetails ? (parseFloat(Orderdetails?.finalOrderTotal) - parseFloat(Orderdetails?.orderData?.trans_coupon_dis_amt)).toFixed(2) : 0.00}</strong></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>}
                  </div>
                </div>
        </div>
       
      </div>
    </MobileView>

  </>)
}
export default Orderdetails