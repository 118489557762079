import React, { useState } from "react";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";

function AboutUs() {
  const navigate = useNavigate();
  return (
    <div>
      <BrowserView>
        <Header></Header>
        <div>about us</div>
        <Footer></Footer>
      </BrowserView>

      <MobileView>
        <div className="mwapper">
          <header className="mheader">
            <div className="mheader-left">
              <i
                className="d-icon-arrow-left tx-22"
                onClick={() => {
                  navigate("/account/account-overview");
                }}
              ></i>
              <p className="mb-0 mt-1 ms-3">About us</p>
            </div>
          </header>
          <div className="mlist">
            <ul>
              <li onClick={() => navigate("/shipping-returns")}>
                Shipping & Returns
                <i className="d-icon-angle-right"></i>
              </li>
              <li onClick={() => navigate("/about-us")}>
                About Us
                <i className="d-icon-angle-right"></i>
              </li>
              <li onClick={() => navigate("/term-conditions")}>
                Terms of Service
                <i className="d-icon-angle-right"></i>
              </li> 
              <li onClick={() => navigate("/faqs")}>
                Frequently Asked Questions
                <i className="d-icon-angle-right"></i>
              </li>
              <li onClick={() => navigate("/privacy-policy")}>
                Privacy Policy
                <i className="d-icon-angle-right"></i>
              </li>
              {/* <li onClick={() => navigate("/track-order")}>
                Track Order
                <i className="d-icon-angle-right"></i>
              </li> */}
              <li onClick={() => navigate("/contact-us")}>
                Contact Us
                <i className="d-icon-angle-right"></i>
              </li>
            </ul>
          </div>
        </div>
      </MobileView>
    </div>
  );
}

export default AboutUs;
