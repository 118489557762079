import Modal from "react-bootstrap/Modal";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState, useContext } from "react";
import { ApiService } from "../../component/services/apiServices";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import DataContext from '../../component/Element/context/index';
import getInfo from "../../component/Element/utils/commonUtils";
import { formatTime } from "../../component/Element/utils/dateTimeUtils";
import Alert from 'react-bootstrap/Alert';
import SpinnerLoader from "../../component/Element/utils/spinnerLoader";
import { showToast } from "../../component/Element/utils/toastUtils";
import Skeleton from "react-loading-skeleton";

const QuickViewmodal = ({ show, setShow, handleClose, handleShow, productid }) => {
  const popclose = () => {
    handleClose();
  };

  const { id } = useParams();
  const navigate = useNavigate();
  let apiServices = new ApiService();
  const { Data, setData, setcartdata, cartdata, setcartCount } = useContext(DataContext);
  const { dayOfWeek, dayOfMonth, time, formattedTime } = getInfo();
  const [quantity, setQuantity] = useState(1);
  const { slug } = useParams()
  const [productData, setproductdata] = useState("")
  const [errorMessageLunch, seterrorMessageLunch] = useState("")
  const [errorMessageDinner, seterrorMessageDinner] = useState("")
  const [adminData, setadminData] = useState()
  const [baseurl, setbaseurl] = useState("")
  const [timeFormat, settimeFormat] = useState()
  const [showskeleton, setshowskeleton] = useState(false)
  const [dayTimeActive, setDayTimeActive] = useState("")
  const [itemDayTimeActive, setItemDayTimeActive] = useState("")
  const [lunchDayTimeStatus, setLunchDayTimeStatus] = useState(false)
  const [dinnerDayTimeStatus, setDinnerDayTimeStatus] = useState(false)
  const didMountRef = useRef(true)
  const data = localStorage.getItem("slug")
  const [selectedItems, setSelectedItems] = useState([]);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [dates, setDates] = useState([]);
  useEffect(() => {
    if (didMountRef.current) {
      if (data !== "") {
        setData(data)
      }
      getDates();
      setshowskeleton(true)
      setSpinnerLoading(true)
      const datastrings = { product_id: productid }
      apiServices.getcategorydetailpostRequest(datastrings).then(res => {
        if (res.data.status == 'success') {
          setproductdata(res?.data)
          setbaseurl(res?.data?.item_img_path)
          setadminData(res?.data?.adminData)
          setshowskeleton(false)
          settimeFormat(formatTime(res?.data?.adminData.lunch_end_time))
          if (formattedTime > res?.data?.adminData?.lunch_end_time && res?.data?.menuData.product_day == dayOfWeek) {
            seterrorMessageLunch('Lunch order time has ended for today so delivery is not availale, However, you can choose to pick up your order from the restaurant.')
            setDayTimeActive('dinner')
            setLunchDayTimeStatus(true)
          } else {
            setDayTimeActive('lunch')
          }
          if (formattedTime > res?.data?.adminData?.dinner_end_time && res?.data?.menuData.product_day == dayOfWeek) {
            seterrorMessageDinner('Dinner order time has ended for today so delivery is not availale, However, you can choose to pick up your order from the restaurant.')
            setDayTimeActive('dinner')
            setDinnerDayTimeStatus(true)
          }
          let updatedSelection = [];
          if (res?.data?.tiffinItem?.length > 0) {
            res?.data.tiffinItem.forEach((items) => {
              if (
                items?.tiffin_category?.addon_category == 0 &&
                items?.menu_items.length > 0
              ) {
                items.menu_items.forEach((itemsChild) => {
                  if (items?.pti_moq === 0) {
                    updatedSelection.push({
                      ptiId: items.pti_id,
                      itemId: itemsChild.item_id,
                      ptimoq: items.pti_moq,
                      addoncategory: items.tiffin_category.addon_category,
                    });
                    setSelectedItems(updatedSelection);
                  }
                });
              }
            });
          }
          setTimeout(() => {
            setSpinnerLoading(false)
          }, 500);
        }
      }).catch((error) => {
        setTimeout(() => {
          setSpinnerLoading(false)
        }, 500);
      })

    }
    didMountRef.current = false;
  })

  const getDates = () => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const shortDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const today = new Date();
    const nextSevenDays = Array.from({ length: 7 }, (_, index) => {
      const currentDate = new Date(today);
      currentDate.setDate(today.getDate() + index);
      return {
        date: currentDate.toLocaleDateString('en-US', { day: 'numeric' }),
        day: days[currentDate.getDay()],
        shortDay: shortDays[currentDate.getDay()]
      };
    });
    setDates(nextSevenDays);
  };
  const chooseDayTime = (day) => {
    if (formattedTime > adminData.lunch_end_time && (productData?.menuData?.product_day == dayOfWeek || productData?.menuData.product_day == 'Select One')) {
      setLunchDayTimeStatus(true)
      seterrorMessageLunch('Lunch order time has ended for today so delivery is not availale, However, you can choose to pick up your order from the restaurant.')
    } else {
      setLunchDayTimeStatus(false)
    }
    if (formattedTime > adminData.dinner_end_time && (productData?.menuData?.product_day == dayOfWeek || productData?.menuData.product_day == 'Select One')) {
      setDinnerDayTimeStatus(true)
      seterrorMessageDinner('Dinner order time has ended for today so delivery is not availale, However, you can choose to pick up your order from the restaurant.')
    } else {
      setDinnerDayTimeStatus(false)
    }
    setDayTimeActive(day)
  }

  const chooseItemDayTime = (day) => {
    if (formattedTime > adminData.lunch_end_time && day == dayOfWeek) {
      setLunchDayTimeStatus(true)
      seterrorMessageLunch('Lunch order time has ended for today so delivery is not availale, However, you can choose to pick up your order from the restaurant.')
    } else {
      setLunchDayTimeStatus(false)
      seterrorMessageLunch('Click and continue for lunch, order before ' + day + ', ' + formatTime(adminData?.lunch_end_time)?.formattedHourMin)
    }
    if (formattedTime > adminData.dinner_end_time && day == dayOfWeek) {
      setDinnerDayTimeStatus(true)
      seterrorMessageDinner('Dinner order time has ended for today so delivery is not availale, However, you can choose to pick up your order from the restaurant.')
    } else {
      setDinnerDayTimeStatus(false)
      seterrorMessageDinner('Click and continue for dinner, order before ' + day + ', ' + formatTime(adminData?.dinner_end_time)?.formattedHourMin)
    }
    setItemDayTimeActive(day)
  }

  const toggleItemSelection = (e, parent, child) => {
    e.stopPropagation();
    const count = selectedItems.filter((item) => item.ptiId === parent.pti_id).length;
    const selectedItemIdIndex = selectedItems.findIndex((item) => item.ptiId == parent.pti_id && item.itemId == child.item_id);
    let updatedSelection = [...selectedItems];
    if (parent.pti_moq == 0 && parent.tiffin_category.addon_category == 0) {
      return false;
    }
    if (selectedItemIdIndex === -1) {
      if (count + Number(1) > parent.pti_moq && parent.pti_moq > 0) {
        showToast('error', 'You only can select max ' + parent.pti_moq + ' options.');
        return false;
      }
      if (parent?.tiffin_category?.addon_category > 0) {
        const productSellingPrice = parseFloat(productData?.menuData?.product_addon_price) || 0;
        const itemPrice = parseFloat(child.item_price) || 0;

        const updatedSellingPrice = (productSellingPrice + itemPrice).toFixed(2);
        //productData.menuData.product_selling_price = updatedSellingPrice;
        productData.menuData.product_addon_price = updatedSellingPrice;
      }
      updatedSelection.push({ ptiId: parent.pti_id, itemId: child.item_id, ptimoq: parent.pti_moq, addoncategory: parent.tiffin_category.addon_category, });
    } else {
      if (parent?.tiffin_category?.addon_category > 0) {
        const productSellingPrice = parseFloat(productData?.menuData?.product_addon_price) || 0;
        const itemPrice = parseFloat(child.item_price) || 0;

        const updatedSellingPrice = (productSellingPrice - itemPrice).toFixed(2);
        //productData.menuData.product_selling_price = updatedSellingPrice;
        productData.menuData.product_addon_price = updatedSellingPrice;
      }
      updatedSelection.splice(selectedItemIdIndex, 1);
    }
    setSelectedItems(updatedSelection);
  };

  const addtocart = (addproduct, purchaseType) => {
    const { counts: selectedItemsCounts } = selectedItems.reduce(
      (acc, curr) => {
        acc.counts[curr.ptiId] = {
          count: (acc.counts[curr.ptiId]?.count || 0) + 1,
          moq: curr.ptimoq,
        };
        return acc;
      },
      { counts: {} }
    );
    console.log(selectedItems, "selecteditems")
    const { counts: productDataCounts } = productData.tiffinItem.reduce(
      (acc, curr) => {
        if (curr.pti_moq > 0) {
          acc.counts[curr.pti_id] = {
            count: curr.menu_items.length,
            moq: curr.pti_moq,
            title: curr.tiffin_category.category_name,
          };
        }
        return acc;
      },
      { counts: {} }
    );

    if (addproduct.product_type == 1) {
      for (const productId in productDataCounts) {
        if (Object.prototype.hasOwnProperty.call(productDataCounts, productId)) {
          if (selectedItemsCounts[productId]) {
            if (selectedItemsCounts[productId].count === productDataCounts[productId].moq) {

            } else {
              showToast('error', 'Please ' + productDataCounts[productId].title);
              return false;
            }
          } else {
            showToast('error', 'Please ' + productDataCounts[productId].title);
            return false;
          }
        }
      }
    } else {
      if (itemDayTimeActive == '') {
        showToast('error', 'Please select delivery day');
        return false;
      }
    }
    //localStorage.removeItem("CART_SESSION");return ;
    let cartSession = localStorage.getItem("CART_SESSION");
    cartSession = cartSession ? JSON.parse(cartSession) : [];
    //console.log("cartSession",cartSession);return;
    const product = {
      product_id: Number(addproduct.product_id),
      product_name: addproduct.product_name,
      product_image: addproduct.product_image
        ? addproduct.product_image
        : '/img/no_img1.jpg',
      product_type: Number(addproduct.product_type),
      product_price: parseFloat(addproduct.product_price),
      product_selling_price: parseFloat(addproduct.product_selling_price),
      product_addon_price: addproduct.product_addon_price > 0 ? parseFloat(addproduct.product_addon_price) : 0,
      product_discount: addproduct.product_discount,
      product_variation: selectedItems,
      product_day: addproduct.product_day == 'Select One' ? itemDayTimeActive : addproduct.product_day,
      product_meal_time: dayTimeActive,
      product_meal_lunch_status: lunchDayTimeStatus,
      product_meal_dinner_status: dinnerDayTimeStatus,
      product_slug: addproduct.product_slug,
      adminData: adminData,
    };
    const existingProductIndex = cartSession.findIndex((item) => {
      return (
        item.product_id === product.product_id && item.product_meal_time === product.product_meal_time &&
        JSON.stringify(item.product_variation) ===
        JSON.stringify(product.product_variation)
      );
    });

    if (existingProductIndex !== -1) {
      cartSession[existingProductIndex].quantity += quantity;
    } else {
      cartSession.push({ ...product, quantity: quantity });
    }
    localStorage.setItem("CART_SESSION", JSON.stringify(cartSession));
    cartSession = localStorage.getItem("CART_SESSION");
    cartSession = cartSession ? JSON.parse(cartSession) : [];
    setcartdata(cartSession)
    setcartCount(cartSession.length)
    localStorage.removeItem("COUPON_SESSION");

    if (purchaseType === 1) {
      setSpinnerLoading(false)
      navigate("/cart");
    }
    showToast('success', addproduct.product_name + ' has been added to your cart.');
  };

  const handleIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="quickviewmodal"
      >
        <button
          className="pop-close"
          onClick={() => {
            popclose();
          }}
        >
        </button>
        <section className="secsmall product-single">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="product-single-media">


                  {showskeleton ? <> <Skeleton width={"100%"} height={300} /></> : <>
                    <img src={productData?.menuData?.product_image ? productData?.menuData?.product_image : "/img/no_img1.jpg"}></img>

                  </>}

                </div>
              </div>
              <div className="col-lg-7">
                <div className="product-details">

                  <h1 className="product-name">

                    {showskeleton ? <> <Skeleton width={"50%"} height={20} /></> : <>
                      {productData?.menuData?.product_name}
                    </>}


                  </h1>
                  <div className="product-meta">
                    {showskeleton ? <> <Skeleton width={"30%"} height={20} /></> : <>
                      {productData?.menuData?.product_category_name}
                    </>}

                  </div>
                  <div className="product-price">
                    {showskeleton ? <> <Skeleton width={"20%"} height={20} /></> : <>
                      <ins className="new-price">

                        ${(parseFloat(productData?.menuData?.product_selling_price) +
                          parseFloat(productData?.menuData?.product_addon_price || 0)).toFixed(2)}
                      </ins>
                      {productData?.menuData?.product_discount !== 0 ? <> <del className="old-price">${productData?.menuData?.product_price}</del></> : ""}
                    </>}


                  </div>
                  {/* <div className="ratings-container">
                  <div className="ratings-full">
                    <span className="ratings" style={{ width: '90%' }}></span>
                  </div>
                  <a href="#product-tab-reviews" className="link-to-tab rating-reviews">(6 reviews)</a>
                </div> */}
                  {productData?.menuData?.product_description_web ? <><p className="product-short-desc" dangerouslySetInnerHTML={{ __html: productData?.menuData?.product_description_web }}></p> </> : ""}
                  <hr></hr>

                  <div className="poptionlist">
                    <h3 className="poptionlist-title">Select Delivery Day & Time</h3>
                    <ul>
                      {productData?.menuData?.product_type == 0 && dates.map((itemDays, indexDays) => {
                        return (<li className={itemDayTimeActive == itemDays.day ? 'active' : ''} onClick={() => chooseItemDayTime(itemDays.day)} style={{ 'width': '50px' }} key={indexDays}>
                          <div className="poptionlist-details" style={{ textAlign: 'center' }}>
                            <div className="poptionlist-text">
                              <p className="mb-0 fw600"> {itemDays.shortDay}</p>
                              <span className="tx-12">{itemDays.date}</span>
                            </div>
                          </div>
                        </li>);
                      })}
                    </ul>
                    <ul>
                      <li className={dayTimeActive == 'lunch' ? 'active' : ''} onClick={() => chooseDayTime('lunch')} style={{ 'width': '317px' }}>
                        <div className="poptionlist-details">
                          <div className="poptionlist-img">
                            <img src={dayTimeActive == 'lunch' ? "/img/selected_meal_type.png" : "/img/unselected_meal_type.png"}></img>
                          </div>
                          <div className="poptionlist-text">
                            <p className="mb-0 fw600"> Lunch</p>
                            {errorMessageLunch ? <span className="tx-11">{errorMessageLunch} </span> : <span className="tx-11">Click and continue for lunch, order before {productData?.menuData?.product_day == 'Select One' ? dayOfWeek : productData?.menuData?.product_day}, {formatTime(adminData?.lunch_end_time)?.formattedHourMin}</span>}
                          </div>
                        </div>
                      </li>
                      <li className={dayTimeActive == 'dinner' ? 'active' : ''} onClick={() => { chooseDayTime('dinner') }} style={{ 'width': '317px' }}>
                        <div className="poptionlist-details">
                          <div className="poptionlist-img">
                            <img src={dayTimeActive == 'dinner' ? "/img/selected_meal_type.png" : "/img/unselected_meal_type.png"}></img>
                          </div>
                          <div className="poptionlist-text">
                            <p className="mb-0 fw600"> Dinner</p>
                            {errorMessageDinner ? <span className="tx-11"> {errorMessageDinner} </span> : <span className="tx-11">Click and continue for Dinner, order before {productData?.menuData?.product_day == 'Select One' ? dayOfWeek : productData?.menuData?.product_day}, {formatTime(adminData?.dinner_end_time)?.formattedHourMin}</span>}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {productData?.tiffinItem?.length > 0 && productData.tiffinItem.map((items, index) => {
                    return (
                      items?.tiffin_category?.addon_category === 0 ?
                        <div className="poptionlist" key={index}>
                          <h3 className="poptionlist-title">{items?.tiffin_category?.category_name}</h3>
                          {items?.menu_items.length > 0 &&
                            <ul>
                              {items?.menu_items?.map((itemsChild, indexchild) => {
                                const isActive = selectedItems.some(item => item.ptiId === items.pti_id && item.itemId === itemsChild.item_id);
                                return (
                                  <li className={isActive ? 'active' : ''} onClick={(e) => toggleItemSelection(e, items, itemsChild)} key={indexchild}>
                                    <div className="poptionlist-details">
                                      <div className="poptionlist-img">
                                        <img src={(itemsChild?.item_image !== "" && itemsChild?.item_image !== null && baseurl) ? `${baseurl}/${itemsChild?.item_image}` : "/img/no_img.jpg"} alt={itemsChild?.item_name} />
                                      </div>
                                      <div className="poptionlist-text">
                                        <p className="mb-0 fw600">{itemsChild?.item_name}</p>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          }
                        </div>
                        :
                        <div className="poptionlist poptionlist-second" key={index}>
                          <h3 className="poptionlist-title">{items?.tiffin_category?.category_name}</h3>
                          {items?.menu_items.length > 0 &&
                            <ul>
                              {items?.menu_items?.map((itemsChild, indexchild) => {
                                const isActive = selectedItems.some(item => item.ptiId === items.pti_id && item.itemId === itemsChild.item_id);
                                return (
                                  <li className={isActive ? 'active' : ''} key={indexchild}>
                                    <label className="container-checkbox" style={{ fontWeight: '600', fontSize: '12px' }}>
                                      {itemsChild?.item_name} +${itemsChild?.item_price}
                                      <input type="checkbox" value={itemsChild?.item_name} onClick={(e) => toggleItemSelection(e, items, itemsChild)} />
                                      <span className="checkmark"></span>
                                    </label>
                                  </li>
                                );
                              })}
                            </ul>
                          }
                        </div>
                    );
                  })}

                  <hr></hr>

                  <div className="product-button">
                    <div className="pbqty">
                      <button className="quantity-minus d-icon-minus" onClick={handleDecrease}></button>
                      <input type="number" className="quantity" value={quantity} readOnly></input>
                      <button className="quantity-plus d-icon-plus" onClick={handleIncrease}></button>
                    </div>
                    <button className="btn btn-primary me-2" onClick={(e) => addtocart(productData.menuData, 0)}><i className="d-icon-bag"></i>Add To Cart</button>
                    <button className="btn btn-primary" onClick={(e) => addtocart(productData.menuData, 1)} >Buy Now</button>
                  </div>
                  <hr></hr>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
};

export default QuickViewmodal;
