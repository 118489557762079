import React, { useEffect, useState, useRef } from "react";
import Col from "react-bootstrap/Col";
import { ApiService } from "../../component/services/apiServices";
import { useLocation, useNavigate } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import localStorageData from "../../component/Element/utils/localStorageData";
const Accountsidebar = ({ Username }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const didMountRef = useRef(true);
  const location = useLocation();
  const dataArray = localStorageData();
  const userSession = dataArray['UserSession'];
  useEffect(() => {
    if (didMountRef.current) {
    }
    didMountRef.current = false;
  }, []);

  const logOut = () => {
    setShow(true);
  };

  const Confirm = () => {
    localStorage.removeItem("USER_SESSION");
    navigate("/");
  };

  const Cancelalert = () => {
    setShow(false);
  };

  return (
    <>
      <Col lg={3}>
        <div className="account-sidebar">
          <div className="account-user mb-3">
            <div className="au-imag">
              <img src="/img/user.png" />
            </div>
            <a href="/account/profile">
              <div className="au-content">
                <h6 className="mb-0" style={{ fontSize: '14px' }}>
                  Hi! {location?.pathname == "/account/profile" && userSession.user_name !== "" ? userSession.user_name?.toUpperCase() : userSession?.user_name?.toUpperCase()}
                </h6>
                <p className="mb-0">{userSession?.user_email}</p>
              </div>
            </a>
          </div>
          <div className="aclist mb-3">
            <ul>
              <li className={location?.pathname == "/account/account-overview" ? "active" : ""}>
                <Link to="/account/account-overview">
                  Account Overview<i className="d-icon-angle-right"></i>
                </Link>
              </li>
              <li className={location?.pathname == "/account/profile" ? "active" : ""}>
                <Link to="/account/profile">
                  Profile<i className="d-icon-angle-right"></i>
                </Link>
              </li>
              <li className={location?.pathname == "/account/address" ? "active" : ""}>
                <Link to="/account/address">
                  Address<i className="d-icon-angle-right"></i>
                </Link>
              </li>
              <li className={location?.pathname == "/account/orders" ? "active" : ""}>
                <Link to="/account/orders">
                  My Orders<i className="d-icon-angle-right"></i>
                </Link>
              </li>
              <li className={location?.pathname == "/account/wallet" ? "active" : ""}>
                <Link to="/account/wallet">
                  My Wallet<i className="d-icon-angle-right"></i>
                </Link>
              </li>

              <li className={location?.pathname == "/account/change-password" ? "active" : ""}>
                <Link to="/account/change-password">
                  Change Password<i className="d-icon-angle-right"></i>
                </Link>
              </li>
              <li className={location?.pathname == "/help-support" ? "active" : ""}>
                <Link to="/help-support">
                  Help & Support<i className="d-icon-angle-right"></i>
                </Link>
              </li>
            </ul>
          </div>
          <div className="aclist">
            <ul>
              <li>
                <a
                  href="#"
                  className="pb-0 pt-0"
                  onClick={() => {
                    logOut();
                  }}
                >
                  Logout<i className="d-icon-angle-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <SweetAlert
          warning
          confirmBtnCssClass="alertpop"
          title={` Are you sure ? you want to Logout `}
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
          show={show}
          onConfirm={Confirm}
          onCancel={Cancelalert}
          btnSize="md"
          showCancel
          cancelBtnBsStyle="danger"
        ></SweetAlert>
      </Col>
    </>
  );
};

export default Accountsidebar;
