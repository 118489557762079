import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, useJsApiLoader, Marker, MarkerF } from '@react-google-maps/api';
import { ApiService } from "../../component/services/apiServices";
import Autocomplete from 'react-google-autocomplete';
import { showToast } from "../Element/utils/toastUtils";
import { toast } from 'react-toastify';
import Alert from 'react-bootstrap/Alert';
import SpinnerLoader from "../Element/utils/spinnerLoader";
const AddressModal = ({ show, setShow, handleClose, handleShow, addressid }) => {
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const didMountRef = useRef(true)
  const [userCurrentPosition, setUserCurrentPosition] = useState(null);
  const [errormessage, setErrorMessage] = useState("")
  const [locationErrorMessage, setLocationErrorMessage] = useState('');
  const [locationStatus, setLocationStatus] = useState('');
  const [addressInputs, setaddressInputs] = useState({
    ua_house_no: "",
    ua_floor: "",
    ua_how_to_reach: "",
    ua_pincode: "",
    ua_complete_address: "",
    ua_id: 0,
    ua_country_name: 'Australia',
    ua_state_name: '',
    ua_city_name: '',
  });

  const containerStyle = {
    width: '100%',
    height: '200px'
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAE7oxhbgUxiIzikhCeN6TB6UUe1frQnZs"
  })

  useEffect(() => {
    if (didMountRef.current) {
      //getLocation();
      if (addressid !== "") {
        getUseraddressdetail()
      }

    }
    didMountRef.current = false
  }, []);

  const getLocation = () => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' })
        .then(permissionStatus => {
          if (permissionStatus.state === 'granted') {
            navigator.geolocation.getCurrentPosition(success, error);
          } else if (permissionStatus.state === 'prompt') {
            setLocationStatus('PermissionPrompt');
            setLocationErrorMessage('Please allow location permission');
          } else {
            setLocationStatus('PermissionDenied');
            setLocationErrorMessage('Please allow location permission');
          }
        })
        .catch(error => {
          console.error('Error checking geolocation permissions:', error);
          setLocationStatus('Error');
        });
    } else {
      setLocationStatus('NotSupported');
      setLocationErrorMessage('Please allow location permission');
    }
  };

  const success = (position) => {
    const centerPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    };

    setUserCurrentPosition({
      lat: position.coords.latitude,
      lng: position.coords.longitude
    });
    if (addressid == "") {
    getCurrentLocationAddress(centerPosition)}
    setTimeout(() => {
      setLocationStatus('granted')
    }, 500);

  };

  const error = (err) => {
    if (err.code == 1) {
      setLocationStatus('PermissionDenied')
      setLocationErrorMessage('Please allow location permission');
    }
  };

  const handleAddressInput = (e) => {
    const { name, value } = e.target;

    setaddressInputs({
      ...addressInputs,
      [name]: value,
    });
  };

  const Saveaddress = (e) => {
    e.preventDefault()
    if (addressInputs?.ua_complete_address == "") {
      setErrorMessage("Please enter your address")
      setTimeout(() => {
        setErrorMessage("")
      }, 2000)
      return
    }
    if (addressInputs?.ua_pincode == "") {
      setErrorMessage("Please fill the zip code field")
      setTimeout(() => {
        setErrorMessage("")
      }, 2000)
      return
    }
    if (addressInputs?.ua_house_no == "") {
      setErrorMessage("Please fill the House No/Flat No/Building No ")
      setTimeout(() => {
        setErrorMessage("")
      }, 2000)
      return
    }
    ApiService.commonPostRequest('user-address-process', addressInputs).then((res) => {
      if (res?.status === "success") {
        handleClose()
      }
      else if (res?.status === "error") {
        setErrorMessage(res?.message + "" + "Please fill the required fields again with other address")
        setaddressInputs({
          ua_house_no: "",
          ua_floor: "",
          ua_how_to_reach: "",
          ua_pincode: "",
          ua_complete_address: "",
          ua_id: 0,
          ua_country_name: 'Australia',
          ua_state_name: '',
          ua_city_name: '',
        })
      } else if (res?.data?.status === "session_expired") {
        localStorage.removeItem("USER_SESSION")
      }
    });

  };

  const getUseraddressdetail = () => {
    const dataString = {
      ua_id: addressid
    }
    ApiService.commonPostRequest('user-address', dataString).then((res) => {
      if (res?.status == "success") {
        setaddressInputs({
          ua_house_no: res?.userAddress?.ua_house_no,
          ua_floor: res?.userAddress?.ua_floor,
          ua_how_to_reach: res?.userAddress?.ua_how_to_reach,
          ua_pincode: res?.userAddress?.ua_pincode,
          ua_complete_address: res?.userAddress?.ua_complete_address,
          ua_id: res?.userAddress?.ua_id,
          ua_country_name: res?.userAddress?.ua_country_name,
          ua_state_name: res?.userAddress?.ua_state_name,
          ua_city_name: res?.userAddress?.ua_city_name,
        })

      }
    })
  }

  const getCurrentLocationAddress = (centerPositionLocation) =>{
    const completeUrl = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + centerPositionLocation.lat + ',' + centerPositionLocation.lng + '&key=AIzaSyAE7oxhbgUxiIzikhCeN6TB6UUe1frQnZs';
    ApiService.googleGetRequest(completeUrl).then(res => {
      if (res.status == 'OK') {
        checkSeletedAddressServicable(placeToAddress(res.results[0]))
        setUserCurrentPosition(centerPositionLocation);
      }
    })
  }

  const onMarkerDragEnd = (e) => {
    const newPosition = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    };
    
    const completeUrl = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + newPosition.lat + ',' + newPosition.lng + '&key=AIzaSyAE7oxhbgUxiIzikhCeN6TB6UUe1frQnZs';
    ApiService.googleGetRequest(completeUrl).then(res => {
      if (res.status == 'OK') {
        checkSeletedAddressServicable(placeToAddress(res.results[0]))
        setUserCurrentPosition(newPosition);
      }
    })
  };

  const autoSearchAddress = (place) => {
    //console.log(place, "placejkjkjjklj")
    const completeUrl = 'https://maps.googleapis.com/maps/api/geocode/json?place_id=' + place.place_id + '&key=AIzaSyAE7oxhbgUxiIzikhCeN6TB6UUe1frQnZs';
    ApiService.googleGetRequest(completeUrl).then(res => {
      console.log("res", res);
      if (res.status == 'OK') {
        checkSeletedAddressServicable(placeToAddress(res.results[0]))
        const newPosition = {
          lat: res.results[0].geometry.location.lat,
          lng: res.results[0].geometry.location.lng
        };
        setUserCurrentPosition(newPosition);
      }
    })
  }

  const checkSeletedAddressServicable = (postData) => {
    setSpinnerLoading(true)
    ApiService.commonPostRequest('serviceable-address', postData).then((res) => {
      if (res.status == 'success') {
        setSpinnerLoading(false)
        setErrorMessage('')
      } else {
        setErrorMessage(res?.message)
        setSpinnerLoading(false)
        setaddressInputs({
          ua_house_no: "",
          ua_floor: "",
          ua_how_to_reach: "",
          ua_pincode: "",
          ua_complete_address: "",
          ua_id: 0,
          ua_country_name: 'Australia',
          ua_state_name: '',
          ua_city_name: '',
        })
      }
    })
  }

  const placeToAddress = (place) => {
    var address = {
      ua_house_no: '',
      ua_city_name: '',
      ua_state_name: '',
      ua_pincode: '',
      ua_country_name: 'Australia',
      ua_complete_address: ''
    };

    address.ua_complete_address = place.formatted_address;

    place.address_components.forEach(function (c) {
      switch (c.types[0]) {
        case 'premise':
          address.ua_house_no = c.long_name;
          break;
        /* case 'neighborhood':
          address.street_number = c.long_name;
          break;
        case 'route':
          address.route = c.long_name;
          break; */
        case 'sublocality_level_1':
          address.ua_city_name = c.long_name;
          break;
        case 'locality':
          address.ua_city_name = c.long_name;
          break;
        case 'administrative_area_level_1':
          address.ua_state_name = c.long_name;
          break;
        case 'postal_code':
          address.ua_pincode = c.long_name;
          break;
        case 'country':
          address.ua_country_name = 'Australia';
          break;
      }
    });
    console.log("address", address);
    setaddressInputs({
      ua_house_no: address.ua_house_no ? address.ua_house_no : '',
      ua_floor: '',
      ua_how_to_reach: '',
      ua_pincode: address.ua_pincode ? address.ua_pincode : '',
      ua_complete_address: address.ua_complete_address ? address.ua_complete_address : '',
      ua_country_name: address.ua_country_name ? address.ua_country_name : 'Australia',
      ua_state_name: address.ua_state_name ? address.ua_state_name : '',
      ua_city_name: address.ua_city_name ? address.ua_city_name : '',
    })
    return address;
  };


  return (
    <>
      <Modal show={show} onHide={handleClose} className="chooseAddressModal">
        {spinnerLoading ? <SpinnerLoader /> : null}
        <button className="closeModal" onClick={handleClose}><i className="d-icon-close"></i></button>
        {/* <div className="addressmap">
          {isLoaded && locationStatus == 'granted' ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={userCurrentPosition}
              zoom={17}
              options={{ zoomControl: false, streetViewControl: false, mapTypeControl: false, fullscreenControl: false }}
            >
              <MarkerF position={userCurrentPosition} title="You are here" draggable={true} onDragEnd={onMarkerDragEnd} />
            </GoogleMap>
          ) : null}
        </div> */}
        <div className="adform">
          <div>
            <h6>Add New Address</h6>
            <p class="tx-color-02 tx-12">Add your home and office addresses and enjoy faster checkout</p>
            <hr></hr>
          </div>
          {errormessage !== "" ? <Alert variant="danger" >{errormessage}</Alert> : ""}
          {locationErrorMessage ? <Alert variant={'danger'}>{locationErrorMessage}</Alert> : null}
          <div className="adformGroup mb-3" >
            <Autocomplete
              apiKey={'AIzaSyAE7oxhbgUxiIzikhCeN6TB6UUe1frQnZs'}
             // onPlaceSelected={(place) => autoSearchAddress(place)}
              onChange={(e) => { handleAddressInput(e) }}
              name='ua_complete_address'
              placeholder="Enter Your Address"
              value={addressInputs.ua_complete_address}
            />
          </div>
          <div className="adformGroup mb-3">
            <input type="number" placeholder="Postal Code" name="ua_pincode" value={addressInputs.ua_pincode} onChange={(e) => { handleAddressInput(e) }}></input>
          </div>
          <div className="adformGroup mb-3">
            <input type="text" placeholder="House No / Flat No / Building No" name="ua_house_no" value={addressInputs.ua_house_no} onChange={(e) => { handleAddressInput(e) }}></input>
          </div>
          <div className="adformGroup mb-3">
            <input type="text" placeholder="Floor (Optional)" name="ua_floor" value={addressInputs.ua_floor} onChange={(e) => { handleAddressInput(e) }}></input>
          </div>
          <div className="adformGroup mb-3">
            <input type="text" placeholder="How to Reach (Optional)" name="ua_how_to_reach" value={addressInputs.ua_how_to_reach} onChange={(e) => { handleAddressInput(e) }}></input>
          </div>
          <div className="adformGroup">
            <button type="button" className="btn btn-primary btn-large btn-full" onClick={(e) => { Saveaddress(e) }}>{addressid !== "" ? "Update Address" : "Save Address"}</button>
          </div>
        </div>
      </Modal>
    </>
  )







};



export default AddressModal;
