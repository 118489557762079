const getInfo = () => {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    const dayOfMonth = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // Month starts from 0 (January)
    const year = currentDate.getFullYear();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();

    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const formattedTime = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    const formattedDate = `${dayOfMonth < 10 ? `${dayOfMonth}` : dayOfMonth}-${month < 10 ? `0${month}` : month}-${year}`;

    return {
        dayOfWeek: daysOfWeek[dayOfWeek],
        dayOfMonth: dayOfMonth,
        month: month,
        year: year,
        formattedDate: formattedDate,
        time: {
            hours: hours < 10 ? `0${hours}` : hours,
            minutes: minutes < 10 ? `0${minutes}` : minutes,
            seconds: seconds < 10 ? `0${seconds}` : seconds,
        },
        formattedTime: formattedTime,
    };
};

export default getInfo;
