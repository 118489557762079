import React, { createContext, useState } from 'react';
const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [Data, setData] = useState("");
  const [cartdata,setcartdata] = useState([]) // Initialize your global data here
  const [cartCount,setcartCount] = useState('') 
  const [cartAmount,setcartAmount] = useState('') 

  return (
    <DataContext.Provider value={{ Data, setData,setcartdata,cartdata,cartCount,setcartCount }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;