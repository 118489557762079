import React, { useState, useEffect,useRef} from "react";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ApiService } from "../services/apiServices";
import { showToast } from "../Element/utils/toastUtils";
const LoginModal = ({ show, setShow, handleClose, handleShow,goToRoute='/' }) => {
  let apiServices = new ApiService();
  const didMountRef = useRef(true);
  const [step, setStep] = useState(1);
  const [otp, setotp] = useState("");
  const [message, setmessage] = useState({});

  const [passwordtype, setpasswordtype] = useState("password");
  const [Errorobject, setErrorobject] = useState({});
  const [confpasswordtype, setconfpasswordtype] = useState("password");
  const [forgetEmail, setforgetEmail] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [forgetpasswordscreen, setforgetpasswordscreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [counter, setCounter] = React.useState(0);
  const [countermob, setCountermob] = React.useState(0);
  const [seconds, setseconds] = useState(0);
  const [minutes, setminutes] = useState(10);
  const navigate = useNavigate();

  const [register, setregisterstate] = useState(false);
  const [registerInputs, setregisterInputs] = useState({
    user_email: "",
    user_password: "",
    user_mobile: "",
    user_name: "",
  });
  const [loginInputs, setloginInputs] = useState({
    user_email: "",
    user_password: "",
  });

  const handleLoginInput = (e) => {
    setErrorobject({});
    setmessage({});
    const { name, value } = e.target;

    setloginInputs({
      ...loginInputs,
      [name]: value,
    });
  };
  const handleRegisterInput = (e) => {
    setErrorobject({});
    setmessage({});
    const { name, value } = e.target;

    setregisterInputs({
      ...registerInputs,
      [name]: value,
    });
  };
  const loginprocess = (e) => {
    e.preventDefault();
    if (loginInputs.user_email == "") {
      setErrorobject({
        ...Errorobject,
        user_email: "Please Enter Email address",
      });

      return false;
    } else if (loginInputs.user_password == "") {
      setErrorobject({
        ...Errorobject,
        user_password: "Please Enter Password",
      });

      return false;
    }

    setIsLoading(true);
    const dataString = {
      user_email: loginInputs.user_email,
      user_password: loginInputs.user_password,
    };

    apiServices
      .logincheckPostRequest(dataString)
      .then((res) => {
        if (res.data.status == "success") {
          let data = res?.data?.data;
          localStorage.setItem("USER_SESSION", JSON.stringify(data));
          setIsLoading(false);
          setShow(false);
          navigate(goToRoute)
        } else if (res?.data?.status == "error") {
          setIsLoading(false);
          const errormsg = res?.data?.message;
          if (errormsg == "Invalid Credentials. Please try again") {
            setmessage({
              ...message,
              Login_msg: "Invalid Credentials. Please enter correct details",
            });
            return false;
          }
        }
      })
      .catch((error) => {});
  };

  const registertionprocess = (e) => {
    e.preventDefault();

    if (registerInputs.user_name == "") {
      setErrorobject({
        ...Errorobject,
        user_name: "Please Enter Your Full Name",
      });
      return false;
    } else if (registerInputs.user_email == "") {
      setErrorobject({
        ...Errorobject,
        user_email: "Please Enter Email address",
      });
      return false;
    } else if (registerInputs.user_mobile == "") {
      setErrorobject({
        ...Errorobject,
        user_mobile: "Please Enter Mobile number",
      });
      return false;
    } else if (registerInputs.user_password == "") {
      setErrorobject({
        ...Errorobject,
        user_password: "Please Enter Password",
      });
      return false;
    }

    setIsLoading(true);
    const dataString = {
      user_email: registerInputs.user_email,
      user_password: registerInputs.user_password,
      user_mobile: registerInputs.user_mobile,
      user_name: registerInputs.user_name,
    };

    apiServices
      .registerPostRequest(dataString)
      .then((res) => {
        if (res.data.status == "success") {
          let data = res?.data?.data;
          localStorage.setItem("USER_SESSION", JSON.stringify(data));
          setIsLoading(false);
          setShow(false);
        } else {
          setIsLoading(false);
          setmessage({
            ...message,
            Register_msg: res.data.message,
          });
          return false;
        }
      })
      .catch((error) => {});
  };

  const Forgetpassword = (e) => {
    e.preventDefault();
    setCounter(0)
    setCountermob(0);
    if (forgetEmail == "") {
      setErrorobject({
        ...Errorobject,
        forgetEmail: "Please Enter Registered Email address",
      });
      return;
    }
    setIsLoading(true);
    const dataString = {
      user_email: forgetEmail,
    };
    apiServices
      .forgetpasswordPostRequest(dataString)
      .then((res) => {
        if (res.data.status == "success") {
          setIsLoading(false);
          setStep(2);
          setCounter(30);
        setCountermob(30);
        setminutes(10);
        setseconds(0);
        } else {
          setIsLoading(false);
          setmessage({
            ...message,
            Send_otp: res.data.message,
          });
          return false;
        }
      })
      .catch((error) => {});
  };

  const Verifyotpresetpass = (e) => {
    if (forgetEmail == "") {
      setErrorobject({
        ...Errorobject,
        forgetEmail: "Please Enter Registered Email address",
      });
      return;
    }
    if (otp == "") {
      setErrorobject({
        ...Errorobject,
        user_otp: "Please Enter OTP sent on your emai id",
      });
      return;
    }

    setIsLoading(true);
    const dataString = {
      user_email: forgetEmail,
      user_otp: otp,
    };

    apiServices
      .resetpasswordverifyotpPostRequest(dataString)
      .then((res) => {
        if (res.data.status == "success") {
          setIsLoading(false);
          setStep(3);
        } else {
          setIsLoading(false);
          setmessage({
            ...message,
            verify_otp: res.data.message,
          });
          return false;
        }
      })
      .catch((error) => {});
  };

  const changepassword = (e) => {
    e.preventDefault();
    if (forgetEmail == "") {
      setErrorobject({
        ...Errorobject,
        forgetEmail: "Please enter your registered email address",
      });
      return;
    }
    if (newpassword == "") {
      setErrorobject({
        ...Errorobject,
        newpassword: "Please enter your New Password",
      });
      return;
    }
    if (confirmpassword == "") {
      setErrorobject({
        ...Errorobject,
        confirmpassword: "Please enter your Confirm Password",
      });
      return;
    }
    if (newpassword !== confirmpassword) {
      setErrorobject({
        ...Errorobject,
        matchpassword: "Your Confirm Password is not matched with New Password",
      });
      return;
    }

    setIsLoading(true);
    const dataString = {
      user_email: forgetEmail,
      password: newpassword,
    };

    apiServices
      .changepasswordPostRequest(dataString)
      .then((res) => {
        if (res.data.status == "success") {
          setIsLoading(false);
          setregisterstate(false);
          setforgetpasswordscreen(false);
        } else {
          setIsLoading(false);
          setmessage({
            ...message,
            change_password: res.data.message,
          });
          return false;
        }
      })
      .catch((error) => {});
  };


  React.useEffect(() => {
    if (counter > 0) {
      const timeoutId = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timeoutId);
    } else {
      setCounter(0); // Reset counter when it reaches 0
    }
  }, [counter]);
  React.useEffect(() => {
    if (countermob > 0) {
      const timeoutId = setTimeout(() => setCountermob(countermob - 1), 1000);
      return () => clearTimeout(timeoutId);
    } else {
      setCountermob(0); // Reset counter when it reaches 0
    }
  }, [countermob]);

  React.useEffect(() => {
    if(didMountRef.current){
      setTimeout(() => {
        if (seconds > 0) {
          setseconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
          } else {
            setseconds(59);
            setminutes(minutes - 1);
          }
        }
      }, 1000);

    }
   didMountRef.current=false
  }, []);

 

  const resendOtpbr = () => {
    if (forgetEmail == "") {
      showToast('error', 'Please enter your registered email address');
      return;
    }
    setIsLoading(true);
    const dataString = {
      user_email: forgetEmail,
    };
    apiServices.forgetpasswordPostRequest(dataString).then((res) => {
      if (res.data.status == "success") {
        localStorage.setItem("AUTH_TOKEN", res.data.auth_token);
        setIsLoading(false);
        showToast('success', res?.data?.message);
        setCounter(30);
        setCountermob(30);
        setminutes(10);
        setseconds(0);
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        showToast('success', res?.data?.message);
        return false;
      }
    });
  };

  const handleClickShowPassword = () => {
    if (passwordtype == "password") {
      setpasswordtype("text");
    } else {
      setpasswordtype("password");
    }
  };
  const handleClickShowconfPassword = () => {
    if (confpasswordtype == "password") {
      setconfpasswordtype("text");
    } else {
      setconfpasswordtype("password");
    }
  };
  const popclose = () => {
    handleClose();
    setregisterstate(false);
    setforgetpasswordscreen(false);
    setErrorobject({});
    setmessage({});
    setloginInputs({});
    setregisterInputs({});
  };


  return (
    <>
      <Modal show={show} onHide={handleClose} className="lrmodal">
        <button
          className="pop-close"
          onClick={() => {
            popclose();
          }}
        >
          <i className="d-icon-times"></i>
        </button>
        {register == false && forgetpasswordscreen == false ? (
          <>
            <div className="lrmodal-content">
              <div className="text-center">
                <img src="/logo192.png" style={{ width: "100px" }}></img>
                <h6 className="fw-bold mb-1 mt-3">
                  Sign in with Daadi's Kitchen
                </h6>
                <p className="fs-6">Welcome back to sign in again</p>
              </div>
              <div
                className={`form-group mb-3 ${
                  Errorobject.user_email && Errorobject.user_email !== "" ? "has-error" : ""
                }`}
              >
                <input
                  placeholder="Email address"
                  id="signinUsername"
                  type="text"
                  name="user_email"
                  onChange={(e) => {
                    handleLoginInput(e);
                  }}
                ></input>
              </div>
              <div
                className={`form-group mb-3 ${
                  Errorobject.user_password && Errorobject.user_password !== "" ? "has-error" : ""
                }`}
              >
                <input
                  placeholder="Password"
                  type={passwordtype}
                  name="user_password"
                  id="signinPassword"
                  onChange={(e) => {
                    handleLoginInput(e);
                  }}
                ></input>
                {passwordtype == "password" ? (
                  <span className="passwordshow">
                    <i
                      className="fas fa-eye"
                      onClick={handleClickShowPassword}
                    ></i>
                  </span>
                ) : (
                  <span className="passwordshow">
                    <i
                      className="fa fa-eye-slash"
                      onClick={handleClickShowPassword}
                    ></i>
                  </span>
                )}
              </div>
              {message?.Login_msg !== "" ? (
                <>
                  <p className="text-danger text-center">
                    {message?.Login_msg}
                  </p>
                </>
              ) : (
                ""
              )}
              <div className="form-group mb-3">
                <button
                  type="button"
                  className="btn btn-primary btn-large btn-full"
                  disabled={isLoading}
                  onClick={(e) => {
                    loginprocess(e);
                  }}
                >
                  {isLoading ? (
                    <img src="/img/loder01.gif" width="60px" height="11px" />
                  ) : (
                    "Sign In"
                  )}
                </button>
              </div>
              <div className="text-center">
                <p
                  className="poniter fw600"
                  onClick={() => {
                    setforgetpasswordscreen(true);
                    setloginInputs({});
                    setregisterInputs({});
                    setStep(1)
                    // setCounter(0)
                    // setseconds(0)
                  }}
                >
                  Forgot Password?
                </p>
                <p className="poniter">
                  Don't have an account?{" "}
                  <span
                    className="tx-theme"
                    onClick={() => {
                      setregisterstate(true);
                      setloginInputs({
                        user_email: "",
                        user_password: "",
                      });
                    }}
                  >
                    Sign Up
                  </span>
                </p>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {register == true && forgetpasswordscreen == false ? (
          <>
            <div className="lrmodal-content">
              <div className="text-center">
                <img src="/logo192.png" style={{ width: "100px" }}></img>
                <h6 className="fw-bold mb-1 mt-3">
                  Sign up with Daadi's Kitchen
                </h6>
                <p className="fs-6">
                  For Better Experience, Order tracking & Regular updates.
                </p>
              </div>
              <div
                className={`form-group mb-3 ${
                  Errorobject.user_name &&  Errorobject?.user_name !== "" ? "has-error" : ""
                }`}
              >
                <input
                  placeholder="Full Name"
                  type="text"
                  name="user_name"
                  id=""
                  onChange={(e) => {
                    handleRegisterInput(e);
                  }}
                ></input>
              </div>
              <div
                className={`form-group mb-3 ${
                  Errorobject.user_email && Errorobject?.user_email !== "" ? "has-error" : ""
                }`}
              >
                <input
                  placeholder="Email address"
                  type="text"
                  name="user_email"
                  onChange={(e) => {
                    handleRegisterInput(e);
                  }}
                ></input>
              </div>
              <div
                className={`form-group country-input mb-3 ${
                  Errorobject.user_mobile && Errorobject?.user_mobile !== "" ? "has-error" : ""
                }`}
              >
                <input
                  placeholder="Mobile Number"
                  type="number"
                  name="user_mobile"
                  onChange={(e) => {
                    handleRegisterInput(e);
                  }}
                ></input>
                <span className="country-code">+61</span>
              </div>
              <div
                className={`form-group mb-3 ${
                  Errorobject.user_password &&  Errorobject?.user_password !== "" ? "has-error" : ""
                }`}
              >
                <input
                  placeholder="Password"
                  type={passwordtype}
                  name="user_password"
                  onChange={(e) => {
                    handleRegisterInput(e);
                  }}
                ></input>
                {passwordtype == "password" ? (
                  <span className="passwordshow">
                    <i
                      className="fas fa-eye"
                      onClick={handleClickShowPassword}
                    ></i>
                  </span>
                ) : (
                  <span className="passwordshow">
                    <i
                      className="fa fa-eye-slash"
                      onClick={handleClickShowPassword}
                    ></i>
                  </span>
                )}
              </div>
              {message?.Register_msg !== "" ? (
                <>
                  <p className="text-danger text-center">
                    {message?.Register_msg}
                  </p>
                </>
              ) : (
                ""
              )}
              <div className="form-group mb-3">
                <button
                  type="button"
                  className="btn btn-primary btn-large btn-full"
                  disabled={isLoading}
                  onClick={(e) => {
                    registertionprocess(e);
                  }}
                >
                  {isLoading ? (
                    <img src="/img/loder01.gif" width="60px" height="11px" />
                  ) : (
                    "Sign Up"
                  )}
                </button>
              </div>
              <div className="text-center">
                <p className="poniter">
                  Already have an account ?{" "}
                  <span
                    className="tx-theme"
                    onClick={() => {
                      setregisterstate(false);
                      setregisterInputs({});
                    }}
                  >
                    Sign in
                  </span>
                </p>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {register == false && forgetpasswordscreen == true ? (
          <>
            {step == 1 ? (
              <>
                <div className="lrmodal-content">
                  <div className="text-center">
                    <img src="/logo192.png" style={{ width: "100px" }}></img>
                    <h6 className="fw-bold mb-1 mt-3">Forgot Password</h6>
                    <p className="fs-6">Enter your registered email address</p>
                  </div>
                  <div
                    className={`form-group mb-3 ${
                      Errorobject.forgetEmail &&  Errorobject.forgetEmail !== "" ? "has-error" : ""
                    }`}
                  >
                    <input
                      placeholder="Email address"
                      type="text"
                      name="user_email"
                      onChange={(e) => {
                        setforgetEmail(e.target.value);
                        setErrorobject({});
                        setmessage({});
                      }}
                    ></input>
                  </div>

                  {message?.Send_otp !== "" ? (
                    <>
                      <p className="text-danger text-center">
                        {message?.Send_otp}
                      </p>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="form-group mb-3">
                    <button
                      type="button"
                      className="btn btn-primary btn-large btn-full"
                      disabled={isLoading}
                      onClick={(e) => {
                        Forgetpassword(e);
                      }}
                    >
                      {isLoading ? (
                        <img
                          src="/img/loder01.gif"
                          width="60px"
                          height="11px"
                        />
                      ) : (
                        "Continue"
                      )}
                    </button>
                  </div>
                  <div className="text-center">
                    <p className="poniter">
                      Already have an account ?{" "}
                      <span
                        className="tx-theme"
                        onClick={() => {
                          setregisterstate(false);
                          setregisterInputs({});
                          // setCounter(0)
                          setforgetpasswordscreen(false);
                        }}
                      >
                        Sign in
                      </span>
                    </p>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {step == 2 ? (
              <>
                <div className="lrmodal-content">
                  <div className="text-center">
                    <img src="/logo192.png" style={{ width: "100px" }}></img>
                    <h6 className="fw-bold mb-1 mt-3">
                      Verify Your Email Address
                    </h6>
                    <p className="fs-6">Enter the OTP sent to {forgetEmail}</p>
                  </div>
                  <div
                    className={`form-group mb-3 ${
                      Errorobject.user_otp &&   Errorobject.user_otp !== "" ? "has-error" : ""
                    }`}
                  >
                    <input
                      placeholder="Enter OTP"
                      type="number"
                      name="user_otp"
                      onChange={(e) => {
                        setotp(e.target.value);
                        setErrorobject({});
                        setmessage({});
                      }}
                    ></input>
                  </div>
                  {counter === 0 ? (
                    <p className="tx-12 mt-2">
                      Didn't receive OTP? Resend{" "}
                      <span
                        className="tx-theme"
                        style={{ cursor: "pointer" }}
                        onClick={resendOtpbr}
                      >
                        (Click Here)
                      </span>
                    </p>
                  ) : (
                    <p className="tx-12 mt-2">
                      Resend OTP in {counter} seconds
                    </p>
                  )}

                  {message?.verify_otp !== "" ? (
                    <>
                      <p className="text-danger text-center">
                        {message?.verify_otp}
                      </p>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="form-group mb-3">
                    <button
                      type="button"
                      className="btn btn-primary btn-large btn-full"
                      disabled={isLoading}
                      onClick={(e) => {
                        Verifyotpresetpass(e);
                      }}
                    >
                      {isLoading ? (
                        <img
                          src="/img/loder01.gif"
                          width="60px"
                          height="11px"
                        />
                      ) : (
                        "Verify"
                      )}
                    </button>
                  </div>
                  <div className="text-center">
                    <p className="poniter">
                      Already have an account ?{" "}
                      <span
                        className="tx-theme"
                        onClick={() => {
                          setregisterstate(false);
                          setregisterInputs({});
                          setforgetpasswordscreen(false);
                          // setCounter(0)
                        }}
                      >
                        Sign in
                      </span>
                    </p>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {step == 3 ? (
              <>
                <div className="lrmodal-content">
                  <div className="text-center">
                    <img src="/logo192.png" style={{ width: "100px" }}></img>
                    <h6 className="fw-bold mb-1 mt-3">Change Password</h6>
                    <p className="fs-6">
                      Kindly enter your new password and click on change
                      password
                    </p>
                  </div>
                  <div
                    className={`form-group mb-3 ${
                      Errorobject.newpassword &&   Errorobject?.newpassword !== "" ? "has-error" : ""
                    }`}
                  >
                    <input
                      placeholder="New Password"
                      type={passwordtype}
                      name="password"
                      onChange={(e) => {
                        setNewpassword(e.target.value);
                        setErrorobject({});
                        setmessage({});
                      }}
                    ></input>
                    {passwordtype == "password" ? (
                      <span className="passwordshow">
                        <i
                          className="fas fa-eye"
                          onClick={handleClickShowPassword}
                        ></i>
                      </span>
                    ) : (
                      <span className="passwordshow">
                        <i
                          className="fa fa-eye-slash"
                          onClick={handleClickShowPassword}
                        ></i>
                      </span>
                    )}
                    <br></br>
                  </div>
                  <div
                    className={`form-group mb-3 ${
                      Errorobject.confirmpassword &&   Errorobject?.confirmpassword !== "" ||Errorobject.matchpassword &&   Errorobject?.matchpassword !== "" ? "has-error"   : ""
                    }`}
                  >
                    <input
                      placeholder="Confirm Password"
                      type={confpasswordtype}
                      name=""
                      onChange={(e) => {
                        setconfirmpassword(e.target.value);
                        setErrorobject({});
                        setmessage({});
                      }}
                    ></input>
                    {confpasswordtype == "password" ? (
                      <span className="passwordshow">
                        <i
                          className="fas fa-eye"
                          onClick={handleClickShowconfPassword}
                        ></i>
                      </span>
                    ) : (
                      <span className="passwordshow">
                        <i
                          className="fa fa-eye-slash"
                          onClick={handleClickShowconfPassword}
                        ></i>
                      </span>
                    )}
                    <br></br>
                  </div>

                  {message?.change_password !== "" ? (
                    <>
                      <p className="text-danger text-center">
                        {message?.change_password}
                      </p>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="form-group mb-3">
                    <button
                      type="button"
                      className="btn btn-primary btn-large btn-full"
                      disabled={isLoading}
                      onClick={(e) => {
                        changepassword(e);
                      }}
                    >
                      {isLoading ? (
                        <img
                          src="/img/loder01.gif"
                          width="60px"
                          height="11px"
                        />
                      ) : (
                        "Change Password"
                      )}
                    </button>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

        <Modal.Body></Modal.Body>
      </Modal>
    </>
  );
};

export default LoginModal;
