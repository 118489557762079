import Header from "../../component/Header";
import Footer from "../../component/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import {
  BrowserView,
  MobileView,
} from "react-device-detect";
import { ApiService } from "../../component/services/apiServices";
import Accountsidebar from "../Account/account_sidebar";

const Helpsupport = () => {
  const navigate = useNavigate();
  let apiServices = new ApiService();
  const [adminData, setAdmindata] = useState("");
  const didMountRef = useRef(true);
  const Userdata = JSON.parse(localStorage.getItem("USER_SESSION"));

  useEffect(() => {
    if (didMountRef.current) {
      apiServices
    .getsettinglistgetRequest()
    .then((res) => {
     
      if (res.data.status == "success") {
        setAdmindata(res?.data?.data);
      
    
      }
    })
    .catch((error) => {
     
    });
    }
    didMountRef.current = false;
  });

  const redirectToEmail = (e) => {
    e.preventDefault();
    let email = adminData?.admin_support_email;
    if (email !== "") {
      window.location.href = `mailto:${email}`;
    }
  };
  const phoneNumber = adminData?.admin_support_mobile
    ? adminData?.admin_support_mobile
    : "";

  const redirectToPhoneCall = (e) => {
    e.preventDefault();
    if (phoneNumber !== "") {
      window.location.href = `tel:${phoneNumber}`;
    }
  };

  return (
    <>
      <BrowserView>
        <Header></Header>

      
          <div className="subheader">
            <Container>
              <Row>
                <Col lg={12}>
                  <Breadcrumb className="breadcrumb-inner">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Help & Support</Breadcrumb.Item>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="">
            <section className="secsmall">
              <Container>
                <Row>
                  <Accountsidebar />
                  <Col lg={9}>
                    <div className="acpanel">
                      <div className="acpanel-header">
                        <h4>Help & Support</h4>
                      </div>
                      <div className="acpanel-body">
                        <div className="p-5 helpbox text-center">
                          <img
                            className="img-fluid mb-2"
                            src="/img/helpe94.webp"
                            style={{ width: "200px" }}
                          ></img>
                          <h6>How can we help you today?</h6>
                          <p className="tx-color-02 tx-13 mb-0">
                            Our customer service team will be able to assist you
                            with any order or query
                          </p>
                        </div>
                        <div className="macc-list">
                          <ul>
                            <li>
                              <a
                                onClick={(e) => {
                                  redirectToEmail(e);
                                }}
                              >
                                <div className="macc-list-icon">
                                  <img src="/img/email.png"></img>
                                </div>
                                <div>
                                  <p className="mb-0">Email Us</p>
                                  <span className="tx-color-02 tx-11">
                                    Write to Daadi's kitchen directly for any
                                    query
                                  </span>
                                </div>
                                <i className="d-icon-angle-right"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) => {
                                  redirectToPhoneCall(e);
                                }}
                              >
                                <div className="macc-list-icon">
                                  <img src="/img/phone.png"></img>
                                </div>
                                <div>
                                  <p className="mb-0">Call Us</p>
                                  <span className="tx-color-02 tx-11">
                                    Get in touch and we will be happy to help
                                    you
                                  </span>
                                </div>
                                <i className="d-icon-angle-right"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href={
                                  "https://wa.me/" +
                                  adminData?.admin_support_mobile
                                }
                                target="new"
                              >
                                <div className="macc-list-icon">
                                  <img src="/img/whatsapp.png"></img>
                                </div>
                                <div>
                                  <p className="mb-0">Whatsapp</p>
                                  <span className="tx-color-02 tx-11">
                                    Get in touch and we will be happy to help
                                    you
                                  </span>
                                </div>
                                <i className="d-icon-angle-right"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
   

        <Footer />
      </BrowserView>

      <MobileView>
        <div className="mwapper">

        <header className="mheader">
          <div className="mheader-left">
            <i
              className="d-icon-arrow-left tx-22"
              onClick={() => {
                navigate("/account/account-overview");
              }}
            ></i>
            <p className="mb-0 mt-1 ms-3">Help & Support</p>
          </div>
        </header>
        <div className="p-5 helpbox text-center">
          <img className="img-fluid mb-2" src="/img/helpe94.webp"></img>
          <h6>How can we help you today?</h6>
          <p className="tx-color-02 tx-13 mb-0">
            Our customer service team will be able to assist you with any order
            or query
          </p>
        </div>
        <div className="macc-list">
          <ul>
            <li>
              <a
                onClick={(e) => {
                  redirectToEmail(e);
                }}
              >
                <div className="macc-list-icon">
                  <img src="/img/email.png"></img>
                </div>
                <div>
                  <p className="mb-0">Email Us</p>
                  <span className="tx-color-02 tx-11">
                    Write to Daadi's kitchen directly for any query
                  </span>
                </div>
                <i className="d-icon-angle-right"></i>
              </a>
            </li>
            <li>
              <a
                onClick={(e) => {
                  redirectToPhoneCall(e);
                }}
              >
                <div className="macc-list-icon">
                  <img src="/img/phone.png"></img>
                </div>
                <div>
                  <p className="mb-0">Call Us</p>
                  <span className="tx-color-02 tx-11">
                    Get in touch and we will be happy to help you
                  </span>
                </div>
                <i className="d-icon-angle-right"></i>
              </a>
            </li>
            <li>
              <a
                href={"https://wa.me/" + adminData?.admin_support_mobile}
                target="new"
              >
                <div className="macc-list-icon">
                  <img src="/img/whatsapp.png"></img>
                </div>
                <div>
                  <p className="mb-0">Whatsapp</p>
                  <span className="tx-color-02 tx-11">
                    Get in touch and we will be happy to help you
                  </span>
                </div>
                <i className="d-icon-angle-right"></i>
              </a>
            </li>
          </ul>
        </div>


        </div>
      
      </MobileView>
    </>
  );
};
export default Helpsupport;
