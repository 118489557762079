import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect, useRef } from 'react';
import { ApiService } from "../../component/services/apiServices";
import Autocomplete from 'react-google-autocomplete';
import { showToast } from "../Element/utils/toastUtils";
import { toast } from 'react-toastify';
import Alert from 'react-bootstrap/Alert';
import SpinnerLoader from "../Element/utils/spinnerLoader";
import localStorageData from "../../component/Element/utils/localStorageData";
import { PayPalButtons, usePayPalScriptReducer,PayPalScriptProvider } from "@paypal/react-paypal-js";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";

const Walletmodal = ({ show, setShow, handleClose, handleShow }) => {
    const localStorageSession = localStorageData();
    const userSession = localStorageSession['UserSession'];
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [paymentorderres, setPaymentorderres] = useState("")
    const [paypalres,setpaypalres]=useState({})
    const [transres, settransres] = useState("")
    const [paymentmode, setpaymentmode] = useState("")
    // const [{ isPending, options }] = usePayPalScriptReducer();
    const [showcardstate, setshowcradstate] = useState(false)
    const [savedcarddata, setsavedcarddata] = useState([])
    const [selectedcardid, setselectedcardid] = useState("")
    const [addnewcard, setaddnewcard] = useState(false)
    const [cardamount,setcardamount]=useState("")
    const [cardState, setCardState] = useState({
        number: "",
        name: "",
        expiry: "",
        cvc: "",
        name: "",
        focus: "",
        save_card: 0
    });
    const didMountRef = useRef(true);
    useEffect(() => {
        if (didMountRef.current) {
    
            ApiService.commonGetRequest("user-savedc-cards").then((res) => {
                if (res?.status == "success") {
                    setsavedcarddata(res?.cards)
                }
            })
        }
        didMountRef.current = false;

        if (cardamount < 10 && cardState !== "") {
            setpaymentmode("")

        }

    }, [cardamount])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let updatedValue = value;
        if (name === 'number') {
            const numberWithoutSpaces = value.replace(/\s/g, '');
            updatedValue = numberWithoutSpaces.replace(/\D/g, '');
            updatedValue = updatedValue.replace(/[^\d]/g, '');
            updatedValue = updatedValue.replace(/(.{4})/g, '$1 ').trim();
            if(updatedValue.length > 20){
                return false;
            }
        } else if (name === 'expiry') {
            updatedValue = value.replace(/[^\d\/]/g, '');

            if (updatedValue.length === 2 && !updatedValue.includes('/')) {
                updatedValue += '/';
            }
        } else if (name === 'cvc') {
            if (updatedValue.length > 4) {
                return false;
            }
        }
        setCardState({
            ...cardState,
            [name]: updatedValue
        });
        
    };
    const handleInputFocus = (e) => {
        setCardState((prev) => ({ ...prev, focus: e.target.name }));
    };
    const AddMoneytoWallet = (e) => {
      
        if (cardState.amount == "") {
            showToast('error', 'Please enter amount to add in wallet')
            return false;
        }
        if (cardState.number == '') {
            showToast('error', 'Please enter card number')
            return false;
        } else if (cardState.number.length > 19 || cardState.number.length < 19) {
            showToast('error', 'Please enter valid card details')
            return false;
        }

        // if (cardState.name == '') {
        //     showToast('error', 'Please enter name')
        //     return false;
        // }

        if (cardState.expiry == '') {
            showToast('error', 'Please enter name')
            return false;
        }
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear() % 100;
        const currentMonth = currentDate.getMonth() + 1;
        const [inputMonth, inputYear] = cardState.expiry.split('/').map(Number);
        const isValidDate = inputYear > currentYear || (inputYear === currentYear && inputMonth <= currentMonth);
        if (!isValidDate) {
            showToast('error', 'Please enter valid expiry')
            return false;
        }

        if (cardState.cvc.length > 3 || cardState.cvc.length < 3) {
            showToast('error', 'Please enter valid cvc')
            return false;
        }
        setSpinnerLoading(true)
        const dataString = {
            // trans_payment: type == 1 ? (parseFloat(cartSummary.total_amount) + parseFloat(cartSummary.addon_amount) + parseFloat(deliveryAmountTotal) - parseFloat(cartSummary.discount) - parseFloat(CouponSession.discount_amount)).toFixed(2) : (parseFloat(cartSummary.total_amount) + parseFloat(cartSummary.addon_amount) + parseFloat(deliveryAmountTotalMobile) - parseFloat(cartSummary.discount) - parseFloat(CouponSession.discount_amount)).toFixed(2),
            user_id: userSession.user_id,
            uw_amount_add: cardamount,
            userSession: userSession,
            card_number: Number(cardState.number.replace(/\s/g, '')),
            exp_month: inputMonth,
            exp_year: inputYear,
            cvc: cardState.cvc,
            name: cardState.name,
            ucd_id:0,
            trans_payment_method:paymentmode,
            uw_add_type:paymentmode=="card"?1:paymentmode=="paypal"?2:"",
            save_card:cardState.save_card
        }

        ApiService.commonPostRequest('create-wallet-card-token', dataString).then((res) => {
            if (res.status == 'success') {
                showToast("success", "Money has been suucessfulley added to your wallet")
                setSpinnerLoading(false)
                setTimeout(() => {
                    window.location.reload();
                }, 1000);

            } else {
                setSpinnerLoading(false)
                showToast('error', res.message)
            }
        })

    }
    const cardOption = (e) => {
        if (cardamount !== "") {
            if (Number(cardamount) >= 10) {
                setpaymentmode("card")
            }
            else {
                showToast("error", "You can  add minimum amount of  $10 in your wallet")
                return
            }
        }
        else {
            showToast("error", "Please Enter Amount Before Going to Payment Mode ")
            return
        }
    }
    const paypalOption = (e) => {
        if (cardamount !== "") {
            if (Number(cardamount) >= 10) {
                setpaymentmode("paypal")

            }
            else {
                showToast("error", "You can  add minimum amount of  $10 in your wallet")
                return
            }
        }
        else {
            showToast("error", "Please Enter Amount Before Going to Payment Mode ")
            return
        }
    }
    
    // if (isPending) {
    //     return <div>Loading...</div>; // Add a loading indicator while the script is being loaded
    // }
    // if (!options) {
    //     console.log(options, options["data-client-token"], "options")
    //     return <div>Error loading PayPal SDK.</div>; // Handle the case where the script fails to load
    // }
    const savecarddetail = (e) => {
        if (e.target.checked) {
            setCardState({
                ...cardState,
                ["save_card"]: 1
            });
        }
        else {
            setCardState({
                ...cardState,
                ["save_card"]: 0
            });
        }
    }
   

    const selectedcarddetail = (item) => {
        setselectedcardid(item?.ucd_four_number)
        const clickedCard = savedcarddata.find(card => card.ucd_four_number === item?.ucd_four_number);
        if (clickedCard) {
            setshowcradstate(true)
            setaddnewcard(false)
            setCardState({
                number: clickedCard?.ucd_four_number,
                save_card: 0,
                ucd_id:clickedCard?.ucd_id
            })
        }
        else {
            showToast("error", "Card not found or Transaction not possible with that card ")
        }

    }
    const initialOptions = {
        clientId: "AbToFBAeDlezkwNZ9KlKwsO3eeQsBuB9jWGAaKK7mALuHeVWvGoBuxr_TPTNCJlgnFX4yp0evU5pbRxA",
        currency: "USD",
        intent: "capture",
    };
  

    const addnewcarddetailfn = () => {
        setaddnewcard(true);
        setselectedcardid("");
        setshowcradstate(false);
        setCardState({
            number: "",
            name: "",
            expiry: "",
            cvc: "",
            name: "",
            focus: "",
            save_card: 0
        })
    }
    const handlesavedcard = (e, item) => {
    
            const { name, value } = e.target;
            let updatedValue = value;
            console.log(updatedValue,"updatedvalue")
            if (name === "cvc") {
                if (updatedValue.length > 3) {
                    showToast("error", "CVC number cannot exceed with 3 digits")
                    return false

                }
                else if (updatedValue.length == 3) {

                    setCardState((prevCardState) => ({
                        ...prevCardState,
                        cvc: updatedValue,
                        save_card: 1,
                    }));

                }
            }
       
    }

    const cardInage = (type) => {
        switch (type) {
            case 'Visa':
                return ('/img/vcard.png');
                break;
            case 'MasterCard':
                return ('/img/mcard.png');
                break;
            case 'American Express':
                return ('/img/amex.png');
                break;
            case 'Discover':
                return ('/img/dcard.png');
                break;
            case 'atm':
                return ('/img/atmcards.png');
            case 'JCB':
                return ('/img/jcard.png');
                break;

            // Add more card types as needed
            default:
                return ('/img/ucard.png');
        }
    }
    const Addmoneybysavedcard = (type) => {
        if (cardState?.cvc?.length > 3 || cardState?.cvc?.length < 3) {
            showToast('error', 'Please enter valid cvc')
            return false;
        }
        setSpinnerLoading(true)
        const dataString = {
            // trans_payment: type == 1 ? (parseFloat(cartSummary.total_amount) + parseFloat(cartSummary.addon_amount) + parseFloat(deliveryAmountTotal) - parseFloat(cartSummary.discount) - parseFloat(CouponSession.discount_amount)).toFixed(2) : (parseFloat(cartSummary.total_amount) + parseFloat(cartSummary.addon_amount) + parseFloat(deliveryAmountTotalMobile) - parseFloat(cartSummary.discount) - parseFloat(CouponSession.discount_amount)).toFixed(2),
            user_id: userSession.user_id,
            uw_amount_add: cardamount,
            userSession: userSession,
            cvc: cardState.cvc,
            name: cardState.name,
            ucd_id:cardState.ucd_id,
            trans_payment_method:paymentmode,
            uw_add_type:paymentmode=="card"?1:paymentmode=="paypal"?2:"",
            save_card:cardState.save_card
        }

        ApiService.commonPostRequest('create-wallet-card-token', dataString).then((res) => {
            if (res.status == 'success') {
                showToast("success", "Money has been suucessfulley added to your wallet")
                setSpinnerLoading(false)
                setTimeout(() => {
                    window.location.reload();
                }, 1000);

            } else {
                setSpinnerLoading(false)
                showToast('error', res.message)
            }
        })


      
    }

    const afterapproveaddinwallet=(respdetail)=>{
        console.log(respdetail,"jkjkhjk")
        if(respdetail?.id && respdetail!==""){
            const dataString={
                user_id: userSession.user_id,
                 uw_amount_add: cardamount,
                  response:respdetail,
                  order_id:respdetail?.id
                  
            }
            ApiService.commonPostRequest("paypal-wallet-money-add",dataString).then((res)=>{
                if(res?.status=="success"){
                    showToast("success", "Money has been suucessfulley added to your wallet")
                setSpinnerLoading(false)
                setTimeout(() => {
                    window.location.reload();
                }, 1000); 
                } else {
                    setSpinnerLoading(false)
                    showToast('error', res.message)
                }
            })
        }
       

    }
    return (
        <>
            <Modal show={show} onHide={handleClose} size={"lg"} className="chooseAddressModal">
                <h4 className="text-center pt-4 mb-2">Add Money</h4>
                <button
                    className="pop-close"
                    onClick={() => {
                        handleClose();
                    }}
                />
                <div>
                    <div className="checkoutPanel-card">
                        {paymentmode=="" ? <>
                            <div className="mt-3">
                                <form>
                                    <div className="mb-3">
                                        <input
                                            type="number"
                                            name="amount"
                                            className="form-control"
                                            placeholder="Add Amount"
                                            value={cardamount}
                                            onChange={(e) => { setcardamount(e.target.value) }}
                                            onFocus={handleInputFocus}
                                            required
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className="cardselect">
                            <h6>Select Payment Method</h6>

                            <div className="cardselect-inner" >
                                <div className={`cardselectbox ${paymentmode == "card" ? "active" : ""}`} onClick={(e)=>{cardOption(e)}}>
                                    <ul>
                                        <li><img src="/img/visa.svg" height={38}></img></li>
                                        <li><img src="/img/master.svg" height={38}></img></li>
                                        <li><img src="/img/amex.svg" height={38}></img></li>
                                    </ul>

                                </div>
                                <div className={`cardselectbox ${paymentmode == "paypal" ? "active" : ""}`} onClick={(e)=>{paypalOption(e)}}>
                                    <ul>
                                        <li><img src="/img/paypal.svg" height={38}></img></li>
                                    </ul>


                                </div>
                            </div>

                        </div>
                        </> : 
                        
                        paymentmode=="card"?<>
                          {savedcarddata?.length > 0  ? <>
                            {savedcarddata?.map((items, index) => {
                                return (<>
                                    <div className="cardsavebox" key={index}>

                                        <div className={selectedcardid == items.ucd_four_number ? "cardsaveboxnu active" : "cardsaveboxnu"} onClick={(e) => selectedcarddetail(items)}>
                                            <div className="cardsavebox-icon">
                                                <img src={cardInage(items?.ucd_card_type)} style={{ width: "40px", marginTop: "7px" }}></img>
                                            </div>
                                            <div className="cardsavebox-number">Ending with {items?.ucd_four_number}</div>
                                            <img src="/img/correct.png" className="cardsaveboxselect"></img>
                                        </div>
                                        {cardState?.number == items?.ucd_four_number ? <>
                                            <div className="cardsavebox-cvv">
                                                <input type="number" name="cvc"
                                                    className="form-control"
                                                    placeholder="CVC"
                                                    pattern="\d{3,4}"
                                                    value={cardState?.cvc}
                                                    onChange={(e) => { handlesavedcard(e, items) }}
                                                    onFocus={handleInputFocus}
                                                    required></input>
                                                <img src="/img/cvv.svg" height={45} style={{ borderRadius: '4px' }}></img>
                                            </div>
                                        </> : ""}

                                    </div>
                                </>)
                            })}

                            <div className="cardsavebox" onClick={() => { addnewcarddetailfn() }}>
                                <div className="cardsaveboxnu">
                                    <div className="cardsavebox-icon"><img src="/img/atmcards.png" style={{ width: "40px", marginTop: "7px" }}></img></div>
                                    <div className="cardsavebox-number">Use a different card</div>
                                    <img src="/img/next.png" className="cardsaveboxarrow"></img>
                                </div>
                            </div>

                            {addnewcard?<>
                        
                        <div className="carddetal">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="crdform crdform-icon mb-3">
                                        <label>Card Number</label>
                                        <input type="text"
                                            name="number"
                                            className="form-control"
                                            placeholder="Card Number"
                                            value={cardState.number}
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                            required></input>
                                        <div className="cicon"><img src="/img/cradicon.svg" height={45} style={{ borderRadius: '4px' }}></img></div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="crdform mb-3">
                                        <label>Expiry date</label>
                                        <input type="text"
                                            name="expiry"
                                            className="form-control"
                                            placeholder="Valid Thru"
                                            pattern="\d\d/\d\d"
                                            value={cardState.expiry}
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                            required></input>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="crdform crdform-icon mb-3">
                                        <label>CVV</label>
                                        <input type="number"
                                            name="cvc"
                                            className="form-control"
                                            placeholder="CVC"
                                            pattern="\d{3,4}"
                                            value={cardState.cvc}
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                            required></input>
                                        <div className="cicon"><img src="/img/cvv.svg" height={45} style={{ borderRadius: '4px' }}></img></div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="crdform checkboxinput mb-3">
                                        <input type="checkbox" onChange={(e) => { savecarddetail(e) }}></input>
                                        Save card for next time
                                    </div>
                                </div>
                            </div>

                        </div>
                    
                    </>:""}
                        </> : <>
                      {!showcardstate?<>
                      
                        <div className="carddetal">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="crdform crdform-icon mb-3">
                                        <label>Card Number</label>
                                        <input type="text"
                                            name="number"
                                            className="form-control"
                                            placeholder="Card Number"
                                            value={cardState.number}
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                            required></input>
                                        <div className="cicon"><img src="/img/cradicon.svg" height={45} style={{ borderRadius: '4px' }}></img></div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="crdform mb-3">
                                        <label>Expiry date</label>
                                        <input type="text"
                                            name="expiry"
                                            className="form-control"
                                            placeholder="Valid Thru"
                                            pattern="\d\d/\d\d"
                                            value={cardState.expiry}
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                            required></input>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="crdform crdform-icon mb-3">
                                        <label>CVV</label>
                                        <input type="number"
                                            name="cvc"
                                            className="form-control"
                                            placeholder="CVC"
                                            pattern="\d{3,4}"
                                            value={cardState.cvc}
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                            required></input>
                                        <div className="cicon"><img src="/img/cvv.svg" height={45} style={{ borderRadius: '4px' }}></img></div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="crdform checkboxinput mb-3">
                                        <input type="checkbox" onChange={(e) => { savecarddetail(e) }}></input>
                                        Save card for next time
                                    </div>
                                </div>
                            </div>

                        </div>
                   
                      </>:""}

                        </>}
                        </>:<>
                        
                        <div className="p-3">
                                <PayPalScriptProvider options={initialOptions}>
                                <PayPalButtons style={{ layout: "horizontal" }}
                                                        createOrder={(data, actions) => {
                                                            // Define the createOrder function
                                                            return actions.order.create({
                                                                // Additional order attributes
                                                                purchase_units: [
                                                                    {
                                                                        amount: {
                                                                            currency_code: "USD",
                                                                            value: (
                                                                                Number(cardamount)
                                                                            ),
                                                                        },
                                                                    },
                                                                ],
                                                            })
                                                           
                                                        }}
                                                        onApprove={(data, actions) => {
                                                            return actions.order.capture().then(function (details) {
                                                                if(details?.status=="COMPLETED"){
                                                                    afterapproveaddinwallet(details)
                                                                    
                                                                }
                                                               
                                                            });
                                                        }}
                                                        onCancel={(data, actions) => {
                                                           console.log("cancel the page" ,data)
                                                           handleClose()
                                                           
                                                        }}
                                                        onError={(error,action)=>{
                                                            console.log(error,"responsrrr")
                                                            alert("something went wrong, please try again with the sufficient amount ")
                                                            handleClose()
                                                        }}
                                                    />
                                                </PayPalScriptProvider>
                        </div>

                        </>
                        }
                          { paymentmode == "card" ? <>
                            {showcardstate ? <>

                                <div className="cartsummary-footer">
                                    <button type="button" className="btn btn-primary btn-full btn-large" disabled={spinnerLoading} onClick={(e) => Addmoneybysavedcard(1)}>Add Payment to Wallet</button>
                                </div>
                            </> : <>
                                <div className="cartsummary-footer">
                                    <button type="button" className="btn btn-primary btn-full btn-large" disabled={spinnerLoading} onClick={(e) => AddMoneytoWallet(1)}>Add Payment to Wallet</button>
                                </div>
                            </>}
                        </> : ""}
                    </div>
                  
                </div>
            </Modal>
        </>
    )
};



export default Walletmodal;
