import React, { useEffect, useState, useRef } from "react";
import {
    BrowserView,
    MobileView,
} from "react-device-detect";
import SpinnerLoader from "../../component/Element/utils/spinnerLoader";
import { useNavigate } from "react-router-dom";
import localStorageData from "../../component/Element/utils/localStorageData";
import CartHeader from "../../component/cart_header";
import { ApiService } from "../../component/services/apiServices";
import AddressModal from "../../component/address-modal";
import { showToast } from "../../component/Element/utils/toastUtils";

const CartAddress = () => {
    const didMountRef = useRef(true);
    const navigate = useNavigate()
    const dataArray = localStorageData();
    /* const CartSession = dataArray['CartSession'];
    const CouponSession = dataArray['CouponSession'];
    const CartSummary = dataArray['CartSummary'];
    const userSession = dataArray['UserSession']; */
    const AddressSession = dataArray['AddressSession'];
    const [addressdata, setaddressdata] = useState([])
    const [selectedAddress, setSelectedAddress] = useState()
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [AddressModalshow, setAddressModalShow] = useState(false);
    const handleAddressModalClose = () => {
        setAddressModalShow(false);
        fetchaddress()
    }
    const handleAddressModalShow = () => setAddressModalShow(true);
    useEffect(() => {
        if (didMountRef.current) {
            setSpinnerLoading(true)
            fetchaddress()
        }
        didMountRef.current = false;
    }, []);

    const fetchaddress = () => {
        ApiService.commonGetRequest('get-address').then((res) => {
            if (res?.status == "success") {
                setaddressdata(res?.resUserAddress)
                setTimeout(() => {
                    setSpinnerLoading(false)
                }, 1000);
            } else if(res?.status == "session_expired") {
                setTimeout(() => {
                    setSpinnerLoading(false)
                    localStorage.removeItem("USER_SESSION");
                    navigate("/");
                }, 1000);
            }else {
                setTimeout(() => {
                    setSpinnerLoading(false)
                }, 1000);
            }
        })
    }
    const selectAddress = (address) =>{
        setSelectedAddress(address.ua_id)
        const addressCont = {};
        addressCont['delivery'] = address
        localStorage.setItem("ADDRESS_SESSION", JSON.stringify(addressCont));
    }
    const continueCheckout = () =>{
        if(AddressSession && AddressSession['delivery'] && AddressSession['delivery'].ua_id > 0){
            //navigate('/checkout')
            window.location.href = '/checkout'
        }else{
            showToast('error', 'Please select address first');
        }
    }
    return (
        <>
            {spinnerLoading ? <SpinnerLoader /> : null}
            <BrowserView>
                <CartHeader  />
                <section className="secsmall">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-4">
                                <div className="text-center mb-3"><h6>Select Delivery & Billing Address</h6></div>
                                <div className="addreesPanel">
                                    {addressdata.length > 0 ? (
                                        <div className="addreesPanel-header">
                                            <button className="btn btn-primary-outline btn-medium btn-full" onClick={(e)=>handleAddressModalShow()}><i className="fa fa-plus" aria-hidden="true"></i> Add New Address</button>
                                        </div>
                                    ) : null}
                                    {addressdata.length > 0 ? (
                                        <div className="addreesPanel-body">
                                            {addressdata.map((item, index) => (
                                                <div className={selectedAddress == item.ua_id ? "addressbox active": "addressbox" } key={index} onClick={(e) => selectAddress(item)}>
                                                    <span className="save-address-check"></span>
                                                    <h6 className="tx-14">{item.ua_name}</h6>
                                                    {item.ua_house_no ? <p className="tx-12 mb-0">House No: {item.ua_house_no}</p>:null}
                                                    {item.ua_floor ? <p className="tx-12 mb-0">Floor: {item.ua_floor}</p>:null}
                                                    <p className="tx-12 mb-0">{item.ua_complete_address} {item.ua_city_name? ', '+item.ua_city_name:''}</p>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="noimg">
                                            <img src="/img/noaddress.png" alt="No address"></img>
                                            <h6>Save Your Address Now!</h6>
                                            <p>Add Your Home and Office Addresses And Enjoy Faster Checkout</p>
                                            <button className="btn btn-primary-outline btn-medium" onClick={(e)=>handleAddressModalShow()}>
                                                <i className="fa fa-plus" aria-hidden="true"></i> Add New Address
                                            </button>
                                        </div>
                                    )}
                                    {addressdata.length > 0 ? (
                                        <div className="addreesPanel-footer">
                                            <button className="btn btn-primary btn-full btn-large" onClick={(e) => continueCheckout() }>Continue</button>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </BrowserView>
            <MobileView>
                <header className="mheader">
                <div className="mheader-left"><i className="d-icon-arrow-left tx-22 me-3" onClick={() => { navigate('/cart') }}></i>Select Address
                </div>
                <div className="mheader-right">(2/3)</div>
                </header>
                <div className="mwapper pb-5 mb-5">
                <div className="addreesPanel">
                                    {addressdata.length > 0 ? (
                                        <div className="addreesPanel-header">
                                            <button className="btn btn-primary-outline btn-medium btn-full" onClick={(e)=>handleAddressModalShow()}><i className="fa fa-plus" aria-hidden="true"></i> Add New Address</button>
                                        </div>
                                    ) : null}
                                    {addressdata.length > 0 ? (
                                        <div className="addreesPanel-body">
                                            {addressdata.map((item, index) => (
                                                <div className={selectedAddress == item.ua_id ? "addressbox active": "addressbox" } key={index} onClick={(e) => selectAddress(item)}>
                                                    <span className="save-address-check"></span>
                                                    <h6 className="tx-14">{item.ua_name}</h6>
                                                    {item.ua_house_no ? <p className="tx-12 mb-0">House No: {item.ua_house_no}</p>:null}
                                                    {item.ua_floor ? <p className="tx-12 mb-0">Floor: {item.ua_floor}</p>:null}
                                                    <p className="tx-12 mb-0">{item.ua_complete_address} {item.ua_city_name? ', '+item.ua_city_name:''}</p>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="noimg">
                                            <img src="/img/noaddress.png" alt="No address"></img>
                                            <h6>Save Your Address Now!</h6>
                                            <p>Add Your Home and Office Addresses And Enjoy Faster Checkout</p>
                                            <button className="btn btn-primary-outline btn-medium" onClick={(e)=>handleAddressModalShow()}>
                                                <i className="fa fa-plus" aria-hidden="true"></i> Add New Address
                                            </button>
                                        </div>
                                    )}
                                   
                                        
                                   
                                </div>

                </div>
                {addressdata.length > 0 ? (
                <div className="mproduct-footer">
                <button className="btn btn-primary btn-full btn-large" onClick={(e) => continueCheckout() }>Continue</button>
                </div>
                 ) : null}
            </MobileView>
            {AddressModalshow ? <AddressModal
                show={AddressModalshow}
                setShow={setAddressModalShow}
                handleClose={handleAddressModalClose}
                handleShow={handleAddressModalShow}
                addressid={''}
            /> : null}
        </>
    );
};

export default CartAddress;
