import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import {
  BrowserView,
  MobileView,
} from "react-device-detect";
import { ApiService } from "../../component/services/apiServices";
import Cartmodal from "../cart-modal/cart_modal";
import LoginModal from "../login-model";
import { showToast } from "../Element/utils/toastUtils";
const Footer = () => {
  const navigate = useNavigate();
  let apiServices = new ApiService();
  const [categorydata, setcatagorydata] = useState("");
  const [adminData, setAdmindata] = useState("");
  const [show, setShow] = useState(false);
  const [newsmessage, setnewsmessage] = useState("");
  const [errormsg, setErrorMessage] = useState("");
  const [newsletteremail, setnewslettermail] = useState("");
  const [footerCategoryData, setFooterCategoryData] = useState([]);
  const [baseimgUrl, setbaseimageUrl] = useState("");
  const [settingImageURL, setSettingImageURL] = useState("");
  const [footer1, setFooter1] = useState("");
  const [showcartmodal, setShowcartmodal] = useState(false);
  const handleShowcartmodal = () => setShowcartmodal(true);
  const handleClosecartmodal = () => setShowcartmodal(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const didMountRef = useRef(true);
  const Userdata = JSON.parse(localStorage.getItem("USER_SESSION"));
  const { id } = useParams();
  useEffect(() => {
    if (didMountRef.current) {
      apiServices
        .getsettinglistgetRequest()
        .then((res) => {
          if (res.data.status == "success") {
            setAdmindata(res?.data?.data);
            setSettingImageURL(res?.data?.setting_img_path);
          }
        })
        .catch((error) => { });

      apiServices.getfooterlisturlgetRequest().then((res) => {
        if (res.data.status == "success") {
          setFooter1(res.data.footerData.footer_desc1);
          setFooterCategoryData(res.data.featuredCategoryData);
        }
      });
    }
    didMountRef.current = false;
  });

  const Newsletter = (e) => {
    setErrorMessage("");
    if (newsletteremail == "" || newsletteremail == null) {
      showToast('error', 'Please enter email address')
      return;
    }

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newsletteremail)) {
      const email = newsletteremail.toLowerCase();
      let datastring = { newsletter_email: email };
      apiServices.getFooternewsletterpostRequest(datastring).then((res) => {
        if (res?.data?.status == "success") {
          showToast('success', res?.data?.message)
          setnewslettermail("");
        } else {
          showToast('error', res?.data?.message)
          setnewslettermail("");
        }
      });
    } else {
      showToast('error', "Invalid email address. Please enter valid email")
      return;
    }
  };

  function redirectToRestaurant(e, address) {
    e.preventDefault();
    var restuaddress = address;
    var googleMapsUrl = "https://www.google.com/maps?q=" + restuaddress;
    window.open(googleMapsUrl, '_blank');
  }


  const redirectToEmail = (e) => {
    e.preventDefault();
    let email = adminData?.admin_support_email;
    if (email !== "") {
      window.location.href = `mailto:${email}`;
    }
  };

  const phoneNumber = adminData?.admin_support_mobile
    ? adminData?.admin_support_mobile
    : "";

  const redirectToPhoneCall = (e, number) => {
    e.preventDefault();
    if (number.trim() !== "") {
      window.location.href = `tel:${number.trim()}`;
    }
  };


  return (
    <>
      <BrowserView>
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 pe-5">
                <a href="/">
                  <img src={adminData.logo != null ? settingImageURL + adminData.logo : "/img/no_img1.jpg"} alt={adminData.logo} className="footer-logo mb-2" />
                </a>
                {adminData?.admin_about && (<p>{adminData?.admin_about}</p>)}
                <div className="footer-contact">
                  <ul>
                    {adminData?.address && (
                      <li>
                        <a target="new" onClick={(e) => { redirectToRestaurant(e, adminData?.address); }} style={{ cursor: "pointer" }} >
                          <i className="d-icon-map mr-5"></i>
                          <span>{adminData?.address}</span>
                        </a>
                      </li>
                    )}
                    {adminData?.address_sec && (
                      <li>
                        <a target="new" onClick={(e) => { redirectToRestaurant(e, adminData?.address_sec); }} style={{ cursor: "pointer" }} >
                          <i className="d-icon-map mr-5"></i>
                          <span>{adminData?.address_sec}</span>
                        </a>
                      </li>
                    )}
                    {(adminData?.admin_support_mobile || adminData?.admin_support_mobile_sec || adminData?.admin_support_mobile_third) && (
                      <li>
                        <a href="javascript:void(0)" style={{ cursor: "pointer" }}>
                          <i className="d-icon-phone mr-5"></i>
                          {adminData?.admin_support_mobile && (
                            <span onClick={(e) => { redirectToPhoneCall(e, adminData?.admin_support_mobile); }}>
                              {adminData?.admin_support_mobile}
                            </span>
                          )}
                          {adminData?.admin_support_mobile_sec && (
                            <span onClick={(e) => { redirectToPhoneCall(e, adminData?.admin_support_mobile_sec); }}>
                              {adminData?.admin_support_mobile_sec}
                            </span>
                          )}
                          {adminData?.admin_support_mobile_third && (
                            <span onClick={(e) => { redirectToPhoneCall(e, adminData?.admin_support_mobile_third); }}>
                              {adminData?.admin_support_mobile_third}
                            </span>
                          )}
                        </a>
                      </li>
                    )}

                    {adminData?.admin_support_email && (
                      <li>
                        <a onClick={(e) => { redirectToEmail(e); }} style={{ cursor: "pointer" }} >
                          <i className="fa fa-envelope  mr-5"></i>
                          <span>{adminData?.admin_support_email}</span>
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-lg-6">
                    <div dangerouslySetInnerHTML={{ __html: footer1 }}></div>
                  </div>
                  <div className="col-lg-6">
                    <h4 className="footer-title">Our Menu</h4>
                    <div className="footer-list mb-3">
                      <ul>
                        {footerCategoryData?.map(
                          (items, index) => {
                            return (
                              <li key={index}>
                                <a href={`/menu/${items?.cat_slug}`}>
                                  <span>{items?.cat_name}</span>
                                </a>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <h4 className="footer-title">Download Our App</h4>
                    <div className="row">
                      <div className="col-6"><a href={adminData?.play_store_url} target="blank"><img src="/img/androidicon.png" style={{ width: '100%' }}></img></a></div>
                      <div className="col-6"><a href={adminData?.app_store_url} target="blank"><img src="/img/appleicon.png" style={{ width: '100%' }}></img></a></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <h4 className="footer-title">Newsletter</h4>
                <p>
                  Simply enter your email address and we will send you daily fresh
                  menu, discount codes and promotions.
                </p>
                <div className="subscribe-form mb-4">
                  <input
                    className="email newsletterRequired"
                    type="email"
                    placeholder="Enter your email here.."
                    name="newsletter_email"
                    value={newsletteremail}
                    required
                    onChange={(e) => {
                      setnewslettermail(e.target.value);
                      setErrorMessage("");
                      setnewsmessage("");
                    }}
                  />
                  <div className="subscribe-button">
                    <input
                      id="mc-embedded-subscribe"
                      className="button"
                      type="button"
                      name="subscribe"
                      value="Subscribe"
                      onClick={(e) => {
                        Newsletter(e);
                      }}
                    />
                    {newsmessage ? (
                      <>
                        <span className="text-success">{newsmessage}</span>
                      </>
                    ) : (
                      ""
                    )}
                    {errormsg ? (
                      <>
                        <span className="text-danger">{errormsg}</span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <h4 className="footer-title">Follow Us </h4>
                <div className="footer-social">
                  <ul>
                    {adminData.facebook_url != null ? (
                      <li>
                        <a href={adminData?.facebook_url} target="new">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {adminData?.linkedin_url != null ? (
                      <li>
                        <a href={adminData?.linkedin_url} target="new">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {adminData?.twitter_url != null ? (
                      <li>
                        <a href={adminData?.twitter_url} target="new">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {adminData?.youtube_url != null ? (
                      <li>
                        <a href={adminData?.youtube_url} target="new">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {adminData?.instagram_url != null ? (
                      <li>
                        <a href={adminData?.instagram_url} target="new">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="copyright text-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                © Copyright 2024. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 pe-5">
                <a href="/">
                  <img
                    src={
                      adminData.logo != null
                        ? settingImageURL + adminData.logo
                        : "/img/no_img.jpg"
                    }
                    alt={adminData.logo}
                    className="footer-logo mb-2"
                  />
                </a>
                {adminData?.admin_about && (<p>{adminData?.admin_about}</p>)}
                <div className="footer-contact mb-4">
                  <ul>
                    {adminData?.address && (
                      <li>
                        <a target="new" onClick={(e) => { redirectToRestaurant(e, adminData?.address); }} style={{ cursor: "pointer" }} >
                          <i className="d-icon-map mr-5"></i>
                          <span>{adminData?.address}</span>
                        </a>
                      </li>
                    )}
                    {adminData?.address_sec && (
                      <li>
                        <a target="new" onClick={(e) => { redirectToRestaurant(e, adminData?.address_sec); }} style={{ cursor: "pointer" }} >
                          <i className="d-icon-map mr-5"></i>
                          <span>{adminData?.address_sec}</span>
                        </a>
                      </li>
                    )}
                    {(adminData?.admin_support_mobile || adminData?.admin_support_mobile_sec || adminData?.admin_support_mobile_third) && (
                      <li>
                        <a href="javascript:void(0)" style={{ cursor: "pointer" }}>
                          <i className="d-icon-phone mr-5"></i>
                          {adminData?.admin_support_mobile && (
                            <span onClick={(e) => { redirectToPhoneCall(e, adminData?.admin_support_mobile); }}>
                              {adminData?.admin_support_mobile}
                            </span>
                          )}
                          {adminData?.admin_support_mobile_sec && (
                            <span onClick={(e) => { redirectToPhoneCall(e, adminData?.admin_support_mobile_sec); }}>
                              {adminData?.admin_support_mobile_sec}
                            </span>
                          )}
                          {adminData?.admin_support_mobile_third && (
                            <span onClick={(e) => { redirectToPhoneCall(e, adminData?.admin_support_mobile_third); }}>
                              {adminData?.admin_support_mobile_third}
                            </span>
                          )}
                        </a>
                      </li>
                    )}

                    {adminData?.admin_support_email && (
                      <li>
                        <a onClick={(e) => { redirectToEmail(e); }} style={{ cursor: "pointer" }} >
                          <i className="fa fa-envelope  mr-5"></i>
                          <span>{adminData?.admin_support_email}</span>
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-lg-6 col-6">
                    <div dangerouslySetInnerHTML={{ __html: footer1 }}></div>
                  </div>
                  <div className="col-lg-6 col-6">
                    <h4 className="footer-title">Our Menu</h4>
                    <div className="footer-list mb-3">
                      <ul>
                        {footerCategoryData?.map(
                          (items, index) => {
                            return (
                              <li key={index}>
                                <a href={`/menu/${items?.cat_slug}`}>
                                  <span>{items?.cat_name}</span>
                                </a>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <h4 className="footer-title">Download Our App</h4>
                    <div className="row mb-4">
                      <div className="col-6"><a href={adminData?.play_store_url} target="blank"><img src="/img/androidicon.png" style={{ width: '100%' }}></img></a></div>
                      <div className="col-6"><a href={adminData?.app_store_url} target="blank"><img src="/img/appleicon.png" style={{ width: '100%' }}></img></a></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <h4 className="footer-title">Newsletter</h4>
                <p>
                  Simply enter your email address and we will send you daily fresh
                  menu, discount codes and promotions.
                </p>
                <div className="subscribe-form mb-4">
                  <input
                    className="email newsletterRequired"
                    type="email"
                    placeholder="Enter your email here.."
                    name="newsletter_email"
                    required
                    onChange={(e) => {
                      setnewslettermail(e.target.value);
                      setErrorMessage("");
                      setnewsmessage("");
                    }}
                  />
                  <div className="subscribe-button">
                    <input
                      id="mc-embedded-subscribe"
                      className="button"
                      type="button"
                      name="subscribe"
                      value="Subscribe"
                      onClick={(e) => {
                        Newsletter(e);
                      }}
                    />
                    {newsmessage ? (
                      <>
                        <span className="text-success">{newsmessage}</span>
                      </>
                    ) : (
                      ""
                    )}
                    {errormsg ? (
                      <>
                        <span className="text-danger">{errormsg}</span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <h4 className="footer-title">Follow Us </h4>
                <div className="footer-social">
                  <ul>
                    {adminData.facebook_url != null ? (
                      <li>
                        <a href={adminData?.facebook_url} target="new">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {adminData?.linkedin_url != null ? (
                      <li>
                        <a href={adminData?.linkedin_url} target="new">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {adminData?.twitter_url != null ? (
                      <li>
                        <a href={adminData?.twitter_url} target="new">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {adminData?.youtube_url != null ? (
                      <li>
                        <a href={adminData?.youtube_url} target="new">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {adminData?.instagram_url != null ? (
                      <li>
                        <a href={adminData?.instagram_url} target="new">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="appBottomMenu">
          <a href="/" className="item active">
            <div className="col">
              <i className="d-icon-home"></i>
              <span>Home</span>
            </div>
          </a>
          <a href="/menu" className="item">
            <div className="col">
              <i className="d-icon-layer"></i>
              <span>Menu</span>
            </div>
          </a>
          {Userdata == null || Userdata == "" ? (
            <>
              <a href="" className="item" onClick={(e) => { e.preventDefault(); handleShow() }}>
                <div className="col">
                  <i className="d-icon-truck"></i>
                  <span>Orders</span>
                </div>
              </a>
            </>
          ) : (
            <a href="/account/orders" className="item">
              <div className="col">
                <i className="d-icon-truck"></i>
                <span>Orders</span>
              </div>
            </a>
          )}
          <a href="" className="item" onClick={(e) => { e.preventDefault(); handleShowcartmodal() }}>
            <div className="col">
              <i className="d-icon-bag"></i>
              <span>Cart</span>
            </div>
          </a>

          {Userdata == null || Userdata == "" ? (
            <>
              <a href="" className="item" onClick={(e) => { e.preventDefault(); handleShow() }}>
                <div className="col">
                  <i className="d-icon-user"></i>
                  <span>Account</span>
                </div>
              </a>
            </>
          ) : (
            <>
              <a href="/account/account-overview" className="item">
                <div className="col">
                  <i className="d-icon-user"></i>
                  <span>Account</span>
                </div>
              </a>
            </>
          )}
        </div>
      </MobileView>
      {showcartmodal ? <Cartmodal show={showcartmodal}
        setShow={setShowcartmodal}
        handleClose={handleClosecartmodal}
        handleShow={handleShowcartmodal} /> : null}
      {show ? <LoginModal
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        handleShow={handleShow}
      /> : null}
    </>
  );
};

export default Footer;
