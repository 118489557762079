import React, { useEffect, useRef, useState } from "react";
import Header from '../../component/Header'
import Footer from '../../component/Footer'
import { useNavigate, useParams } from "react-router-dom";
import { ApiService } from "../../component/services/apiServices";
import { Breadcrumb, Container, Row, Col } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import { Helmet } from "react-helmet";
import constants from "../../component/services/constants";
let apiServices = new ApiService();

function Pages() {
  const navigate = useNavigate();
  const { slug } = useParams();
  const didMountRef = useRef(true)

  const [pageData, setPageData] = useState({});
  const [pageContent, setPageContent] = useState("");

  useEffect(() => {
    if (didMountRef.current) {

      const getpagesData = {
        slug: slug,
      };

      apiServices.getpagesDataUrlpostrequest(getpagesData).then(res => {
        if (res.data.status == 'success') {
          setPageData(res?.data.data)
          setPageContent(res.data?.data?.page_content);
          console.log("res.data?.data?.page_content", res.data?.data?.page_content);
        }
      })

    }
    didMountRef.current = false;

  }, [])



  return (

    <>
      <Helmet>
        <title>{pageData?.page_meta_title != null ? pageData.page_meta_title : "Daadi's Kitchen"}</title>
        <meta name="description" itemprop="description" content={pageData?.page_meta_desc != null ? pageData?.page_meta_desc : "Daadi's Kitchen"} />
        {pageData?.page_meta_keyword != null ? <meta name="keywords" content={pageData?.page_meta_keyword} /> : "Daadi's Kitchen"}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={pageData?.page_meta_title} />
        <meta name="twitter:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={constants.FRONT_URL + 'img/logo.png'} />
        <meta property="og:url" content={window.location.href} />
        {pageData?.page_meta_desc != null ? (
          <meta property="og:description" content={pageData?.page_meta_desc} />
        ) : (
          <meta property="og:description" content="Daadi's Kitchen" />
        )}
        <meta name="twitter:title" content={pageData?.page_meta_title} />
        {pageData?.page_meta_desc != null ? <meta name="twitter:description" content={pageData?.page_meta_desc} /> : "Daadi's Kitchen"}
        <meta property="twitter:image" content={constants.FRONT_URL + 'img/logo.png'} />

      </Helmet>
      <BrowserView>
        <Header />
        <div className="subheader subheader-small">
          <Container>
            <Row>
              <Col lg={12}>
                <h1>{pageData?.page_name}</h1>
                <Breadcrumb className="breadcrumb-inner">
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>  {pageData?.page_name}</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>

        <section className="secsmall">

          {pageContent != null ? (
            <div dangerouslySetInnerHTML={{ __html: pageContent }}></div>
          ) : (
            ""
          )}
        </section>

        <Footer />
      </BrowserView>

      <MobileView>
        <div className="mwapper">
          <header className="mheader">
            <div className="mheader-left"><i className="d-icon-arrow-left tx-22" onClick={() => { navigate("/account/about-us") }}></i><h6 className="mb-0 mt-1 ms-3">{pageData?.page_name}</h6></div>
          </header>
          {pageContent != null ? (
            <div dangerouslySetInnerHTML={{ __html: pageContent }}></div>
          ) : (
            ""
          )}
        </div>
      </MobileView>
    </>


  )
}

export default Pages