 //https://codexo.live/2024/daadiskitchen/api/web-api
//const API_URL="https://codexo.live/2024/daadiskitchen/api/web-api"
const API_URL="https://daadiskitchen.com.au/csadmin/api/web-api/"
const FRONT_URL = 'https://daadiskitchen.com.au/'
const Google_api_key="AIzaSyAE7oxhbgUxiIzikhCeN6TB6UUe1frQnZs"
const API_URL2=""



const CATEGORIES = [];
const themesetting = {};
export default { API_URL, CATEGORIES, themesetting ,FRONT_URL,API_URL2,Google_api_key };