import React, { useEffect, useState, useRef } from "react";
import {
   BrowserView,
   MobileView,
} from "react-device-detect";
import SpinnerLoader from "../../component/Element/utils/spinnerLoader";
import Footer from "../../component/Footer";
import { useNavigate } from "react-router-dom";
import localStorageData from "../../component/Element/utils/localStorageData";
import LoginModal from "../../component/login-model";
import TiffinViewDetailsModal from "../../component/Element/modal/tiffinviewdetails-modal";
import CartHeader from "../../component/cart_header";
import getInfo from "../../component/Element/utils/commonUtils";
import { ApiService } from "../../component/services/apiServices";
import { showToast } from "../../component/Element/utils/toastUtils";
import MobileHeader from "../../component/Element/mobile_header";
const Cart = () => {
   const didMountRef = useRef(true);
   const [showLoginModal, setShowLoginModal] = useState(false);
   const handleShowLoginModal = () => setShowLoginModal(true);
   const handleCloseLoginModal = () => setShowLoginModal(false);
   const { dayOfWeek, dayOfMonth, time, formattedTime, formattedDate } = getInfo();
   const navigate = useNavigate()
   const [deliveryType, setDeliveryType] = useState("delivery")
   const [variationData, setVariationData] = useState([])
   const dataArray = localStorageData();
   const CartSession = dataArray['CartSession'];
   const userSession = dataArray['UserSession'];
   const AddressSession = dataArray['AddressSession'];
   const [adminData, setadminData] = useState()
   let lunchStatusParent = false
   let dinnerStatusParent = false
   const mappedCartData = CartSession.reduce((acc, curr) => {
      if (!acc[curr.product_day]) {
         acc[curr.product_day] = [];
      }
      acc[curr.product_day].push(curr);
      return acc;
   }, {});
   const [spinnerLoading, setSpinnerLoading] = useState(false);
   useEffect(() => {
      if (didMountRef.current) {
         setSpinnerLoading(true)
         ApiService.commonGetRequest('setting-data').then(res => {
            if (res.status == 'success') {
               setadminData(res?.data)
               const address = {};
               address['delivery'] = {}
               localStorage.setItem("ADDRESS_SESSION", JSON.stringify(address));
               setTimeout(() => {
                  setSpinnerLoading(false)
               }, 500);
            }
         }).catch((error) => {
            setTimeout(() => {
               setSpinnerLoading(false)
            }, 500);
         })
      }
      didMountRef.current = false;
   }, []);

   const chooseDeliveryType = (deliveryType) => {
      if (deliveryType == 'pickup') {
         lunchStatusParent = false
         dinnerStatusParent = false
      } else {
         lunchStatusParent = true
         dinnerStatusParent = true
      }
      const address = {};
      address[deliveryType] = {}
      localStorage.setItem("ADDRESS_SESSION", JSON.stringify(address));
      setDeliveryType(deliveryType)
   }
   
   const minustocart = (productData) => {
      setSpinnerLoading(true);
      let cartSession = localStorage.getItem("CART_SESSION");
      cartSession = cartSession ? JSON.parse(cartSession) : [];
      const existingProductIndex = cartSession.findIndex((item) => {
         return (
            item.product_id === productData.product_id && item.product_meal_time === productData.product_meal_time &&
            JSON.stringify(item.product_variation) ===
            JSON.stringify(productData.product_variation)
         );
      });
      if (existingProductIndex !== -1) {
         if (cartSession[existingProductIndex].quantity === 1) {
            cartSession.splice(existingProductIndex, 1);
         } else {
            cartSession[existingProductIndex].quantity -= 1;
         }
         localStorage.setItem("CART_SESSION", JSON.stringify(cartSession));
      }
      setTimeout(() => {
         setSpinnerLoading(false);
      }, 500);
   };

   const plustocart = (productData) => {
      setSpinnerLoading(true);
      let cartSession = localStorage.getItem("CART_SESSION");
      cartSession = cartSession ? JSON.parse(cartSession) : [];
      const existingProductIndex = cartSession.findIndex((item) => {
         return (
            item.product_id === productData.product_id && item.product_meal_time === productData.product_meal_time &&
            JSON.stringify(item.product_variation) ===
            JSON.stringify(productData.product_variation)
         );
      });     
      cartSession[existingProductIndex].quantity += 1;
      localStorage.setItem("CART_SESSION", JSON.stringify(cartSession));
      setTimeout(() => {
         setSpinnerLoading(false);
      }, 500);
   };

   const removeProduct = (productData) => {
      setSpinnerLoading(true);
      let cartSession = localStorage.getItem("CART_SESSION");
      cartSession = cartSession ? JSON.parse(cartSession) : [];
      const existingProductIndex = cartSession.findIndex((item) => {
         return (
            item.product_id === productData.product_id &&
            JSON.stringify(item.product_variation) ===
            JSON.stringify(productData.product_variation)
         );
      });
      if (existingProductIndex !== -1) {
         cartSession.splice(existingProductIndex, 1);
         localStorage.setItem("CART_SESSION", JSON.stringify(cartSession));
      }
      setTimeout(() => {
         setSpinnerLoading(false);
      }, 500);
   };

   const getNextDate = (day) => {
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const today = new Date();
      const todayDay = today.getDay();
      const inputDay = daysOfWeek.indexOf(day);

      if (inputDay === todayDay) {
         return `${today.getDate()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getFullYear()}`;
      }

      let daysToAdd = inputDay - todayDay;
      if (daysToAdd <= 0) {
         daysToAdd += 7; // Add 7 days to get the next occurrence of the day
      }

      const nextDate = new Date(today);
      nextDate.setDate(nextDate.getDate() + daysToAdd);

      const formattedDate = `${nextDate.getDate()}-${(nextDate.getMonth() + 1).toString().padStart(2, '0')}-${nextDate.getFullYear()}`;

      return formattedDate; // Return the formatted date
   };

   const [showTiffinViewModal, setShowTiffinViewModal] = useState(false);
   const handleCloseTiffinViewModal = () => setShowTiffinViewModal(false);
   const handleShowTiffinViewModal = (variationData) => {
      setVariationData(variationData)
      setShowTiffinViewModal(true)
   };
   const continuecheckout = () => {
      let counterStatus = 0;
      Object.entries(mappedCartData).forEach(([day, items], index) => {
         let errorMessage = '';
         const lunchStatus = items.some((item) => item.product_meal_lunch_status == true && item.product_meal_time == 'lunch');
         const dinnerStatus = items.some((item) => item.product_meal_dinner_status == true && item.product_meal_time == 'dinner');
         if (lunchStatus && deliveryType == 'delivery') {
            counterStatus += 1;
            errorMessage =
               'Lunch order time has ended. Please either remove this tiffin or select the pickup option to receive it.';
            showToast('error', errorMessage);
            return false;
         } else if (dinnerStatus && deliveryType == 'delivery') {
            counterStatus += 1;
            errorMessage =
               'Dinner order time has ended. Please either remove this tiffin or select the pickup option to receive it.';
            showToast('error', errorMessage);
            return false;
         } else {
            items.forEach((item, itemIndex) => {
               let timeStatus = false;
               if (item.product_meal_lunch_status && item.product_meal_time == 'lunch') {
                  timeStatus = true;
               } else if (item.product_meal_dinner_status && item.product_meal_time == 'dinner') {
                  timeStatus = true;
               }
               /* if (checkInnerCondition(timeStatus, deliveryType, formattedDate, day)) {
                  counterStatus += 1;
                  if (counterStatus == 1) {
                     showToast('error', 'Remove this item because pickup option available only current day.');
                  }
                  return false;
               } */
            });
         }
      });
      if (counterStatus == 0) {
         if (userSession) {
            if (AddressSession && AddressSession['delivery']) {
               navigate('/cart-address')
            } else {
               navigate('/checkout')
            }

         } else {
            handleShowLoginModal()
         }

      }
   }

   const addMoreItems = () => {
      navigate('/add-more-items')
   }

   const checkInnerCondition = (timeStatus, deliveryType, formattedDate, day) => {
      if (deliveryType == 'delivery') {
         if (timeStatus) {
            return true
         } else {
            return false
         }
      } else {
         return false;
         if (timeStatus && formattedDate == getNextDate(day)) {
            return false
         } else if (timeStatus || formattedDate != getNextDate(day)) {
            return true
         } else {
            return false
         }
      }
   }

   return (
      <>
         {spinnerLoading ?
            <SpinnerLoader />
            : null}
         <BrowserView>
            <CartHeader></CartHeader>
            <section className="secsmall">
               <div className="container">
                  <div className="row align-items-center justify-content-center">
                     {Object.entries(mappedCartData).length > 0 ? (
                        <div className="col-lg-6">
                           <div className="cartPanel">
                              <div className="cartPanel-top">
                                 <div className={deliveryType == 'delivery' ? 'cptimebox active' : 'cptimebox'} onClick={() => chooseDeliveryType('delivery')}>
                                    <div className="cptimebox-icon">
                                       <img src="/img/food-undelivery.png"></img>
                                    </div>
                                    <div className="cptimebox-text">
                                       <h6 className="mb-0">Delivery</h6>
                                       <p className="mb-0 tx-12">Calculated with an address</p>
                                    </div>
                                 </div>
                                 <div className={deliveryType == 'pickup' ? 'cptimebox active' : 'cptimebox'} onClick={() => chooseDeliveryType('pickup')}>
                                    <div className="cptimebox-icon">
                                       <img src="/img/restaurant.png"></img>
                                    </div>
                                    <div className="cptimebox-text">
                                       <h6 className="mb-0">Pickup</h6>
                                       <p className="mb-0 tx-12">Free & Order Pickup from restraunts</p>
                                    </div>
                                 </div>
                              </div>
                              <div className="cartPanel-middle">
                                 <div className="carItembox">
                                    <div className="carItemboxbody">
                                       {Object.entries(mappedCartData).map(([day, items], index) => {
                                          let totalSum = 0, deliveryAmount = 0, deliverytotalSum = 0, product_type = false; let errorMessage = '';
                                          const lunchStatus = items.some((item) => item.product_meal_lunch_status == true && item.product_meal_time == 'lunch');
                                          const dinnerStatus = items.some((item) => item.product_meal_dinner_status == true && item.product_meal_time == 'dinner');
                                          if (lunchStatus) {
                                             errorMessage =
                                                'Lunch order time has ended. Please either remove this tiffin or select the pickup option to receive it.';
                                          } else if (dinnerStatus) {
                                             errorMessage =
                                                'Dinner order time has ended. Please either remove this tiffin or select the pickup option to receive it.';
                                          }
                                          return (
                                             <div className="cItembSection" key={index}>
                                                <div className="carItembox-title">
                                                   <div>{day}</div>
                                                   <div>Delivery Date: {getNextDate(day)}</div>
                                                </div>
                                                {errorMessage && deliveryType == 'delivery' ? <div className="alert alert-warning" role="alert">
                                                   {errorMessage}
                                                </div> : null}
                                                {/* {deliveryType == 'pickup' && formattedDate != getNextDate(day) ? <div className="alert alert-warning" role="alert">
                                                   Remove this item because pickup option available only current day.
                                                </div> : null} */}
                                                {items.map((item, itemIndex) => {
                                                   totalSum += (parseFloat(item.product_selling_price) + parseFloat(item.product_addon_price)) * Number(item.quantity);
                                                   if (item.product_type == 1 && product_type == false) {
                                                      product_type = true
                                                   }
                                                   if (product_type && deliveryType == 'delivery') {
                                                      deliverytotalSum = items.reduce((total, item) => {
                                                         let price = (parseFloat(item.product_selling_price) + parseFloat(item.product_addon_price)) * Number(item.quantity);
                                                         return total + price;
                                                     }, 0);
                                                      //deliverytotalSum += (parseFloat(item.product_selling_price) + parseFloat(item.product_addon_price)) * Number(item.quantity);
                                                      if (deliverytotalSum > adminData?.shipping_amount_free) {
                                                         deliveryAmount = 0
                                                      } else {
                                                         deliveryAmount = adminData && adminData.shipping_amount_tiffin ? adminData.shipping_amount_tiffin : 0
                                                      }
                                                   } else if(deliveryType == 'delivery'){
                                                      deliverytotalSum = items.reduce((total, item) => {
                                                         let price = (parseFloat(item.product_selling_price) + parseFloat(item.product_addon_price)) * Number(item.quantity);
                                                         return total + price;
                                                     }, 0);
                                                      //deliverytotalSum += (parseFloat(item.product_selling_price) + parseFloat(item.product_addon_price)) * Number(item.quantity);
                                                      if (deliverytotalSum > adminData?.shipping_amount_free) {
                                                         deliveryAmount = 0
                                                      } else {
                                                         deliveryAmount = adminData && adminData.shipping_amount_other ? adminData.shipping_amount_other : 0
                                                      }
                                                   }
                                                   let timeStatus = false;
                                                   if (item.product_meal_lunch_status && item.product_meal_time == 'lunch') {
                                                      timeStatus = true;
                                                   } else if (item.product_meal_dinner_status && item.product_meal_time == 'dinner') {
                                                      timeStatus = true;
                                                   }
                                                   return (
                                                      <div className={checkInnerCondition(timeStatus, deliveryType, formattedDate, day) ? "cItemb remove" : "cItemb"} key={itemIndex}>{/* remove */}
                                                         <div className="cItemb-media">
                                                            <img src={item.product_image}></img>
                                                         </div>
                                                         <div className="cItemb-content">
                                                            <h6 className="fw600 mb-0">{item.product_name}</h6>
                                                            <p className="tx-gray mb-1">{item.product_meal_time == 'lunch' ? item.adminData.lunch_note : item.adminData.dinner_note}</p>
                                                            {item.product_type == 1 ? <div className="Vdetails" onClick={(e) => handleShowTiffinViewModal(item.product_variation)}>View Details <i className="d-icon-angle-right"></i></div> : null}
                                                            <p className="fw600 mb-0 mt-1">${(parseFloat(item.product_selling_price) + parseFloat(item.product_addon_price)).toFixed(2)}</p>
                                                            <div className="cItemb-footer mt-1">
                                                               <div className="qty-changer">
                                                                  <button onClick={(e) => minustocart(item)}><i className="fas fa-minus psbmiuns"></i></button>
                                                                  <span>{item.quantity}</span>
                                                                  <button onClick={(e) => plustocart(item)}><i className="fas fa-plus psbplus"></i></button>
                                                               </div>
                                                               <div className="cItemb-Itemremove" onClick={(e) => removeProduct(item)}><i className="fa fa-trash" aria-hidden="true"></i></div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   );
                                                })}
                                                <div className="cItemb-add-more" onClick={(e) => addMoreItems()}><i className="fa fa-plus" aria-hidden="true"></i> {deliveryAmount > 0 ? 'Add More Items' : 'Add More Items'}</div>
                                                <div className="cItembPricelist">
                                                   <h6>Items Summary</h6>
                                                   <ul>
                                                      <li>Item Total<span>${totalSum.toFixed(2)}</span></li>
                                                      <li>Delivery {deliveryAmount > 0 ? <span>${deliveryAmount}</span> : <span className="tx-green">Free</span>}</li>
                                                      <hr></hr>
                                                      <li>Item Total Amount<span>${totalSum > 0 ? (Number(totalSum) + Number(deliveryAmount)).toFixed(2) : '0.00'}</span></li>
                                                   </ul>
                                                </div>
                                             </div>
                                          );
                                       })}
                                    </div>
                                    <div className="carItemboxfooter">
                                       <button className="btn btn-primary btn-full btn-large" onClick={(e) => continuecheckout()}>Continue</button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     ) : (
                        <div className="noimg">
                           <img src="/img/empty-cart.webp" className="img-fluid mb-3" />
                           <h6>Your cart is empty!</h6>
                           <p>There is nothing in your cart. Let's add some items</p>
                           <a
                              href="/"
                              className="btn btn-primary-outline btn-block btn-medium"
                           >
                              Continue Shopping
                           </a>
                        </div>
                     )}
                  </div>
               </div>
            </section>
            <Footer />
         </BrowserView>
         <MobileView>
            <header className="mheader">
               <div className="mheader-left"><i className="d-icon-arrow-left tx-22 me-3" onClick={() => { navigate('/') }}></i>Shopping Cart
               </div>
               <div className="mheader-right">(1/3)</div>
            </header>
            <div className="mwapper pb-5 mb-5">

               {Object.entries(mappedCartData).length > 0 ? (
                  <>
                     <div className="cartPanel">
                        <div className="cartPanel-top">
                           <div className={deliveryType == 'delivery' ? 'cptimebox active' : 'cptimebox'} onClick={() => chooseDeliveryType('delivery')}>
                              <div className="cptimebox-icon">
                                 <img src="/img/food-undelivery.png"></img>
                              </div>
                              <div className="cptimebox-text">
                                 <h6 className="mb-0">Delivery</h6>
                                 <p className="mb-0 tx-12">Calculated with an address</p>
                              </div>
                           </div>
                           <div className={deliveryType == 'pickup' ? 'cptimebox active' : 'cptimebox'} onClick={() => chooseDeliveryType('pickup')}>
                              <div className="cptimebox-icon">
                                 <img src="/img/restaurant.png"></img>
                              </div>
                              <div className="cptimebox-text">
                                 <h6 className="mb-0">Pickup</h6>
                                 <p className="mb-0 tx-12">Free & Order Pickup from restraunts</p>
                              </div>
                           </div>
                        </div>
                        <div className="cartPanel-middle">
                           <div className="carItembox">
                              <div className="carItemboxbody">
                                 {Object.entries(mappedCartData).map(([day, items], index) => {
                                    let totalSum = 0, deliveryAmount = 0, deliverytotalSum = 0, product_type = false; let errorMessage = '';
                                    const lunchStatus = items.some((item) => item.product_meal_lunch_status == true && item.product_meal_time == 'lunch');
                                    const dinnerStatus = items.some((item) => item.product_meal_dinner_status == true && item.product_meal_time == 'dinner');
                                    if (lunchStatus) {
                                       errorMessage =
                                          'Lunch order time has ended. Please either remove this tiffin or select the pickup option to receive it.';
                                    } else if (dinnerStatus) {
                                       errorMessage =
                                          'Dinner order time has ended. Please either remove this tiffin or select the pickup option to receive it.';
                                    }
                                    return (
                                       <div className="cItembSection" key={index}>
                                          <div className="carItembox-title">
                                             <div>{day}</div>
                                             <div>Delivery Date: {getNextDate(day)}</div>
                                          </div>
                                          {errorMessage && deliveryType == 'delivery' ? <div className="alert alert-warning" role="alert">
                                             {errorMessage}
                                          </div> : null}
                                          {/* {deliveryType == 'pickup' && formattedDate != getNextDate(day) ? <div className="alert alert-warning" role="alert">
                                             Remove this item because pickup option available only current day.
                                          </div> : null} */}
                                          {items.map((item, itemIndex) => {
                                             totalSum += (parseFloat(item.product_selling_price) + parseFloat(item.product_addon_price)) * Number(item.quantity);
                                             if (item.product_type == 1 && product_type == false) {
                                                product_type = true
                                             }
                                             if (product_type && deliveryType == 'delivery') {
                                                deliverytotalSum = items.reduce((total, item) => {
                                                   let price = (parseFloat(item.product_selling_price) + parseFloat(item.product_addon_price)) * Number(item.quantity);
                                                   return total + price;
                                               }, 0);
                                                //deliverytotalSum += (parseFloat(item.product_selling_price) + parseFloat(item.product_addon_price)) * Number(item.quantity);
                                                if (deliverytotalSum > adminData?.shipping_amount_free) {
                                                   deliveryAmount = 0
                                                } else {
                                                   deliveryAmount = adminData && adminData.shipping_amount_tiffin ? adminData.shipping_amount_tiffin : 0
                                                }
                                             } else if(deliveryType == 'delivery') {
                                                deliverytotalSum = items.reduce((total, item) => {
                                                   let price = (parseFloat(item.product_selling_price) + parseFloat(item.product_addon_price)) * Number(item.quantity);
                                                   return total + price;
                                               }, 0);
                                                //deliverytotalSum += (parseFloat(item.product_selling_price) + parseFloat(item.product_addon_price)) * Number(item.quantity);
                                                if (deliverytotalSum > adminData?.shipping_amount_free) {
                                                   deliveryAmount = 0
                                                } else {
                                                   deliveryAmount = adminData && adminData.shipping_amount_other ? adminData.shipping_amount_other : 0
                                                }
                                             }
                                             let timeStatus = false;
                                             if (item.product_meal_lunch_status && item.product_meal_time == 'lunch') {
                                                timeStatus = true;
                                             } else if (item.product_meal_dinner_status && item.product_meal_time == 'dinner') {
                                                timeStatus = true;
                                             }
                                             return (
                                                <div className={checkInnerCondition(timeStatus, deliveryType, formattedDate, day) ? "cItemb remove" : "cItemb"} key={itemIndex}>{/* remove */}
                                                   <div className="cItemb-media">
                                                      <img src={item.product_image}></img>
                                                   </div>
                                                   <div className="cItemb-content">
                                                      <h6 className="fw600 mb-0">{item.product_name}</h6>
                                                      <p className="tx-gray mb-1">{item.product_meal_time == 'lunch' ? item.adminData.lunch_note : item.adminData.dinner_note}</p>
                                                      {item.product_type == 1 ? <div className="Vdetails" onClick={(e) => handleShowTiffinViewModal(item.product_variation)}>View Details <i className="d-icon-angle-right"></i></div> : null}
                                                      <p className="fw600 mb-0 mt-1">${(parseFloat(item.product_selling_price) + parseFloat(item.product_addon_price)).toFixed(2)}</p>
                                                      <div className="cItemb-footer mt-1">
                                                         <div className="qty-changer">
                                                            <button onClick={(e) => minustocart(item)}><i className="fas fa-minus psbmiuns"></i></button>
                                                            <span>{item.quantity}</span>
                                                            <button onClick={(e) => plustocart(item)}><i className="fas fa-plus psbplus"></i></button>
                                                         </div>
                                                         <div className="cItemb-Itemremove" onClick={(e) => removeProduct(item)}><i className="fa fa-trash" aria-hidden="true"></i></div>
                                                      </div>
                                                   </div>
                                                </div>
                                             );
                                          })}
                                          <div className="cItemb-add-more" onClick={(e) => addMoreItems()}><i className="fa fa-plus" aria-hidden="true"></i> {deliveryAmount > 0 ? 'Add More Items' : 'Add More Items'}</div>
                                          <div className="cItembPricelist">
                                             <h6>Items Summary</h6>
                                             <ul>
                                                <li>Item Total<span>${totalSum.toFixed(2)}</span></li>
                                                <li>Delivery {deliveryAmount > 0 ? <span>${deliveryAmount}</span> : <span className="tx-green">Free</span>}</li>
                                                <hr></hr>
                                                <li>Item Total Amount<span>${totalSum > 0 ?(Number(totalSum) + Number(deliveryAmount)).toFixed(2) : '0.00' }</span></li>
                                             </ul>
                                          </div>
                                       </div>
                                    );
                                 })}
                              </div>

                           </div>
                        </div>
                     </div>
                     <div className="mproduct-footer"><button className="btn btn-primary btn-full btn-large" onClick={(e) => continuecheckout()}>Continue</button></div>
                  </>
               ) : (
                  <div className="noimg">
                     <img src="/img/empty-cart.webp" className="img-fluid mb-3" />
                     <h6>Your cart is empty!</h6>
                     <p>There is nothing in your cart. Let's add some items</p>
                     <a
                        href="/"
                        className="btn btn-primary-outline btn-block btn-small"
                     >
                        Continue Shopping
                     </a>
                  </div>
               )}

            </div>

         </MobileView>
         {showLoginModal ? (
            <LoginModal
               show={showLoginModal}
               setShow={setShowLoginModal}
               handleClose={handleCloseLoginModal}
               handleShow={handleShowLoginModal}
               goToRoute={'/cart-address'}
            />
         ) : null}
         {showTiffinViewModal ? (
            <TiffinViewDetailsModal
               show={showTiffinViewModal}
               setShow={setShowTiffinViewModal}
               handleClose={handleCloseTiffinViewModal}
               handleShow={handleShowTiffinViewModal}
               variationData={variationData}
            />
         ) : null}
      </>
   );
};
export default Cart;
