import React, { useEffect, useRef, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import { useNavigate } from "react-router-dom";
import {
    Breadcrumb,
    Col,
    Container,
    Row,
} from "react-bootstrap";
import { ApiService } from "../../component/services/apiServices";
import { Helmet } from "react-helmet";
import constants from "../../component/services/constants";
let apiServices = new ApiService();

const OurStores = () => {
    const navigate = useNavigate();
    const didMountRef = useRef(true);
    const [pageData, setPageData] = useState();
    const [storeData, setStoredata] = useState([]);
    const [loader, setLoader] = useState(true);
    useEffect(() => {
        if (didMountRef.current) {
            const getPageData = {
                slug: "our-stores",
            };
            apiServices.getpagesDataUrlpostrequest(getPageData).then((res) => {
                if (res.data.status == "success") {
                    setPageData(res?.data.data);
                }
            });
            apiServices
                .getourstoredata()
                .then((res) => {
                    if (res.data.status == "success") {
                        setStoredata(res?.data?.resStoreData);
                        setLoader(false)
                    }
                })
                .catch((error) => { });
        }
        didMountRef.current = false;
    });

    return (
        <>
            <Helmet>
                <title>{pageData?.page_meta_title != null ? pageData.page_meta_title : "Daadi's Kitchen"}</title>
                <meta name="description" itemprop="description" content={pageData?.page_meta_desc != null ? pageData?.page_meta_desc : "Daadi's Kitchen"} />
                {pageData?.page_meta_keyword != null ? <meta name="keywords" content={pageData?.page_meta_keyword} /> : "Daadi's Kitchen"}
                <link rel="canonical" href={window.location.href} />
                <meta property="og:title" content={pageData?.page_meta_title} />
                <meta name="twitter:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content={constants.FRONT_URL + 'img/logo.png'} />
                <meta property="og:url" content={window.location.href} />
                {pageData?.page_meta_desc != null ? <meta property="og:description" content={pageData?.page_meta_desc} /> : "Daadi's Kitchen"}
                <meta name="twitter:title" content={pageData?.page_meta_title} />
                {pageData?.page_meta_desc != null ? <meta name="twitter:description" content={pageData?.page_meta_desc} /> : "Daadi's Kitchen"}
                <meta property="twitter:image" content={constants.FRONT_URL + 'img/logo.png'} />
            </Helmet>
            <BrowserView>
                <Header />
                <div className="subheader subheader-small">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="page-title">
                                    <h1>{pageData?.page_name}</h1>
                                </div>
                                <Breadcrumb className="breadcrumb-inner">
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        {pageData?.page_name}
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <section className="secmedium">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row g-3">
                                    {storeData.length > 0 ? (
                                        <>
                                            {storeData.length > 0 &&
                                                storeData?.map((items, index) => {
                                                    return (
                                                        <div className="col-lg-4" key={index}>
                                                            <div className="product">
                                                                <figure className="product-media">
                                                                        <img
                                                                            src={
                                                                                items?.store_profile_image
                                                                                    ? items?.store_profile_image
                                                                                    : "/img/no_img1.jpg"
                                                                            }
                                                                            width={280}
                                                                            height={315}
                                                                        ></img>
                                                                </figure>
                                                                <div className="product-details"> 
                                                                    <h2 className="product-name">
                                                                        {items?.store_name}
                                                                    </h2> 
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </>
                                    ) : (
                                        <>
                                            {loader === false ? (
                                                <div className="noimg text-center">
                                                    <img
                                                        src="/img/noitemavailable.webp"
                                                        className="mb-5"
                                                        style={{ width: "250px" }}
                                                    ></img>
                                                    <h6 className="mb-0">No result found</h6>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    )} 
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </BrowserView>
            <MobileView>
                <header className="mheader">
                    <div className="mheader-left">
                        <i className="d-icon-arrow-left tx-22" onClick={() => { navigate("/account/about-us"); }}></i>
                        <p className="mb-0 mt-1 ms-3">{pageData?.page_name}</p>
                    </div>
                </header>

                <div className="mwapper">
                    <div className="container">
                        <div className="row">
                        </div>
                    </div>
                </div>
            </MobileView>
        </>
    );
};

export default OurStores;
