import React, { useEffect, useState, useRef } from "react";
import {
    BrowserView,
    MobileView,
} from "react-device-detect";
import SpinnerLoader from "../../component/Element/utils/spinnerLoader";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import CartHeader from "../../component/cart_header";
import { Link, useNavigate, useParams } from "react-router-dom";
const Thankyou = () => {
    const didMountRef = useRef(true);
    const navigate = useNavigate()
    const { orderid } = useParams()
    // console.log("mappedCartData", mappedCartData);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    useEffect(() => {
        if (didMountRef.current) {


        }
        didMountRef.current = false;
    }, []);

    return (
        <>
            {spinnerLoading ?
                <SpinnerLoader />
                : null}
            <Header />
            <section className="secsmall">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6">
                            <div className="cartPanel">
                                <div className="cartPanel-middle">
                                    <div className="carItembox">
                                        <h4>Thank you for your order, Your order ID: {orderid}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};
export default Thankyou;
