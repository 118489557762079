import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Route, Routes, Navigate, Switch } from 'react-router-dom';
import Home from './container/Home';
import MenuList from './container/menulist';
import MyOrder from './container/Account/myorders/index';
import Helpsupport from './container/helpsupport';
import Orderdetails from './container/order-details';
import Menucategorywise from './container/menu_categorywise';
// import Addresslist from './container/address';
import Menudetail from './container/menu-detail';
import Contactus from './container/contactus';
import Cart from './container/cart';
import CheckOut from './container/cart/checkout';
import Myoffers from './container/my-offers';
import Pages from './container/Pages';
import Search from './container/search';
import AccountOverview from './container/Account/account_overview';
import Address from './container/Account/address';
import Profile from './container/Account/profile';
import AboutUs from './container/Account/about_us';
import Changepassword from "./container/Account/changepassword"
import CartAddress from './container/cart/address';
import AddMoreItems from './container/addmoreitems';
import Thankyou from './container/cart/thankyou';
import UserWallet from './container/Account/wallet';
import VerificationEmail from './container/Pages/verificationemail';
import OurStores from './container/ourstores';




function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <Routes>
          <Route exact path='/' activeClassName="active" element={<Home />} />
          <Route exact path="/menu" activeClassName="active" element={<MenuList />} />
          <Route exact path='/account/orders' activeClassName="active" element={<MyOrder />} />
          <Route exact path='/help-support' activeClassName="active" element={<Helpsupport />} />
          <Route exact path='/order-details/:id' activeClassName="active" element={<Orderdetails />} />
          <Route exact path='/menu/:slug' activeClassName="active" element={<Menucategorywise />} />
          {/* <Route exact path='/address' activeClassName="active" element={<Addresslist />} /> */}
          <Route exact path='/product/:slug' activeClassName="active" element={<Menudetail />} />
          <Route exact path='/contact-us' activeClassName="active" element={<Contactus />} />
          <Route exact path='/our-stores' activeClassName="active" element={<OurStores />} />
          {/* CART */}
          <Route exact path='/cart' activeClassName="active" element={<Cart />} />
          <Route exact path='/cart-address' activeClassName="active" element={<CartAddress />} />
          <Route exact path='/checkout' activeClassName="active" element={<CheckOut />} />
          <Route exact path='/thankyou/:orderid' activeClassName="active" element={<Thankyou />} />
          {/* END CART */}
          <Route exact path='/add-more-items' activeClassName="active" element={<AddMoreItems />} />
          <Route exact path='/my-offers' activeClassName="active" element={<Myoffers />} />
          <Route exact path='/search' activeClassName="active" element={<Search />} />
          {/* ACCOUNT ROUTE */}
          <Route path="/account/account-overview" activeClassName="active" element={<AccountOverview />} />
          <Route path="/account/address" activeClassName="active" element={<Address />} />
          <Route path="/account/profile" activeClassName="active" element={<Profile />} />
          <Route path="/account/about-us" activeClassName="active" element={<AboutUs />} />
          <Route path="/account/change-password" activeClassName="active" element={<Changepassword />} />
          <Route path="/account/wallet" activeClassName="active" element={<UserWallet/>} />
          <Route path="/user-email-verification/:encryption_string" activeClassName="active" element={<VerificationEmail/>} />
          {/* ACCOUNT ROUTE END */}
          <Route exact path='/:slug' activeClassName="active" element={<Pages />} />
        </Routes>
      </Router>

    </div>
  );
}

export default App;
