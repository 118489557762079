import React, { useEffect, useRef, useState } from "react";
import {
  BrowserView,
  MobileView,
} from "react-device-detect";
import Header from "../../component/Header";
import Footer from "../../component/Footer";

import { Breadcrumb } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ApiService } from "../../component/services/apiServices";
import Accountsidebar from "./account_sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import localStorageData from "../../component/Element/utils/localStorageData";
const AccountOverview = () => {
  const navigate = useNavigate();
  const dataArray = localStorageData();
  const userSession = dataArray['UserSession'];
  const [show, setShow] = useState(false);
  const [UserData, setUserData] = useState({});
  const [errormsg, seterrormessage] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [username, setusername] = useState("");

  const didMountRef = useRef(true);
  const Userdata = JSON.parse(localStorage.getItem("USER_SESSION"));
  const location = useLocation();

  useEffect(() => {
    if (didMountRef.current) {
      getUserdatadetail();
    }
    didMountRef.current = false;
  }, []);
  const getUserdatadetail = () => {

      ApiService.commonGetRequest("profile-data").then((res)=>{
        if(res?.status=="success"){
          setUserData(res?.userData);
        }
      }).catch((error)=>{})
  };

  const logOut = () => {
    setShow(true);
  };

  const Confirm = () => {
    localStorage.removeItem("USER_SESSION");
    navigate("/");
  };

  const Cancelalert = () => {
    setShow(false);
  };

  return (
    <>
      <BrowserView>
        <Header />
          <div className="subheader subheader-small">
            <Container>
              <Row>
                <Col lg={12}>
                  <Breadcrumb className="breadcrumb-inner">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Account Overview</Breadcrumb.Item>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="">
            <section className="secmedium">
              <Container>
                <Row>
                  <Accountsidebar />
                  <Col lg={9}>
                    <div className="acpanel">
                      <div className="acpanel-header">
                        <h4>Account Overview</h4>
                      </div>
                      <div className="acpanel-body">
                        <div className="row mb-3">
                          <div className="col-lg-4">
                            <a href="/account/profile" className="aobox">
                              <img src="/img/user.png" className="mb-3" />
                              <h6>Profile</h6>
                              <p className="mb-0">
                                Manage your account information
                              </p>
                            </a>
                          </div>
                          <div className="col-lg-4">
                            <a href="/account/address" className="aobox">
                              <img src="/img/pin.png" className="mb-3" />
                              <h6>Address</h6>
                              <p className="mb-0">
                                Saved addresses for hassle-free checkout
                              </p>
                            </a>
                          </div>
                          <div className="col-lg-4">
                            <a href="/account/wallet" className="aobox">
                              <img src="/img/wallet.png" className="mb-3" />
                              <h6>My Wallet</h6>
                              <p className="mb-0">
                                Add Money to Your Wallet
                              </p>
                            </a>
                          </div>
                         
                        </div>
                        <div className="row">
                        <div className="col-lg-4">
                            <a href="/account/orders" className="aobox">
                              <img src="/img/order.png" className="mb-3" />
                              <h6>My Orders</h6>
                              <p className="mb-0">Check your order status</p>
                            </a>
                          </div>
                          <div className="col-lg-4">
                            <a
                              href="/account/change-password"
                              className="aobox"
                            >
                              <img
                                src="/img/changepassword.png"
                                className="mb-3"
                              />
                              <h6>Change Password</h6>
                              <p className="mb-0">Keep your account secure</p>
                            </a>
                          </div>
                          <div className="col-lg-4">
                            <a href="/help-support" className="aobox">
                              <img
                                src="/img/helpsupport.png"
                                className="mb-3"
                              />
                              <h6>Help & Support</h6>
                              <p className="mb-0">
                                Help regarding recent purchase & other
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        
        <Footer />
      </BrowserView>
      <MobileView>
      <div className="mwapper">
      <header className="mheader">
          <div className="mheader-left">
            <i
              className="d-icon-arrow-left tx-22"
              onClick={() => {
                navigate("/");
              }}
            ></i>
            <p className="mb-0 mt-1 ms-3">My Account</p>
          </div>
        </header>
       
          <div className="macc-profile">
            <div className="macc-img">
              <img src="/img/user.png" />
            </div>
            <div className="macc-content">
              <a href="/account/profile">
                <h6 className="mb-0">
                  Hi! {userSession?.user_name?.toUpperCase()}
                </h6>
                <p className="mb-0">{userSession?.user_email}</p>
              </a>
            </div>
            <i className="d-icon-angle-right"></i>
          </div>
          <div className="macc-list">
            <ul>
              <li onClick={() => navigate("/account/orders")}>
                <div className="macc-list-icon">
                  <img src="/img/order.png" />
                </div>
                <div>
                  <p className="mb-0">My Orders</p>
                  <span className="tx-color-02 tx-11">
                    Check your order status
                  </span>
                </div>
                <i className="d-icon-angle-right"></i>
              </li>
              <li onClick={() => navigate("/account/address")}>
                <div className="macc-list-icon">
                  <img src="/img/pin.png" />
                </div>
                <div>
                  <p className="mb-0"> Address Book</p>
                  <span className="tx-color-02 tx-11">
                    Saved addresses for hassle-free checkout
                  </span>
                </div>
                <i className="d-icon-angle-right"></i>
              </li>
              <li onClick={() => navigate("/account/wallet")}>
                <div className="macc-list-icon">
                  <img src="/img/wallet.png" />
                </div>
                <div>
                  <p className="mb-0">My Wallet</p>
                  <span className="tx-color-02 tx-11">
                   Add Money to your Wallet 
                  </span>
                </div>
                <i className="d-icon-angle-right"></i>
              </li>
            </ul>
          </div>
          <div className="macc-list mb-2">
            <ul>
              <li onClick={() => navigate("/help-support")}>
                <div className="macc-list-icon">
                  <img src="/img/helpsupport.png" />
                </div>
                <div>
                  <p className="mb-0">Help & Support</p>
                  <span className="tx-color-02 tx-11">
                    Help regarding recent purchase & other
                  </span>
                </div>
                <i className="d-icon-angle-right"></i>
              </li>
              <li onClick={() => navigate("/account/about-us")}>
                <div className="macc-list-icon">
                  <img src="/img/information.png" />
                </div>
                <div>
                  <p className="mb-0"> About Us</p>
                  <span className="tx-color-02 tx-11">
                    Policies, terms of use etc
                  </span>
                </div>
                <i className="d-icon-angle-right"></i>
              </li>
              <li onClick={() => navigate("/account/change-password")}>
                <div className="macc-list-icon">
                  <img src="/img/changepassword.png" />
                </div>
                Change Password
                <i className="d-icon-angle-right"></i>
              </li>
            </ul>
          </div>
          <div
            className="logout-button mb-3"
            onClick={() => {
              logOut();
            }}
          >
            <button className="btn btn-primary-outline  btn-medium">
              Logout From Daadi Kitchen
            </button>
          </div>
      </div>
      </MobileView>
      <SweetAlert
        warning
        confirmBtnCssClass="alertpop"
        title={` Are you sure you want to Logout ? `}
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
        show={show}
        onConfirm={Confirm}
        onCancel={Cancelalert}
        btnSize="md"
        showCancel
        cancelBtnBsStyle="danger"
      ></SweetAlert>
    </>
  );
};
export default AccountOverview;
