import Header from "../../component/Header";
import Footer from "../../component/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import MobileHeader from "../../component/Element/mobile_header";
import {
  BrowserView,
  MobileView,
} from "react-device-detect";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { ApiService } from "../../component/services/apiServices";
import QuickViewmodal from "../../component/quickview-modal/quickview_modal";
import Cartmodal from "../../component/cart-modal/cart_modal";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";
import constants from "../../component/services/constants";


const Menucategorywise = () => {

  const navigate = useNavigate();
  let apiServices = new ApiService();
  const handleShowcartmodal = () => setShowcartmodal(true);
  const handleClosecartmodal = () => setShowcartmodal(false);
  const [categorydata, setcatagorydata] = useState("");
  const [showcartmodal, setShowcartmodal] = useState(false);
  const [show, setShow] = useState(false);
  const [filteredfield, setfilteredfield] = useState("");
  const [featuredcategory, setfeaturedcategory] = useState([]);
  const [filtercateg, setfiltercateg] = useState("");
  const [productid, setproductid] = useState("");
  const [catmenudata, setcatmenudata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [baseimgUrl, setbaseimageUrl] = useState("");
  const didMountRef = useRef(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Userdata = JSON.parse(localStorage.getItem("USER_SESSION"));
  const { slug } = useParams();
  useEffect(() => {
    const datastrings = {
      cat_slug: filtercateg == "" ? slug : filtercateg,
      user_id: Userdata?.user_id,
      sort_filter: filteredfield,
    };
    setLoader(true);
    apiServices
      .getcategorywisemenuPostRequest(datastrings)
      .then((res) => {
        if (res.data.status == "success") {
          // setAdmindata(res?.data)
          // setswipertopdata(res?.data?.homeTopSliderData)
          setcatagorydata(res?.data?.categoryData);
          setcatmenudata(res?.data?.rowProductData);
          setbaseimageUrl(res?.data?.slider_img_path);
          setLoader(false);
        } else {
          setLoader(false);
        }

      })
      .catch((error) => {

      });
   
  }, [filteredfield, slug]);
  useEffect(()=>{
    if(didMountRef.current){
      const datastring = Userdata?.user_id;
      apiServices
        .getallfeaturedcategory(datastring)
        .then((res) => {
  
          if (res.data.status == "success") {
            setfeaturedcategory(res?.data?.featuredCategoryData);
          }
        })
        .catch((error) => {
  
  
        });


    }
    didMountRef.current = false;
  },[])
  const filteredcategory = (e) => {
    setfiltercateg(e.target.value);
    let selectvalue = e.target.value;
    if (filtercateg == e.target.value) {
      setfiltercateg("");
      setLoader(true);
      const datastrings = {
        cat_slug: slug,
        user_id: Userdata?.user_id,
        sort_filter: filteredfield,
      };
      apiServices
        .getcategorywisemenuPostRequest(datastrings)
        .then((res) => {

          if (res.data.status == "success") {
            setcatagorydata(res?.data?.categoryData);
            setcatmenudata(res?.data?.rowProductData);
            setbaseimageUrl(res?.data?.slider_img_path);

            setLoader(false);
          } else {
            setLoader(false);
          }
        })
        .catch((error) => { });
      return;
    } else {
      setLoader(true);
      const datastrings = {
        cat_slug: selectvalue,
        user_id: Userdata?.user_id,
        sort_filter: filteredfield,
      };
      apiServices
        .getcategorywisemenuPostRequest(datastrings)
        .then((res) => {

          if (res.data.status == "success") {
            setcatagorydata(res?.data?.categoryData);
            setcatmenudata(res?.data?.rowProductData);
            setbaseimageUrl(res?.data?.slider_img_path);
            setLoader(false);
          } else {
            setLoader(false);

          }
        })
        .catch((error) => { });
    }
  };

  const getdiscountprice = (discount, mrp, sp) => {
    const discountprice = Number(discount);
    const mrpprice = Number(mrp);
    const discountperc = (discountprice / mrpprice) * 100;
    return Math.round(discountperc);
  };

  return (
    <>
              <Helmet>
              <title>{categorydata?.cat_meta_title != null ? categorydata.cat_meta_title : "Daadi's Kitchen"}</title>
        <meta name="description" itemprop="description" content={categorydata?.cat_meta_desc != null ? categorydata?.cat_meta_desc :"Daadi's Kitchen"} />
        {categorydata?.cat_meta_keyword != null ?<meta name="keywords" content={categorydata?.cat_meta_keyword} />:"Daadi's Kitchen"}
        <link rel="canonical" href={window.location.href} />
        {categorydata?.cat_meta_title != null ? <meta property="og:title" content={categorydata?.cat_meta_title} />:"Daadi's Kitchen"}
        <meta name="twitter:url" content={window.location.href} />
             <meta property="og:image" content= {constants.FRONT_URL+'img/logo.png'}/>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
           {categorydata?.cat_meta_desc != null ? (
            <meta property="og:description" content={categorydata?.cat_meta_desc} />
            ) : (
            <meta property="og:description" content="Daadi's Kitchen" />
            )}
        <meta name="twitter:title" content={categorydata?.cat_meta_title} />
        {categorydata?.cat_meta_desc != null ?<meta name="twitter:description" content={categorydata?.cat_meta_desc} />:"Daadi's Kitchen"}
            <meta property="twitter:image" content= {constants.FRONT_URL+'img/logo.png'}/>
        
      </Helmet>
      <BrowserView>
        <Header></Header>
        <section className="secsmall">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="filterbox">
                  <div className="filterbox-title">Filter By Category</div>
                  <div className="filterbox-body">
                    <div className="sflist">
                      <ul>
                        {featuredcategory?.map((item, index) => {
                          const isChecked = filtercateg === item?.cat_slug;
                          return (
                            <li key={index}>
                              <label className="container-checkbox">
                                {item?.cat_name}
                                <input
                                  type="checkbox"
                                  value={item?.cat_slug}
                                  checked={isChecked}
                                  onChange={(e) => {
                                    filteredcategory(e);
                                  }}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {categorydata?.cat_name}
                  </Breadcrumb.Item>
                </Breadcrumb>
                <div className="page-title">
                  <h1>{categorydata?.cat_name}</h1>
                </div>
                <div className="shortby">
                  <p className="mb-0">
                    Showing results of {catmenudata?.length} products.
                  </p>
                  <select
                    onChange={(e) => {
                      setfilteredfield(e.target.value);
                    }}
                  >
                    <option value="">Sort by</option>
                    <option value="1">Sort by Popularity</option>
                    <option value="2">Sort by Latest</option>
                    <option value="3">Sort by Price-High to Low</option>
                    <option value="4">Sort by Price-Low to High</option>
                  </select>
                </div>
                <div className="row g-3">
                  {catmenudata.length > 0 ? (
                    <>
                      {catmenudata.length > 0 &&
                        catmenudata?.map((items, index) => {
                          return (
                            <div className="col-lg-4" key={index}>
                              <div className="product" onClick={() => { localStorage.setItem('slug', filtercateg == "" ? slug : filtercateg); }} >
                                <figure className="product-media">
                                  <Link
                                    to={`/product/${items?.product_slug}`}

                                  >
                                    <img
                                      src={
                                        items?.product_image
                                          ? items?.product_image
                                          : "/img/no_img1.jpg"
                                      }
                                      width={280}
                                      height={315}
                                    ></img>
                                  </Link>
                                  <div className="product-label-group">
                                    {items?.product_label_name?.split(', ')?.length > 0 ? (

                                      <>
                                        {items?.product_label_name?.split(', ')?.map((items) => {
                                          return (<>
                                            <label className="product-label label-new" key={items}>
                                              {items}
                                            </label>
                                          </>)
                                        })}

                                      </>
                                    ) : (
                                      ""
                                    )}

                                    {getdiscountprice(
                                      items?.product_discount,
                                      items?.product_price,
                                      items?.product_selling_price
                                    ) !== 0 ? (
                                      <>
                                        <label className="product-label label-sale">
                                          {getdiscountprice(
                                            items?.product_discount,
                                            items?.product_price,
                                            items?.product_selling_price
                                          )}
                                          %
                                        </label>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="product-action">
                                    <a
                                      href=""
                                      className="btn-product btn-quickview"
                                      title="Quick View"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleShow();
                                        setproductid(items?.product_slug);
                                      }}
                                    >
                                      Quick View
                                    </a>
                                  </div>
                                </figure>
                                <div className="product-details">
                                  <div className="product-cat">
                                    <Link>{categorydata?.cat_name}</Link>
                                  </div>
                                  <h2 className="product-name">
                                    <Link>{items?.product_name}</Link>
                                  </h2>
                                  <div className="product-price">
                                    <ins className="new-price">
                                      ${items?.product_selling_price}
                                    </ins>
                                    {items?.product_discount > 0 ? (
                                      <>
                                        {" "}
                                        <del className="old-price">
                                          ${items?.product_price}
                                        </del>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {/* <div className="ratings-container">
                                    <div className="ratings-full">
                                      <span
                                        className="ratings"
                                        style={{ width: "60%" }}
                                      ></span>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      {loader === false ? (
                        <div className="noimg text-center">
                          <img
                            src="/img/noitemavailable.webp"
                            className="mb-5"
                            style={{ width: "250px" }}
                          ></img>
                          <h6 className="mb-0">No result found</h6>
                        </div>

                      ) : (
                        ""
                      )}
                    </>
                  )}

                  {loader === true ? (
                    <>
                      <div className="col-lg-4">
                        <div className="product">
                          <Skeleton width={"100%"} height={300} />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="product">
                          <Skeleton width={"100%"} height={300} />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="product">
                          <Skeleton width={"100%"} height={300} />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="product">
                          <Skeleton width={"100%"} height={300} />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="product">
                          <Skeleton width={"100%"} height={300} />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="product">
                          <Skeleton width={"100%"} height={300} />
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer></Footer>
      </BrowserView>
      <MobileView>
        <MobileHeader
          page_name={categorydata?.cat_name}
          cart_count={3}
          navigateroute={"/"}
        />
        <div className="mwapper">
          {
            catmenudata?.length > 0 ? (
              <>
                {catmenudata.map((item, index) => (
                  <div className="hproduct" onClick={() => navigate(`/product/${item?.product_slug}`)} key={index}>
                    <div className="row align-items-center">
                      <div className="col-8">
                        <h2 className="hproduct-name">{item?.product_name}</h2>
                        <div className="hproduct-price">
                          <ins className="new-price">${item?.product_selling_price}</ins>
                          {item?.product_discount > 0 && (
                            <del className="old-price">${item?.product_price}</del>
                          )}
                        </div>
                        {/* <div className="ratings-container">
                          <div className="ratings-full">
                            <span className="ratings" style={{ width: "60%" }}></span>
                          </div>
                        </div> */}
                      </div>
                      <div className="col-4">
                        <figure className="hproduct-media">
                          <img src={item?.product_image ? item?.product_image : "/img/no_img1.jpg"} alt="" />
                          <div className="product-label-group">
                          {item?.product_label_name?.split(', ')?.length > 0 ? (

<>
  {item?.product_label_name?.split(', ')?.map((items) => {
    return (<>
      <label className="product-label label-new" key={items}>
        {items}
      </label>
    </>)
  })}

</>
) : (
""
)}
                            {getdiscountprice(item?.product_discount, item?.product_price, item?.product_selling_price) !== 0 && (
                              <label className="product-label label-sale">
                                {getdiscountprice(item?.product_discount, item?.product_price, item?.product_selling_price)}%
                              </label>
                            )}
                          </div>
                        </figure>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="col-lg-4">
                <div className="product">
                  <Skeleton width={"100%"} height={"129px"} count={7} />
                </div>
              </div>
            )
          }

        </div>
        <div className="container">
          <div className="row g-3"></div>
        </div>
     
      </MobileView>
      {show ? <QuickViewmodal
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        handleShow={handleShow}
        productid={productid}
      /> : null}
    </>
  );
};
export default Menucategorywise;
