import axios from 'axios';
import constant from './constants';
const logincheckUrl = "user-login"
const registerUrl = "user-registration-process"
const forgetpasswordUrl = "forgot-password"
const resetpasswordverifyotpUrl = "check-reset-password-otp"
const changepaswordurl = "change-password"
const dashboarddataURL = "dashboard"
const userorderlistURL = "order-list"
const userorderdetailurl = "order-details"
const UserdataPostUrl = "profile-data"
const categorywisemenuurl = "category-wise-menu"
const menulistposturl = "menu-list"
const getsearchdataurl = "get-search-data"
const getaddressdataurl = "get-address"
const categorydetailurl = "menu-tiffin-item"
const FooternewsletterUrl = "newsletter-process"
const favouritecategory = "featured-categories"
const updateprofilepostUrl = "profile-update"
const getOfferlistPostUrl = "offers-list"
const filtercatmenuUrl = ""
const removeaddressposturl = "remove-address"
const addtocartpostUrl = "add-to-cart"
const cartlistitemUrl = "cart-list"
const cartlistitemdecreaseUrl = "minus-to-cart"
const cartlistitemincreaseUrl = "plus-to-cart"
const cartitemremoveUrl = "remove-from-cart"
const addtofavpostUrl = "add-to-fav"
const selectcoupanpostUrl = "select-coupon"
const favlistuseridpostUrl = "favourite-list"
const checkouttotalpostUrl = "checkout-total"
const FooterapiUrl = ""
const FooterSettingsUrl = ""
const addaddressposturl = "user-address-process"
const footerlisturl = "footer-list"
const pagesDataurl = "pages-data"
const settinglisturl="setting-data"
const getallfeaturedcategory="all-featured-categories"
const getaddressdetailurl="user-address"
const getaddressupdateurl=""
const getourstoreurl="getourstoredata"

const client = axios.create({
  baseURL: constant.API_URL,
});

const clientGoogle = axios.create({
  baseURL: '',
});

// Request interceptor
client.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
   // window.location.href = '/error_404';
    return Promise.reject(error);
  }
);

// Response interceptor
client.interceptors.response.use(
  (response) => {
    if(response.data.status === 'session_expired'){
      localStorage.removeItem("USER_SESSION")
      window.location.href = '/';
    }
    return response;
  },
  (error) => {
  //  window.location.href = '/error_404';
    return Promise.reject(error);
  }
);

function getauthtoken() {
  let getuserdata = JSON.parse(localStorage.getItem("USER_SESSION"));

  let token = getuserdata?.user_token

  let Authtoken = '';
  if (token != null && token != '' && token != undefined) {

    Authtoken = token;
  }
  const config = {
    headers: { 'x-authorization': `Bearer ${Authtoken}` }
  };
  return config;
}

export class ApiService {

  static async googleGetRequest(url) {
    try {
      const response = await clientGoogle.get(url);
      return response.data;
    } catch (error) {
      // console.error('Error fetching data:', error);
      throw error;
    }
  }

  static async commonGetRequest(url) {
    try {
      const response = await client.get(url, getauthtoken());
      return response.data;
    } catch (error) {
      // console.error('Error fetching data:', error);
      throw error;
    }
  }

  static async commonPostRequest(url, postData) {
    try {
      const response = await client.post(url, postData, getauthtoken());
      return response.data;
    } catch (error) {
      // console.error('Error fetching data:', error);
      throw error;
    }
  }

  logincheckPostRequest(request) {
    return client.post(logincheckUrl, request, getauthtoken());
  }
  registerPostRequest(request) {
    return client.post(registerUrl, request, getauthtoken());
  }
  forgetpasswordPostRequest(request) {
    return client.post(forgetpasswordUrl, request, getauthtoken());
  }
  resetpasswordverifyotpPostRequest(request) {
    return client.post(resetpasswordverifyotpUrl, request, getauthtoken());
  }
  changepasswordPostRequest(request) {
    return client.post(changepaswordurl, request, getauthtoken());
  }
  getdashboarddataPostRequest(request) {
    return client.post(dashboarddataURL, request, getauthtoken());
  }
  getuserdataPostRequest(request) {
    return client.post(UserdataPostUrl, request, getauthtoken());
  }
  getOrderListPostRequest(request) {
    return client.post(userorderlistURL, request, getauthtoken());
  }
  getorderdetailsPostRequest(request) {
    return client.post(userorderdetailurl, request, getauthtoken());
  }
  getcategorywisemenuPostRequest(request) {
    return client.post(categorywisemenuurl, request, getauthtoken());
  }
  getmenulistPostRequest(request) {
    return client.post(menulistposturl, request, getauthtoken());
  }
  getsearchdataPostRequest(request) {
    return client.post(getsearchdataurl, request, getauthtoken());
  }
  getaddressdatapostRequest(request) {
    return client.post(getaddressdataurl, request, getauthtoken());
  }
  getcategorydetailpostRequest(request) {
    return client.post(categorydetailurl, request, getauthtoken());
  }
  getFooterpostRequest(request) {
    return client.post(FooterapiUrl, request, getauthtoken());
  }
  getSettingFooterpostRequest(request) {
    return client.post(FooterSettingsUrl, request, getauthtoken());
  }
  getFooternewsletterpostRequest(request) {
    return client.post(FooternewsletterUrl, request, getauthtoken());
  }
  getFeaturedcategorygetRequest() {
    return client.get(favouritecategory, getauthtoken());
  }
  getprofileUpdatePostRequest(request) {
    return client.post(updateprofilepostUrl, request, getauthtoken());
  }

  getremoveaddresspostrequest(request) {
    return client.post(removeaddressposturl, request, getauthtoken());
  }

  getaddaddresspostrequest(request) {
    return client.post(addaddressposturl, request, getauthtoken());
  }
  getaddressdetailpostrequest(request) {
    return client.post(getaddressdetailurl, request, getauthtoken());
  }
  getaddressupdatepostrequest(request) {
    return client.post(getaddressupdateurl, request, getauthtoken());
  }

  getOfferslistPosturlrequest() {
    return client.get(getOfferlistPostUrl, getauthtoken());
  }
  getaddtocartPosturlrequest(request) {
    return client.post(addtocartpostUrl, request, getauthtoken());
  }
  getcartlistitemsPosturlrequest(request) {
    return client.post(cartlistitemUrl, request, getauthtoken());

  }
  getcartitemsdecPosturlrequest(request) {
    return client.post(cartlistitemdecreaseUrl, request, getauthtoken());

  }
  getcartitemsIncPosturlrequest(request) {
    return client.post(cartlistitemincreaseUrl, request, getauthtoken());

  }
  getaddtofavPosturlrequest(request) {
    return client.post(addtofavpostUrl, request, getauthtoken());

  }
  getcartitemsRemovePosturlrequest(request) {
    return client.post(cartitemremoveUrl, request, getauthtoken());

  }
  getselectcoupanPosturlrequest(request) {
    return client.post(selectcoupanpostUrl, request, getauthtoken());

  }
  getfavlistbyuserpostrequest(request, id) {
    return client.post(favlistuseridpostUrl + `?user_id=${id}`, request, getauthtoken());
  }
  getcheckoutpostrequest(request) {
    return client.post(checkouttotalpostUrl, request, getauthtoken());
  }

  getfooterlisturlgetRequest() {
    return client.get(footerlisturl, getauthtoken());
  }
  getsettinglistgetRequest() {
    return client.get(settinglisturl, getauthtoken());
  }
  getallfeaturedcategory(){
    return client.get(getallfeaturedcategory, getauthtoken());
  }
  getourstoredata(){
    return client.get(getourstoreurl, getauthtoken());
  }
  
  getpagesDataUrlpostrequest(request) {
    return client.post(pagesDataurl, request, getauthtoken());
  }



}