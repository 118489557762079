import React from "react";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { ApiService } from "../../component/services/apiServices";

const Myoffers = () => {
  const [offerslist, setofferslist] = useState([])
  const didMountRef = useRef(true)
  let apiServices = new ApiService();
  const navigate = useNavigate();
  useEffect(() => {
    if (didMountRef.current) {
      apiServices.getOfferslistPosturlrequest().then((res) => {
      
        if (res?.data?.status == "success") {
          setofferslist(res?.data?.offerData)
        }
        else if(res?.data?.status == "session_expired"){
        
          localStorage.removeItem("USER_SESSION")
        }
      })
    }
    didMountRef.current = false;
  }, [])
  

  return (
    <>
      <BrowserView>
        <Header />
        <section className="m-2">
          <div className="container">
            <div className="row"> <h4>My offers</h4></div>
            <p></p>
            {offerslist?.map((value, index) => {
              return(<>
              
              <div key={index}>
                  <p>{value?.promo_name}</p>
                  <p>{value?.promo_description}</p>
                  <p>{value?.promo_coupon_code}</p>
                  <p>{value?.promo_minimum_purchase}</p>
                  <p>{value?.promo_valid_from}</p>
                  <p>{value?.promo_valid_to}</p>
                  <p>{value?.promo_usage_user_limit}</p>
                </div>
              </>)
                
              })}
          

          </div>
        </section>
        <Footer />
      </BrowserView>
      <MobileView>
      <header className="mheader">
            <div className="mheader-left"><i className="d-icon-arrow-left tx-22" onClick={() => { navigate("/") }}></i><p className="mb-0 mt-1 ms-3">My Offers</p></div>
            </header>
       
      </MobileView>
    </>
  );
};

export default Myoffers;
