import React, { useState, useEffect, useRef, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import DataContext from "../Element/context";
import SpinnerLoader from "../Element/utils/spinnerLoader";
import localStorageData from "../Element/utils/localStorageData";
import LoginModal from "../login-model";
const Cartmodal = ({ show, setShow, handleClose, handleShow }) => {
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const { setcartdata, cartdata } = useContext(DataContext);
    const dataArray = localStorageData();
    const parsedCartSession = dataArray['CartSession'];
    const cartSummary = dataArray['CartSummary'];
    const userSession = dataArray['UserSession'];
    const navigate=useNavigate()
    //console.log("cartSummary",cartSummary);
    const didMountRef = useRef(true);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const handleShowLoginModal = () => setShowLoginModal(true);
    const handleCloseLoginModal = () => setShowLoginModal(false);
    const removeProduct = (productData) => {
        setSpinnerLoading(true);
        let cartSession = localStorage.getItem("CART_SESSION");
        cartSession = cartSession ? JSON.parse(cartSession) : [];
        const existingProductIndex = cartSession.findIndex((item) => {
            return (
                item.product_id === productData.product_id &&
                JSON.stringify(item.product_variation) ===
                JSON.stringify(productData.product_variation)
            );
        });

        if (existingProductIndex !== -1) {
            cartSession.splice(existingProductIndex, 1);
            localStorage.setItem("CART_SESSION", JSON.stringify(cartSession));
        }
        setTimeout(() => {
            setSpinnerLoading(false);
        }, 500);
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} className="cart-modal right">
                {spinnerLoading ? <SpinnerLoader /> : null}
                <div className="cartmodalcontent">
                    <div className="cartmodalcontent-header">
                        <h6 className="mb-0">My Cart</h6>
                        <a
                            href="javscript:void(0)"
                            className="cartmodalcontent-close"
                            onClick={(e) => {
                                e.preventDefault();
                                handleClose();
                            }}
                        >
                            <i className="d-icon-times"></i>
                        </a>
                    </div>
                    <div className="cartmodalcontent-middle">
                        {parsedCartSession?.length > 0 ? (
                            <>
                                {parsedCartSession?.map((items, index) => {
                                    return (
                                        <div className="productCart" key={items.product_id}>
                                            <a
                                                href="javscript:void(0)"
                                                className="productCart-remove"
                                                onClick={(e) => {
                                                    removeProduct(items);
                                                }}
                                            >
                                                <i className="fa fa-trash-alt"></i>
                                            </a>
                                            <figure className="productCart-media">
                                                <img
                                                    src={
                                                        items?.product_image
                                                            ? items?.product_image
                                                            : "/img/no_img.jpg"
                                                    }
                                                />
                                            </figure>
                                            <div className="productCart-details">
                                                <h2 className="productCart-name">
                                                    <a href={`/product/${items?.product_slug}`}>
                                                        {items?.product_name}
                                                    </a>
                                                </h2>
                                                <h2 className="productCart-name">
                                                    <a href={`/product/${items?.product_slug}`}>
                                                        <span style={{color:'#666'}}>Time:</span> {items?.product_meal_time}
                                                    </a>
                                                </h2>
                                                <div className="productCart-price">
                                                    <span className="productCart-quantity">
                                                        {items?.quantity}
                                                    </span>
                                                    <ins className="new-price">
                                                        ${(parseFloat(items?.product_selling_price) + parseFloat(items?.product_addon_price)).toFixed(2)}
                                                    </ins>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}

                                <div className="cartmodalcontent-footer">
                                    <div className="cartmodalcontent-total">
                                        <span>Subtotal:</span>
                                        <span>${(cartSummary.total_amount + cartSummary.addon_amount).toFixed(2)}</span>
                                    </div>
                                    {userSession ?
                                        <div className="cartmodalcontent-action">
                                            <a href="/cart" className="cartbox-action-btn">
                                                View Cart
                                            </a>
                                            <a
                                                href="/cart"
                                                className="btn btn-primary btn-full btn-large"
                                            >
                                                Go to Checkout
                                            </a>
                                        </div>
                                        :
                                        <div className="cartmodalcontent-action">
                                            <a href="/cart" className="cartbox-action-btn">
                                                View Cart
                                            </a>
                                            <a
                                                href="javascript:void(0)"
                                                className="btn btn-primary btn-full btn-large"
                                                onClick={(e) => handleShowLoginModal()}
                                            >
                                                Go to Checkout
                                            </a>
                                        </div>
                                    }

                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                    {parsedCartSession?.length == 0 ? (
                        <div className="noimg text-center">
                            <img src="/img/empty-cart.webp" className="img-fluid mb-3"></img>
                            <h6>Your cart is empty!</h6>
                            <p>There is nothing in your cart. Let's add some items</p>
                            <a
                               
                                className="btn btn-primary-outline btn-block btn-small"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/")
                                    handleClose();
                                }}
                            >
                                Continue Shopping
                            </a>
                        </div>
                    ) : null}
                </div>
            </Modal>
            {showLoginModal ? (
                <LoginModal
                    show={showLoginModal}
                    setShow={setShowLoginModal}
                    handleClose={handleCloseLoginModal}
                    handleShow={handleShowLoginModal}
                />
            ) : null}
        </>
    );
};

export default Cartmodal;
