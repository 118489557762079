import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ApiService } from "../../../services/apiServices";
import SpinnerLoader from "../../utils/spinnerLoader";
const TiffinViewDetailsModal = ({ show, setShow, handleClose, handleShow, variationData = [] }) => {
const [isLoading, setIsLoading] = useState(false);
const didMountRef = useRef(true);
const [spinnerLoading, setSpinnerLoading] = useState(false);
const [TiffenItemsData, setTiffenItemsData] = useState();
const popclose = () => {
handleClose();
};
useEffect(() => {
if (didMountRef.current) {
setSpinnerLoading(true)
const variationNewData = variationData.reduce((acc, curr) => {
if (!acc.counts[curr.ptiId]) {
acc.counts[curr.ptiId] = [curr.itemId];
} else {
acc.counts[curr.ptiId].push(curr.itemId);
}
return acc;
}, { counts: {} });


const dataString = {
variation_data: variationNewData.counts
}
ApiService.commonPostRequest('cart-variation-data', dataString).then(res => {
if (res.status == 'success') {
setTiffenItemsData(res.itemsarr)
setTimeout(() => {
setSpinnerLoading(false)
}, 500);
}
}).catch((error) => {
setTimeout(() => {
setSpinnerLoading(false)
}, 500);
})
}
didMountRef.current = false;
}, []);
return (
<>
<Modal show={show} onHide={handleClose} className="lrmodal">
<button
className="pop-close"
onClick={() => {
popclose();
}}
>
<i className="d-icon-times"></i>
</button>
<Modal.Body>
<div className="tiffindetails">
{spinnerLoading ? <SpinnerLoader /> : null}


<ul>
  {TiffenItemsData &&
    Object.entries(TiffenItemsData).length > 0 &&
    Object.entries(TiffenItemsData).map(([variation_heading, items], index) => (
       
      <li key={index}>
        <h6 className="tx-14">{variation_heading}</h6>
        <ul style={{ marginBottom: '20px' }}>
          {items.map((item, i) => (
            item?.map((dishitem,index)=>{
                return(<>
                 <li key={index}>{dishitem}</li> 
                
                </>)
            })
           
            
          ))}
        </ul>
      </li>
    ))}
</ul>
</div>
</Modal.Body>
</Modal>
</>
);
};

export default TiffinViewDetailsModal;
