import Header from "../../component/Header";
import Footer from "../../component/Footer";
import { Navigation, Pagination } from "swiper/modules";
import {
  BrowserView,
  MobileView,
} from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../component/services/apiServices";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Link, useNavigate } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";
import constants from "../../component/services/constants";

const Home = () => {
  let apiServices = new ApiService();
  const [adminData, setAdmindata] = useState("");
  const [swipertpdata, setswipertopdata] = useState([]);
  const [swipertpmobdata, setswipertopmobdata] = useState([]);
  const [featuredcategory, setfeaturedcategory] = useState([]);
  const [baseimgUrl, setbaseimageUrl] = useState("");
  const [baseimgUrlcat, setbaseimageUrlcat] = useState("");
  const didMountRef = useRef(true);
  const Userdata = JSON.parse(localStorage.getItem("USER_SESSION"));
  const navigate = useNavigate();
  const [pageData, setPageData] = useState();
  const [pageContent, setPageContent] = useState("");
  useEffect(() => {
    if (didMountRef.current) {
      const getpagesData = {
        slug: "home",
      };

      apiServices.getpagesDataUrlpostrequest(getpagesData).then(res => {
        if (res.data.status == 'success') {
          setPageData(res?.data?.data)
          setPageContent(res.data?.data?.page_content);
          console.log("res.data?.data?.page_content", res.data?.data?.page_content);
        }
      })
      ApiService.commonGetRequest("dashboard").then((res) => {
        if (res?.status == "success") {
          setAdmindata(res);
          setswipertopdata(res?.homeTopSliderData);
          setswipertopmobdata(res?.homeTopWebSliderMobData)
          setbaseimageUrl(res?.slider_img_path);
          setbaseimageUrlcat(res?.category_img_path);
          setfeaturedcategory(res?.featuredCategoryData);
        }
      }).catch((error) => {


      });
    }
    didMountRef.current = false;
  }, []);




  const bannerredirect = (index) => {
    if (swipertpdata[index]?.slider_type == 2) {

      navigate(`/menu/${swipertpdata[index]?.category?.cat_slug}`)
    }
    else if (swipertpdata[index]?.slider_type == 1) {
      navigate(swipertpdata[index]?.slider_url)
    }
  }
  const bannermobredirect = (index) => {
    if (swipertpmobdata[index]?.slider_type == 2) {

      navigate(`/menu/${swipertpmobdata[index]?.category?.cat_slug}`)
    }
    else if (swipertpmobdata[index]?.slider_type == 1) {
      navigate(swipertpmobdata[index]?.slider_url)
    }
  }
  return (
    <>
          <Helmet>
          <title>{pageData?.page_meta_title != null ? pageData.page_meta_title : "Daadi's Kitchen: Best Indian Food & Pure Veg Restaurant"}</title>
        <meta name="description" itemprop="description" content={pageData?.page_meta_desc != null ? pageData?.page_meta_desc :"Daadi's Kitchen"} />
        {pageData?.page_meta_keyword != null ?<meta name="keywords" content={pageData?.page_meta_keyword} />:"Daadi's Kitchen"}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={pageData?.page_meta_title} />
        <meta name="twitter:url" content={window.location.href} />
        <meta property="og:type" content="website" />
             <meta property="og:image" content= {constants.FRONT_URL+'img/logo.png'}/>
        <meta property="og:url" content={window.location.href} />
        {pageData?.page_meta_desc != null ? (
            <meta property="og:description" content={pageData?.page_meta_desc } />
            ) : (
            <meta property="og:description" content="Daadi's Kitchen" />
            )}
        <meta name="twitter:title" content={pageData?.page_meta_title} />
        {pageData?.page_meta_desc != null ?<meta name="twitter:description" content={pageData?.page_meta_desc} />:"Daadi's Kitchen"}
            <meta property="twitter:image" content= {constants.FRONT_URL+'img/logo.png'}/>
        
      </Helmet>
      <Header />
      <BrowserView>
        <section className="secsmall">
          <div className="container">
            <div className="row">
              <div className="col-lg-12" >
                {
                  swipertpdata.length > 0 ? (
                    <Swiper
                      spaceBetween={0}
                      centeredSlides={true}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      pagination={{
                        clickable: true,
                      }}
                      navigation={false}
                      modules={[Autoplay, Pagination, Navigation]}
                      className="mySwiper"
                    >
                      {swipertpdata.map((item, index) => (
                        <SwiperSlide key={index}>
                          <div className="sliderimg">
                            <img
                              src={baseimgUrl + item?.slider_image}
                              alt={`Slider Image ${index}`}
                              onClick={() => bannerredirect(index)}
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : (
                    <div className="sliderimg">
                      <Skeleton width={"100%"} height={400} />
                    </div>
                  )
                }

              </div>
            </div>
          </div>
        </section>
        <section className="secsmall">
          <div className="container">
            <div className="row g-3">
              {
                featuredcategory.length > 0 ? (
                  <>
                    {featuredcategory.map((item, index) => (
                      <div className="col-lg-2" key={index}>
                        <Link to={`/menu/${item?.cat_slug}`} className="catbox">
                          <div className="catbox-thumbnail">
                            {item?.cat_image ? (
                              <img src={baseimgUrlcat + item?.cat_image} alt="" />
                            ) : (
                              <img src="/img/no_img1.jpg" alt="" />
                            )}
                          </div>
                          <div className="catbox-name">{item?.cat_meta_title}</div>
                        </Link>
                      </div>
                    ))}
                  </>
                ) : (
                  Array.from({ length: 12 }, (_, index) => (
                    <div className="col-lg-2" key={index}>
                      <div className="catbox-thumbnail">
                        <Skeleton width={200} height={200} />
                      </div>
                      <div className="catbox-name">
                        <Skeleton width={200} height={20} />
                      </div>
                    </div>
                  ))
                )
              }

            </div>
          </div>
        </section>
        <section className="secsmall">
          <div className="container">
            <div className="col-lg-12">
              <div className="appsection">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="appsection-content">
                      <h2>Download the Daadikitchen App</h2>
                      <h6>Order your dish's & track on the go, with the all-new Daadikitchen app.</h6>
                      <div className="appsection-button">
                        <a href={adminData?.adminData?.play_store_url} target="blank"><img src="/img/androidicon.png"></img></a>
                        <a href={adminData?.adminData?.app_store_url} target="blank"><img src="/img/appleicon.png"></img></a>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      </BrowserView>
      <MobileView>

        <section className="secsmall">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {swipertpdata.length > 0 ? (
                  <Swiper
                    spaceBetween={0}
                    centeredSlides={true}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={false}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                  >
                    {swipertpmobdata?.map((items, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="sliderimg">
                            <img
                              src={baseimgUrl + items?.slider_image}
                              onClick={() => { bannermobredirect(index) }}
                            ></img>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                ) : (
                  <>
                    <div className="sliderimg">
                      <Skeleton width={"100%"} height={100} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="secsmall">
          <div className="container">
            <div className="row g-3">
              {
                featuredcategory.length > 0 ? (
                  <>
                    {featuredcategory.map((item, index) => (
                      <div className="col-lg-2 col-4" key={index}>
                        <Link to={`/menu/${item?.cat_slug}`} className="catbox">
                          <div className="catbox-thumbnail">
                            <img src={(item?.cat_image ? baseimgUrlcat + item?.cat_image : "/img/no_img1.jpg")} alt={item?.cat_meta_title}/>
                          </div>
                          <div className="catbox-name">{item?.cat_meta_title}</div>
                        </Link>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {[...Array(12)].map((_, index) => (
                      <div className="col-lg-2 col-4" key={index}>
                        <div className="catbox-thumbnail">
                          <Skeleton width={100} height={100} />
                        </div>
                        <div className="catbox-name">
                          <Skeleton width={100} height={10} />
                        </div>
                      </div>
                    ))}
                  </>
                )
              }

            </div>
          </div>
        </section>

      </MobileView>
      <Footer />
    </>
  );
};

export default Home;
