import React, { useEffect, useRef, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../component/Element/utils/toastUtils";
import {
  Breadcrumb,
  Col,
  Alert,
  Button,
  Container,
  Row,
} from "react-bootstrap";
import { ApiService } from "../../component/services/apiServices";
import { Helmet } from "react-helmet";
import constants from "../../component/services/constants";
let apiServices = new ApiService();

const Contactus = () => {
  const navigate = useNavigate();
  const didMountRef = useRef(true);
  const [pageData, setPageData] = useState();
  const [contactInputs, setcontactInputs] = useState({
    contact_name: "",
    contact_email: "",
    contact_mobile: "",
    contact_subject: "",
    contact_message: "",
  });
  const [alertmsg, setalertmsg] = useState("");
  const [alertsuccessmsg, setalertsuccessmsg] = useState("");
  const [adminData, setAdmindata] = useState("");
  console.log(pageData, "pageDatapageData");
  useEffect(() => {
    if (didMountRef.current) {
      const getPageData = {
        slug: "contact-us",
      };

      apiServices.getpagesDataUrlpostrequest(getPageData).then((res) => {
        if (res.data.status == "success") {
          setPageData(res?.data.data);
        }
      });

      apiServices
        .getsettinglistgetRequest()
        .then((res) => {
          if (res.data.status == "success") {
            setAdmindata(res?.data?.data);
            //setSettingImageURL(res?.data?.setting_img_path);
          }
        })
        .catch((error) => { });
    }
    didMountRef.current = false;
  });
  const handleContactInput = (e) => {
    const { name, value } = e.target;

    setcontactInputs({
      ...contactInputs,
      [name]: value,
    });
  };
  const ContactformHandle = () => {
    if (contactInputs?.contact_name == "") {
      showToast('error', 'Please Enter Your Name');
      return;
    }
    if (contactInputs?.contact_email == "") {
      showToast('error', 'Please Enter Your Email Address');
      return;
    }
    const validEmail = new RegExp(
      "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
    );
    if (!validEmail.test(contactInputs?.contact_email)) {
      showToast('error', 'Please Enter Valid Email Address');
      return;
    }
    if (contactInputs?.contact_mobile == "") {
      showToast('error', 'Please Enter Your Mobile Number');
      return;
    }

    if (contactInputs?.contact_message == "") {
      showToast('error', 'Please Enter Your Message');
      return;
    }
    ApiService.commonPostRequest("contact-us-process", contactInputs).then(
      (res) => {
        if (res?.status == "success") {
          showToast('success', res?.message);
          setcontactInputs({ contact_name: "", contact_email: "", contact_mobile: "", contact_subject: "", contact_message: "", })
        } else if (res?.status == "error") {
          showToast('error', res?.message);
        }
      }
    );
  };

  function redirectToRestaurant(e, address) {
    e.preventDefault();
    var restuaddress = address;
    var googleMapsUrl = "https://www.google.com/maps?q=" + restuaddress;
    window.open(googleMapsUrl, '_blank');
  }

  const redirectToEmail = (e) => {
    e.preventDefault();
    let email = adminData?.admin_support_email;
    if (email !== "") {
      window.location.href = `mailto:${email}`;
    }
  };
  const phoneNumber = adminData?.admin_support_mobile
    ? adminData?.admin_support_mobile
    : "";

  const redirectToPhoneCall = (e, number) => {
    e.preventDefault();
    if (number.trim() !== "") {
      window.location.href = `tel:${number.trim()}`;
    }
  };

  return (
    <>
      <Helmet>
        <title>{pageData?.page_meta_title != null ? pageData.page_meta_title : "Daadi's Kitchen"}</title>
        <meta name="description" itemprop="description" content={pageData?.page_meta_desc != null ? pageData?.page_meta_desc : "Daadi's Kitchen"} />
        {pageData?.page_meta_keyword != null ? <meta name="keywords" content={pageData?.page_meta_keyword} /> : "Daadi's Kitchen"}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={pageData?.page_meta_title} />
        <meta name="twitter:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={constants.FRONT_URL + 'img/logo.png'} />
        <meta property="og:url" content={window.location.href} />
        {pageData?.page_meta_desc != null ? <meta property="og:description" content={pageData?.page_meta_desc} /> : "Daadi's Kitchen"}
        <meta name="twitter:title" content={pageData?.page_meta_title} />
        {pageData?.page_meta_desc != null ? <meta name="twitter:description" content={pageData?.page_meta_desc} /> : "Daadi's Kitchen"}
        <meta property="twitter:image" content={constants.FRONT_URL + 'img/logo.png'} />

      </Helmet>
      <BrowserView>
        <Header />
        <div className="subheader subheader-small">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="page-title">
                  <h1>{pageData?.page_name}</h1>
                </div>
                <Breadcrumb className="breadcrumb-inner">
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {pageData?.page_name}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>

        <section className="secmedium">
          <div className="container">
            <div className="row">
              <Col lg={7}>
                <div className="section-title">
                  <h2>Get In Touch With Us</h2>
                  <p>
                    If you have any questions or enquiries please feel free to
                    contact us alternatively you can complete our online enquiry
                    form located below and we will get back to you as soon as
                    possible.
                  </p>
                </div>
                <div className="row g-3">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Your Name</label>
                      <input
                        type="text"
                        name="contact_name"
                        className="form-control required"
                        value={contactInputs.contact_name}
                        onChange={(e) => {
                          handleContactInput(e);
                        }}
                        placeholder="Name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Email Address</label>
                      <input
                        type="email"
                        name="contact_email"
                        className="form-control required"
                        value={contactInputs.contact_email}
                        onChange={(e) => {
                          handleContactInput(e);
                        }}
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Mobile Number</label>
                      <input
                        type="number"
                        name="contact_mobile"
                        className="form-control required"
                        value={contactInputs.contact_mobile}
                        onChange={(e) => {
                          handleContactInput(e);
                        }}
                        placeholder="Mobile Number"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Subject</label>
                      <input
                        type="text"
                        name="contact_subject"
                        className="form-control required"
                        value={contactInputs.contact_subject}
                        onChange={(e) => {
                          handleContactInput(e);
                        }}
                        placeholder="Subject"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Message</label>
                      <textarea
                        name="contact_message"
                        className="form-control required"
                        value={contactInputs.contact_message}
                        onChange={(e) => {
                          handleContactInput(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Button
                      className="btn btn-primary btn-medium"
                      onClick={ContactformHandle}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </Col>
              <Col lg={5}>
                <div className="contact-address-section mb-3">
                  <div className="section-title">
                    <h5>Our Corporate Office</h5>
                  </div>
                  <div className="address-contact">
                    <ul>
                      {adminData?.address && (
                        <li>
                          <a target="new" onClick={(e) => { redirectToRestaurant(e, adminData?.address); }} style={{ cursor: "pointer" }} >
                            <i className="d-icon-map mr-5"></i>
                            <span>{adminData?.address}</span>
                          </a>
                        </li>
                      )}
                      {adminData?.address_sec && (
                        <li>
                          <a target="new" onClick={(e) => { redirectToRestaurant(e, adminData?.address_sec); }} style={{ cursor: "pointer" }} >
                            <i className="d-icon-map mr-5"></i>
                            <span>{adminData?.address_sec}</span>
                          </a>
                        </li>
                      )}
                      {(adminData?.admin_support_mobile || adminData?.admin_support_mobile_sec || adminData?.admin_support_mobile_third) && (
                        <li>
                          <a href="javascript:void(0)" style={{ cursor: "pointer" }}>
                            <i className="d-icon-phone mr-5"></i>
                            {adminData?.admin_support_mobile && (
                              <span onClick={(e) => { redirectToPhoneCall(e, adminData?.admin_support_mobile); }}>
                                {adminData?.admin_support_mobile}
                              </span>
                            )}
                            {adminData?.admin_support_mobile_sec && (
                              <span onClick={(e) => { redirectToPhoneCall(e, adminData?.admin_support_mobile_sec); }}>
                                {adminData?.admin_support_mobile_sec}
                              </span>
                            )}
                            {adminData?.admin_support_mobile_third && (
                              <span onClick={(e) => { redirectToPhoneCall(e, adminData?.admin_support_mobile_third); }}>
                                {adminData?.admin_support_mobile_third}
                              </span>
                            )}
                          </a>
                        </li>
                      )}

                      {adminData?.admin_support_email && (
                        <li>
                          <a onClick={(e) => { redirectToEmail(e); }} style={{ cursor: "pointer" }} >
                            <i className="fa fa-envelope  mr-5"></i>
                            <span>{adminData?.admin_support_email}</span>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </Col>
            </div>
          </div>
        </section>
        <Footer />
      </BrowserView>
      <MobileView>
        <header className="mheader">
          <div className="mheader-left">
            <i
              className="d-icon-arrow-left tx-22"
              onClick={() => {
                navigate("/account/about-us");
              }}
            ></i>
            <p className="mb-0 mt-1 ms-3">{pageData?.page_name}</p>
          </div>
        </header>

        <div className="mwapper">
          <div className="container">
            <div className="row">
              <Col lg={7}>
                <div className="section-title">
                  <h2>Get In Touch With Us</h2>
                  <p>
                    If you have any questions or enquiries please feel free to
                    contact us alternatively you can complete our online enquiry
                    form located below and we will get back to you as soon as
                    possible.
                  </p>
                </div>
                <div className="row g-3">
                  <div className="col-lg-6">
                    <div className="form-group-dark">
                      <label>Your Name</label>
                      <input
                        type="text"
                        name="contact_name"
                        className="form-control required"
                        value={contactInputs.contact_name}
                        onChange={(e) => {
                          handleContactInput(e);
                        }}
                        placeholder="Name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group-dark">
                      <label>Email Address</label>
                      <input
                        type="email"
                        name="contact_email"
                        className="form-control required"
                        value={contactInputs.contact_email}
                        onChange={(e) => {
                          handleContactInput(e);
                        }}
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group-dark">
                      <label>Mobile Number</label>
                      <input
                        type="number"
                        name="contact_mobile"
                        className="form-control required"
                        value={contactInputs.contact_mobile}
                        onChange={(e) => {
                          handleContactInput(e);
                        }}
                        placeholder="Mobile Number"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group-dark">
                      <label>Subject</label>
                      <input
                        type="text"
                        name="contact_subject"
                        className="form-control required"
                        value={contactInputs.contact_subject}
                        onChange={(e) => {
                          handleContactInput(e);
                        }}
                        placeholder="Subject"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group-dark">
                      <label>Message</label>
                      <textarea
                        name="contact_message"
                        className="form-control required"
                        value={contactInputs.contact_message}
                        onChange={(e) => {
                          handleContactInput(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Button
                      className="btn btn-primary btn-medium"
                      onClick={ContactformHandle}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </Col>
              <Col lg={5}>
                <div className="contact-address-section mb-3">
                  <div className="section-title">
                    <h3>Our Corporate Office</h3>
                  </div>
                  <div className="address-contact">
                    <ul>
                      {adminData?.address && (
                        <li>
                          <a target="new" onClick={(e) => { redirectToRestaurant(e, adminData?.address); }} style={{ cursor: "pointer" }} >
                            <i className="d-icon-map mr-5"></i>
                            <span>{adminData?.address}</span>
                          </a>
                        </li>
                      )}
                      {adminData?.address_sec && (
                        <li>
                          <a target="new" onClick={(e) => { redirectToRestaurant(e, adminData?.address_sec); }} style={{ cursor: "pointer" }} >
                            <i className="d-icon-map mr-5"></i>
                            <span>{adminData?.address_sec}</span>
                          </a>
                        </li>
                      )}
                      {(adminData?.admin_support_mobile || adminData?.admin_support_mobile_sec || adminData?.admin_support_mobile_third) && (
                        <li>
                          <a href="javascript:void(0)" style={{ cursor: "pointer" }}>
                            <i className="d-icon-phone mr-5"></i>
                            {adminData?.admin_support_mobile && (
                              <span onClick={(e) => { redirectToPhoneCall(e, adminData?.admin_support_mobile); }}>
                                {adminData?.admin_support_mobile}
                              </span>
                            )}
                            {adminData?.admin_support_mobile_sec && (
                              <span onClick={(e) => { redirectToPhoneCall(e, adminData?.admin_support_mobile_sec); }}>
                                {adminData?.admin_support_mobile_sec}
                              </span>
                            )}
                            {adminData?.admin_support_mobile_third && (
                              <span onClick={(e) => { redirectToPhoneCall(e, adminData?.admin_support_mobile_third); }}>
                                {adminData?.admin_support_mobile_third}
                              </span>
                            )}
                          </a>
                        </li>
                      )}

                      {adminData?.admin_support_email && (
                        <li>
                          <a onClick={(e) => { redirectToEmail(e); }} style={{ cursor: "pointer" }} >
                            <i className="fa fa-envelope  mr-5"></i>
                            <span>{adminData?.admin_support_email}</span>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </Col>
            </div>
          </div>
        </div>
      </MobileView>
    </>
  );
};

export default Contactus;
