import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { BrowserView, MobileView } from "react-device-detect";
import AccountSidebar from "./account_sidebar";
import { useNavigate } from "react-router-dom";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import { ApiService } from "../../component/services/apiServices";
import Alert from "react-bootstrap/Alert";

function Changepassword() {
  const navigate = useNavigate();
  const [Errorobject, setErrorobject] = useState({});
  const [show, setShow] = useState(false);
  let apiServices = new ApiService();
  const [UserData, setUserData] = useState({});
  const [errormsg, seterrormessage] = useState("");
  const [errorconfmsg, seterrorconfmessage] = useState("");
  const [errormatchmsg, seterrormatchmsg] = useState("");
  const [successmsg, setsuccessmsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [newpassword, setNewpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [matchpassword, setmatchpassword] = useState("");
  const [message, setmessage] = useState({});
  const [passwordtype, setpasswordtype] = useState("password");
  const [confpasswordtype, setconfpasswordtype] = useState("password");
  const didMountRef = useRef(true);
  const Userdata = JSON.parse(localStorage.getItem("USER_SESSION"));

  const changepassword = (e) => {
    e.preventDefault();

    if (newpassword == "" || newpassword == null) {
      setErrorobject({
        ...Errorobject,
        newpassword: "Please enter your New Password",
      });

      seterrormessage("Please enter your New Pasword");
      setTimeout(seterrormessage, 2000);
      setShow(false);
      return;
    }
    if (confirmpassword == "" || confirmpassword == null) {
      setErrorobject({
        ...Errorobject,
        confirmpassword: "Please enter your Confirm Password",
      });
      seterrorconfmessage("Please enter your Confirm Password");
      setTimeout(seterrorconfmessage, 2000);
      setShow(false);
      return;
    }
    if (newpassword !== confirmpassword) {
      setErrorobject({
        ...Errorobject,
        matchpassword: "Your Confirm Password is not matched with New Password",
      });
      seterrormatchmsg(
        "Your Confirm Password is not matched with New Password"
      );
      setTimeout(seterrormatchmsg, 3000);
      setShow(false);
      return;
    }

    setIsLoading(true);
    setShow(true);
    setTimeout(setShow, 2000);
    const dataString = {
      user_email: Userdata?.user_email,
      password: newpassword,
    };

    apiServices
      .changepasswordPostRequest(dataString)
      .then((res) => {
        if (res.data.status == "success") {
          seterrormatchmsg(false);
          setIsLoading(false);
          setShow(true);
          setTimeout(setShow, 3000);
          setsuccessmsg("Your Password has been changed!");
        } else {
          setIsLoading(false);
          setShow(false);
          setmessage({
            ...message,
            change_password: res.data.message,
          });

          // toast(res.data.message);
          return false;
        }
      })
      .catch((error) => { });
  };

  return (
    <>
      <BrowserView>
        <Header />

        <div className="subheader">
          <Container>
            <Row>
              <Col lg={12}>
                <Breadcrumb className="breadcrumb-inner">
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Change Password</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="">
          <section className="secmedium">
            <Container>
              <Row>
                <AccountSidebar />
                <Col lg={9}>
                  {(show == true && (
                    <>
                      <Alert variant="success">{successmsg}</Alert>
                    </>
                  )) ||
                    ""}

                  {errormatchmsg ? (
                    <>
                      <Alert variant="danger">{errormatchmsg}</Alert>
                    </>
                  ) : (
                    ""
                  )}

                  {errormsg ? (
                    <>
                      <Alert variant="danger">{errormsg}</Alert>
                    </>
                  ) : (
                    ""
                  )}

                  {errorconfmsg ? (
                    <>
                      <Alert variant="danger">{errorconfmsg}</Alert>
                    </>
                  ) : (
                    ""
                  )}

                  <div className="acpanel">
                    <div className="acpanel-header">
                      <h4>Change Password</h4>
                    </div>
                    <div className="acpanel-body">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group mb-3">
                            <label htmlFor="user_fname">New Password</label>
                            <input
                              type="password"
                              name="new_password"
                              className={`form-control ${Errorobject?.newpassword !== ""
                                  ? "has-error"
                                  : ""
                                } required`}
                              placeholder="New Password"
                              onChange={(e) => {
                                setNewpassword(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group mb-3">
                            <label htmlFor="user_fname">Confirm Password</label>
                            <input
                              type="password"
                              name="confirm_password"
                              className={`form-control required ${Errorobject?.confirmpassword !== "" ||
                                  Errorobject?.matchpassword !== ""
                                  ? "has-error"
                                  : ""
                                }`}
                              placeholder="Confirm Password"
                              onChange={(e) => {
                                setconfirmpassword(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <button
                            className="btn btn-primary btn-large"
                            type="button"
                            name="submit"
                            onClick={(e) => {
                              changepassword(e);
                              setShow(false);
                            }}
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <img
                                src="/img/loder01.gif"
                                width="60px"
                                height="11px"
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>

        <Footer />
      </BrowserView>

      <MobileView>
        <div className="mwapper">
          <header className="mheader">
            <div className="mheader-left">
              <i
                className="d-icon-arrow-left tx-22"
                onClick={() => {
                  navigate("/account/account-overview");
                }}
              ></i>
              <p className="mb-0 mt-1 ms-3">Change Password</p>
            </div>
          
          </header>


          <div className="p-3">
            <div className="row">
              <div className="col-lg-12">
                {(show == true && (
                  <>
                    <Alert variant="success">{successmsg}</Alert>
                  </>
                )) ||
                  ""}

                {errormatchmsg ? (
                  <>
                    <Alert variant="danger">{errormatchmsg}</Alert>
                  </>
                ) : (
                  ""
                )}

                {errormsg ? (
                  <>
                    <Alert variant="danger">{errormsg}</Alert>
                  </>
                ) : (
                  ""
                )}

                {errorconfmsg ? (
                  <>
                    <Alert variant="danger">{errorconfmsg}</Alert>
                  </>
                ) : (
                  ""
                )}
                <div className="form-group-dark mb-3">
                  <label htmlFor="user_fname">New Password</label>
                  <input
                    type="password"
                    name="new_password"
                    className={`form-control required ${Errorobject?.newpassword !== ""
                        ? "has-error"
                        : ""
                      }`}
                    placeholder="New Password"
                    onChange={(e) => {
                      setNewpassword(e.target.value);
                    }}
                    value={newpassword}


                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group-dark mb-3">
                  <label htmlFor="user_fname">Confirm Password</label>
                  <input
                    type="password"
                    value={confirmpassword}
                    name="confirm_password"
                    className={`form-control required ${Errorobject?.confirmpassword !== "" ||
                        Errorobject?.matchpassword !== ""
                        ? "has-error"
                        : ""
                      }`}
                    placeholder="Confirm Password"
                    onChange={(e) => {
                      setconfirmpassword(e.target.value);
                    }}

                  />
                </div>
              </div>
              <div className="col-lg-6">
                <button
                  className="btn btn-primary btn-large"
                  type="button"
                  name="submit"
                  onClick={(e) => {
                    changepassword(e);
                    setShow(false);
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <img
                      src="/img/loder01.gif"
                      width="60px"
                      height="11px"
                    />
                  ) : (
                    "Update"
                  )}

                </button>
              </div>
            </div>
          </div>


        </div>
      </MobileView>
    </>
  );
}

export default Changepassword;
