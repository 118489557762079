import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../services/apiServices";
import DataContext from "../Element/context";
import { Link } from "react-router-dom";
import {
  BrowserView,
  MobileView,isBrowser
} from "react-device-detect";
import LoginModal from "../login-model/index";
import Cartmodal from "../cart-modal/cart_modal";
import Sidebarmodal from "../sidebarnav-modal/sidebar-nav";
import localStorageData from "../Element/utils/localStorageData";

const Header = ({ state = "" }) => {
  const { setcartdata, cartdata } = useContext(DataContext);
  let apiServices = new ApiService();
  const dataArray = localStorageData();
  const parsedCartSession = dataArray['CartSession'];
  const cartSummary = dataArray['CartSummary'];
  const userSession = dataArray['UserSession'];
  const [show, setShow] = useState(false);
  const [Navshow, setNavShow] = useState(false);
  const [Admindata, setAdmindata] = useState("");
  const [featuredcategory, setfeaturedcategory] = useState([]);
  const [searchdata, setsearchdata] = useState([]);
  const [showcartmodal, setShowcartmodal] = useState(false);
  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
      SettingData()
      apiServices.getFeaturedcategorygetRequest().then((response) => {
        if (response?.data?.status == "success") {
          setfeaturedcategory(response?.data?.featuredCategoryData);
        }
      });
     
      if (isBrowser) {
        const handleScroll = () => {
          if (window.scrollY > 200) {
            document.getElementById("header_top").classList.add("fixed-head");
            const navbarHeight =
              document.querySelector(".fixed-header").offsetHeight;
            document.body.style.paddingTop = navbarHeight + "px";
          } else {
            document
              .getElementById("header_top")
              .classList.remove("fixed-head");
            document.body.style.paddingTop = "0";
          }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }
    }
    didMountRef.current = false;
  }, []); 
  
  const SettingData=()=>{
    apiServices
    .getsettinglistgetRequest()
    .then((res) => {
      if (res.data.status == "success") {
        setAdmindata(res?.data?.data);
      }
    })
    .catch((error) => { });
  }

  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleNavClose = () => setNavShow(false);
  const handleNavShow = () => setNavShow(true);
  const handleShowcartmodal = () => setShowcartmodal(true);
  const handleClosecartmodal = () => setShowcartmodal(false);

  const handlesearch = (e) => {
    let value = e.target.value;
    if (value == "" || value.length < 2) {
      setsearchdata("");
      return;
    }
    if (value.length > 2 || value.length == 2) {
      let dataString = {
        query: value,
      };
      apiServices.getsearchdataPostRequest(dataString).then((result) => {
        if (result.data.status == "success") {
          setsearchdata(result.data.data);
        }
      });
    }
  };


  const handleemptysearch = (e) => {
    e.preventDefault();
    if (searchdata == "" || searchdata == null) {
      // toast.info("Please Enter atleast 2 character  before search ");
    } else {
    }
  };

  const phoneNumber = Admindata?.admin_support_mobile
    ? Admindata?.admin_support_mobile
    : "";

  const redirectToPhoneCall = (e) => {
    e.preventDefault();
    if (phoneNumber.trim() !== "") {
      window.location.href = `tel:${phoneNumber.trim()}`;
    }
  };

  const handleUserimg = (e) => {
    e.preventDefault();
    const Userdata = JSON.parse(localStorage.getItem("USER_SESSION"));
    if (Userdata !== "" && Userdata !== null) {
      navigate("/account/account-overview");
    } else {
      handleShow();
    }
  };

  return (
    <>
      <BrowserView>
        <div className="topheader">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6">
                <marquee>{Admindata?.header_bar}</marquee>
              </div>
            </div>
          </div>
        </div>
        <header className="header">
          <div
            className={
              state == ""
                ? "fixed-header header-middle"
                : " fixed-header header-middle " + state
            }
            id="header_top"
          >
            <div className="container">
              <div className="header-left">
                <a href="/" className="logo">
                  <img src="/logo192.png" alt="" />
                </a>
                <div className="header-search">
                  <input
                    type="text"
                    placeholder="Search for category or tiffin & more..."
                    onChange={(e) => {
                    
                      handlesearch(e);
                    }}
                  ></input>
                  {searchdata && searchdata.length > 0 ? (
                      <div className="header-search-list" id="search_input">
                        <ul className="">
                          {searchdata?.map((items, index) => {
                            return (
                                <a href={items?.redirect}><li
                                key={index}
                              >
                                {items?.name}
                              </li></a>
                            );
                          })}
                        </ul>
                      </div>
                  ) : (
                    ""
                  )}
                  <button
                    className="btn btn-search"
                    type="submit"
                    title="submit-button"
                    onClick={(e) => {
                      handleemptysearch(e);
                      // handlebtnsearch()
                    }}
                  >
                    <i className="d-icon-search"></i>
                  </button>
                </div>
              </div>
              <div className="header-center">
                <nav className="navbar navbar-expand-lg navbar-light">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link className="nav-link" to="/about-us">About us</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/our-stores">Our Stores</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/contact-us">Contact us</Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="header-right justify-content-end">
                <a
                  className="iconbox"
                  onClick={(e) => {
                    redirectToPhoneCall(e);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="iconbox-icon me-2">
                    <i className="d-icon-phone"></i>
                  </div>
                  <div className="iconbox-content">
                    <p className="mb-0 tx-11">Call / Whatsapp</p>
                    <h6 className="mb-0 tx-14">
                      {Admindata?.admin_support_mobile}
                    </h6>
                  </div>
                </a>
                <span className="divider"></span>
                <a
                  href="#"
                  className="login-link"
                  onClick={(e) => {
                    e.preventDefault();
                    handleUserimg(e);
                  }}
                >
                  <i className="d-icon-user"></i>
                </a>
                <span className="divider"></span>
                <a
                  href="#"
                  className="cart-toggle"
                  onClick={(e) => {
                    e.preventDefault();
                    handleShowcartmodal();
                  }}
                >
                  <div className="cart-label">
                    <span className="cart-name">Cart Items</span>
                    <span className="cart-price">
                      $
                      {cartSummary?.total_amount!==null&&cartSummary?.addon_amount!==null
                        ? (cartSummary.total_amount + cartSummary.addon_amount).toFixed(2)
                        : "0.00"}
                    </span>
                  </div>
                  <i className="d-icon-bag"></i>

                  <span className="cart-count">
                   {parsedCartSession!==""&&parsedCartSession!==null?parsedCartSession?.length:0}
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="header-bottom">
            <div className="container">
              <div className="main-menu">
                <nav className="navbar navbar-expand-lg navbar-light">
                  <ul className="navbar-nav">
                    {featuredcategory?.length > 0 &&
                      featuredcategory?.map((items, index) => {
                        return (
                            <li className="nav-item" key={index}>
                              <Link
                                to={`/menu/${items?.cat_slug}`}
                                className="nav-link"
                              >
                                {items?.cat_name}
                              </Link>
                            </li>
                        );
                      })}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </header>
      </BrowserView>
      <MobileView>
        <div className="topheader">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6">
                <marquee>{Admindata?.header_bar}</marquee>
              </div>
            </div>
          </div>
        </div>
        <header className="mheaderhome">
          <div className="mheaderhome-inner">
            <div className="mheader-left">
           {/*    <a
                href="javascript:void(0)"
                className="sidenav-trigger"
                onClick={(e) => {
                  e.preventDefault();
                  
                }}
              >
                <i className="d-icon-bars2"></i>
              </a> */}
              <a href="/" className="mlogo">
                <img src="/logo192.png" alt="logo" />
              </a>
            </div>
            <div className="mheader-right">
              {/* <a className="msearch-link" href="/search" title="search">
              <i className="d-icon-search"></i>
            </a> */}
              <a
                className="mlogin-link"
                href="javascript:void(0)"
                title="login"
                onClick={(e) => {
                  e.preventDefault();
                  handleUserimg(e);
                }}
              >
                <i className="d-icon-user"></i>
              </a>
              <a
                className="mcart-link"
                href="javascript:void(0)"
                title="cart"
                onClick={(e) => {
                  e.preventDefault();
                  handleShowcartmodal();
                }}
              >
                <i className="d-icon-bag"></i>
                <span className="cart-count">
                {parsedCartSession!==""&&parsedCartSession!==null?parsedCartSession?.length:0}
                </span>
              </a>
            </div>
          </div>
          <div
            className="mheader-search"
            onClick={() => {
              navigate("/search");
            }}
          >
            <div className="mheader-search-inner">
              <i className="d-icon-search"></i>Search for tifin, sweets and
              more...
            </div>
          </div>
        </header>
      </MobileView>
      { show ? <LoginModal
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        handleShow={handleShow}
      />:null}
      { showcartmodal ? <Cartmodal
        show={showcartmodal}
        setShow={setShowcartmodal}
        handleClose={handleClosecartmodal}
        handleShow={handleShowcartmodal}
      />:null}
      { Navshow ? <Sidebarmodal
        show={Navshow}
        setShow={setNavShow}
        handleClose={handleNavClose}
        handleShow={handleNavShow}
      />:null}
    </>
  );
};

export default Header;
