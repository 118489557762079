import React, { useEffect, useState, useRef } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import { ApiService } from "../../component/services/apiServices";
import { BrowserView, MobileView } from "react-device-detect";
import AccountSidebar from "./account_sidebar";

import { useNavigate } from "react-router-dom";

import Alert from "react-bootstrap/Alert";
import { showToast } from "../../component/Element/utils/toastUtils";
function Profile() {
  const navigate = useNavigate();
  const [successmsg, setsuccessmsg] = useState("");
  const [show, setShow] = useState(false);
  let apiServices = new ApiService();
  const [UserData, setUserData] = useState({});
  const [errormsg, seterrormessage] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const didMountRef = useRef(true);
  const Userdata = JSON.parse(localStorage.getItem("USER_SESSION"));

  useEffect(() => {
    if (didMountRef.current) {
      getUserdatadetail();
    }
    didMountRef.current = false;
  }, []);

  const getUserdatadetail = () => {

      ApiService.commonGetRequest("profile-data").then((res)=>{
        if(res?.status=="success"){
          setUserData(res?.userData);
        }
      })  .catch((error) => {});
  };

  const handleUpdateInput = (e) => {
    seterrormessage("");

    const { name, value } = e.target;
    setUserData({
      ...UserData,
      [name]: value,
    });
  };

  const Profileupdate = (e) => {
    e.preventDefault();
    if (UserData?.user_name == "" || UserData?.user_name == null) {
      showToast('error',"Please enter full name");
      return;
    }
    setisLoading(true);
    const dataString = {
      user_id: Userdata?.user_id,
      user_name: UserData?.user_name,
    };
    apiServices
      .getprofileUpdatePostRequest(dataString)
      .then((res) => {
        if (res?.data?.status == "success") {
          setisLoading(false);
          getUserdatadetail();
          const existingUserData = JSON.parse(localStorage.getItem("USER_SESSION"));
          const updatedUserData = {
            ...existingUserData,
            ...res?.data?.userData,
          };
          localStorage.setItem("USER_SESSION", JSON.stringify(updatedUserData));
          showToast('success',"Profile Updated Successfully")
        } else {
          showToast('error',res?.data?.message)
          setisLoading(false);
        }
      });
  };

  return (
    <>
      <BrowserView>
        <Header />
        <div className="subheader">
          <Container>
            <Row>
              <Col lg={12}>
                <Breadcrumb className="breadcrumb-inner">
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Profile</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="">
          <section className="secmedium">
            <Container>
              <Row>
                <AccountSidebar/>
                <Col lg={9}>
                  <div className="acpanel">
                    <div className="acpanel-header">
                      <h4>My Profile</h4>
                    </div>
                    <div className="acpanel-body">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group mb-3">
                            <label htmlFor="user_fname">Full Name</label>
                            <input
                              className="form-control required"
                              type="text"
                              placeholder="Full Name"
                              defaultValue={UserData?.user_name}
                              name="user_name"
                              onChange={(e) => {
                                handleUpdateInput(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group mb-3">
                            <label htmlFor="user_email" className="form-label">
                              Email Address
                            </label>
                            <input
                              type="email"
                              name="user_email"
                              className="form-control required"
                              placeholder="Email Address"
                              value={UserData?.user_email}
                              disabled={true}
                              readOnly={true}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group mb-3">
                            <label htmlFor="user_mobile" className="form-label">
                              Mobile Number
                            </label>
                            <input
                              type="number"
                              name="user_mobile"
                              className="form-control required"
                              placeholder="Mobile Number"
                              disabled={true}
                              value={UserData?.user_mobile}
                              readOnly={true}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <button
                            className="btn btn-primary btn-large"
                            type="button"
                            name="submit"
                            onClick={(e) => {
                              Profileupdate(e);
                              setShow(false);
                            }}
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <img
                                src="/img/loder01.gif"
                                width="60px"
                                height="11px"
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>

        <Footer />
      </BrowserView>
      <MobileView>
        <div className="mwapper">
          <header className="mheader">
            <div className="mheader-left">
              <i
                className="d-icon-arrow-left tx-22"
                onClick={() => {
                  navigate("/account/account-overview");
                }}
              ></i>
              <p className="mb-0 mt-1 ms-3">My Profile</p>
            </div>
          </header>
          <div className="p-3">
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label htmlFor="user_fname">Full Name</label>
                  <input
                    type="text"
                    defaultValue={UserData?.user_name}
                    placeholder="Full Name"
                    name="user_name"
                    onChange={(e) => {
                      handleUpdateInput(e);
                    }}
                  />
                  
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label htmlFor="user_email" className="form-label">
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="user_email"
                    className="form-control required"
                    placeholder="Email Address"
                    value={UserData?.user_email}
                    disabled={true}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label htmlFor="user_mobile" className="form-label">
                    Mobile Number
                  </label>
                  <input
                    type="number"
                    name="user_mobile"
                    className="form-control required"
                    placeholder="Mobile Number"
                    value={UserData?.user_mobile}
                    disabled={true}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <button
                  className="btn btn-primary btn-block btn-large"
                  type="button"
                  name="submit"
                  onClick={(e) => {
                    Profileupdate(e);
                    setShow(false);
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <img src="/img/loder01.gif" width="60px" height="11px" />
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </MobileView>
    </>
  );
}
export default Profile;
