import Header from "../../component/Header";
import Footer from "../../component/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import {
  BrowserView,
  MobileView,
} from "react-device-detect";
import { ApiService } from "../../component/services/apiServices";
import Skeleton from "react-loading-skeleton";
import QuickViewmodal from "../../component/quickview-modal/quickview_modal";
const AddMoreItems = () => {
  const navigate = useNavigate();
  const [catmenudata, setcatmenudata] = useState([]);
  const [show, setShow] = useState(false);
  const [productid, setproductid] = useState("");
  const [loader, setLoader] = useState(true);
  const didMountRef = useRef(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (didMountRef.current) {
      ApiService
        .commonGetRequest('add-more-items')
        .then((res) => {
          if (res.status == "success") {
            setcatmenudata(res?.rowProductData);
            setLoader(false);
          } else {
            setLoader(false);
          }
        })
    }
    didMountRef.current = false;
  }, []);
  const getdiscountprice = (discount, mrp, sp) => {
    const discountprice = Number(discount);
    const mrpprice = Number(mrp);
    const discountperc = (discountprice / mrpprice) * 100;
    return discountperc;
  };
  return (
    <>
      <BrowserView>
        <Header></Header>
        <section className="secsmall">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row g-3">
                  {catmenudata.length > 0 ? (
                    <>
                      {catmenudata.length > 0 &&
                        catmenudata?.map((items, index) => {
                          return (
                            <div className="col-lg-3" key={index}>
                              <div className="product"  >
                                <figure className="product-media">
                                  <Link
                                    to={`/product/${items?.product_slug}`}
                                  >
                                    <img
                                      src={
                                        items?.product_image
                                          ? items?.product_image
                                          : "/img/no_img1.jpg"
                                      }
                                      width={280}
                                      height={315}
                                    ></img>
                                  </Link>
                                  <div className="product-label-group">
                                    {/* {items?.label.length > 0 ? (
                                      <>
                                        {items?.label?.map((items) => {
                                          return (<>
                                            <label className="product-label label-new" key={items?.label_id}>
                                              {items?.label_name}
                                            </label>
                                          </>)
                                        })}
                                      </>
                                    ) : (
                                      ""
                                    )} */}
                                    {getdiscountprice(
                                      items?.product_discount,
                                      items?.product_price,
                                      items?.product_selling_price
                                    ) !== 0 ? (
                                      <>
                                        <label className="product-label label-sale">
                                          {getdiscountprice(
                                            items?.product_discount,
                                            items?.product_price,
                                            items?.product_selling_price
                                          )}
                                          %
                                        </label>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="product-action">
                                    <a
                                      href=""
                                      className="btn-product btn-quickview"
                                      title="Quick View"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleShow();
                                        setproductid(items?.product_slug);
                                      }}
                                    >
                                      Quick View
                                    </a>
                                  </div>
                                </figure>
                                <div className="product-details">
                                  <h2 className="product-name">
                                    <Link>{items?.product_name}</Link>
                                  </h2>
                                  <div className="product-price">
                                    <ins className="new-price">
                                      ${items?.product_selling_price}
                                    </ins>
                                    {items?.product_discount > 0 ? (
                                      <>
                                        {" "}
                                        <del className="old-price">
                                          ${items?.product_price}
                                        </del>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      {loader === false ? (
                        <div className="noimg text-center">
                          <img
                            src="/img/noitemavailable.webp"
                            className="mb-5"
                            style={{ width: "250px" }}
                          ></img>
                          <h6 className="mb-0">No result found</h6>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  {loader === true ? (
                    <>
                      {Array.from({ length: 8 }, (_, index) => (
                        <div className="col-lg-3" key={index}>
                          <div className="product">
                            <Skeleton width={"100%"} height={300} />
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    ""
                  )}

                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer></Footer>
      </BrowserView>
      <MobileView>
        <header className="mheader">
          <div className="mheader-left">
            <i
              className="d-icon-arrow-left tx-22"
              onClick={() => {
                navigate("/cart");
              }}
            ></i>
            <p className="mb-0 mt-1 ms-3">Add More Items</p>
          </div>
        </header>
        <div className="mwapper">
          {loader === false ? (
            catmenudata?.length > 0 ? (
              catmenudata.map((items, index) => (
                <div className="hproduct" onClick={() => navigate(`/product/${items?.product_slug}`)} key={index}>
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h2 className="hproduct-name">{items?.product_name}</h2>
                      <div className="hproduct-price">
                        <ins className="new-price">${items?.product_selling_price}</ins>
                        {items?.product_discount > 0 && (
                          <del className="old-price">${items?.product_price}</del>
                        )}
                      </div>
                      {/* <div className="ratings-container">
                        <div className="ratings-full">
                          <span className="ratings" style={{ width: "60%" }}></span>
                        </div>
                      </div> */}
                    </div>
                    <div className="col-4">
                      <figure className="hproduct-media">
                        <img
                          src={items?.product_image ? items?.product_image : "/img/no_img1.jpg"}
                          alt={items?.product_name}
                        />
                        <div className="product-label-group">
                          {items?.product_label_name?.split(', ')?.length > 0 ? (

                            <>
                              {items?.product_label_name?.split(', ')?.map((items) => {
                                return (<>
                                  <label className="product-label label-new" key={items}>
                                    {items}
                                  </label>
                                </>)
                              })}

                            </>
                          ) : (
                            ""
                          )}
                          {getdiscountprice(items?.product_discount, items?.product_price, items?.product_selling_price) !== 0 && (
                            <label className="product-label label-sale">
                              {getdiscountprice(items?.product_discount, items?.product_price, items?.product_selling_price)}%
                            </label>
                          )}
                        </div>
                      </figure>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-lg-4">
                <div className="product">
                  <h1>No Items Found</h1>
                </div>
              </div>
            )
          ) : (<>
             
            {Array.from({ length: 8 }, (_, index) => (
              <div className="col-lg-3" key={index}>
                <div className="product"  >
                <Skeleton width={"100%"} height={"129px"} />
                </div>
              </div>
            ))}
          </>
          )}
        </div>
      </MobileView>
      {show ? <QuickViewmodal
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        handleShow={handleShow}
        productid={productid}
      /> : null}

    </>
  );
};
export default AddMoreItems;





