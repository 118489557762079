import React, { useEffect, useRef, useState } from "react";
import Header from '../../component/Header'
import Footer from '../../component/Footer'
import { useNavigate, useParams } from "react-router-dom";
import { ApiService } from "../../component/services/apiServices";
import { Breadcrumb, Container, Row, Col } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import SpinnerLoader from "../../component/Element/utils/spinnerLoader";
function VerificationEmail() {
  const navigate = useNavigate();
  const { encryption_string } = useParams();
  const [mesasge, setMessage] = useState("");
  const [verificationStatus, setVerificationStatus] = useState(0);
  const didMountRef = useRef(true) 
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  useEffect(() => {
    if (didMountRef.current) {
      const getpagesData = {
        encryption_string: encryption_string,
      };
      ApiService.commonPostRequest('email-verification',getpagesData).then(res => {
        if (res.status == 'success') {
            setSpinnerLoading(false)
            setMessage(res.message)
            setVerificationStatus(1)
        }else{
            setSpinnerLoading(false)
            setVerificationStatus(0)
        }
      })
    }
    didMountRef.current = false;
  }, [])
  return (
    <>
        {spinnerLoading ?
            <SpinnerLoader />
            : null}
        <Header />
        <style>
        {`
          /* CSS styles for beautification */
          body {
            font-family: Arial, sans-serif;
            background-color: #f0f0f0;
            margin: 0;
            padding: 0;
          }
          .secsmall {
            padding: 50px 0;
          }
          .container {
            max-width: 800px;
            margin: 0 auto;
            text-align: center;
          }
          .cartPanel {
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            padding: 30px;
          }
          .cartPanel-middle {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 200px; /* Adjust height as needed */
          }
          .carItembox {
            background-color: #f9f9f9;
            padding: 20px;
            border-radius: 8px;
          }
          h4 {
            color: #333;
            font-size: 22px;
            margin: 0;
          }
        `}
      </style>
      <section className="secsmall">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6">
              <div className="cartPanel">
                <div className="cartPanel-middle">
                  <div className="carItembox">
                    {verificationStatus ? (
                      <>
                        <h4>Email Verified Successfully !!!</h4>
                      </>
                    ) : (
                      <>
                        <h4>Email Not Verified, Please contact info@daadiskitchen.com</h4>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
        <Footer />
    </>
);
}

export default VerificationEmail