import Header from "../../../component/Header";
import Footer from "../../../component/Footer";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import {
  BrowserView,
  MobileView,
} from "react-device-detect";
import { ApiService } from "../../../component/services/apiServices";
import MobileHeader from "../../../component/Element/mobile_header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import moment from "moment";
import Col from "react-bootstrap/Col";
import Accountsidebar from "../account_sidebar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Skeleton from "react-loading-skeleton";

const MyOrder = () => {
  const navigate = useNavigate();
  let apiServices = new ApiService();
  const [OrderData, setOrderdata] = useState([]);
  const [showskeleton, setshowskeleton] = useState(false);
  const didMountRef = useRef(true);
  const Userdata = JSON.parse(localStorage.getItem("USER_SESSION"));
  useEffect(() => {
    if (didMountRef.current) {
      setshowskeleton(true)
      ApiService.commonGetRequest("order-list").then((res) => {
        if (res?.status == "success") {
          setOrderdata(res?.orderData);
          setshowskeleton(false)
        }
        else{
          setshowskeleton(false)
        }

      }).catch((error) => { })
    }
    didMountRef.current = false;
  });

  const getIndianTime = (createdat) => {
    const timestamp = createdat;
    const date = new Date(timestamp);

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    let formattedHours = hours % 12;
    formattedHours = formattedHours === 0 ? 12 : formattedHours;
    const period = hours < 12 ? "AM" : "PM";

    const formattedTime = `${formattedHours}:${minutes < 10 ? "0" : ""
      }${minutes}:${seconds < 10 ? "0" : ""}${seconds} ${period}`;
    return formattedTime;
  };


  const Orderstataus=(status)=>{
    switch (status) {
      case 1:
       return  "Confirmed"
        break;
      case 2:
     return "Order Recieved";
        break;
      case 3:
     return  "Preparing";
        break;
      case 4:
       return "Ready";
        break;
      case 5:
       return "On the way";
        break;
        case 6:
        return  'Delivered';
          break;
          case 7:
          return 'Refund';
            break;
            case 8:
           return   'canceled';
              break;
      default:
        return'Order status';
        break;
  }}

  return (
    <>
      <BrowserView>
        <Header />
        <div className="subheader">
          <Container>
            <Row>
              <Col lg={12}>
                <Breadcrumb className="breadcrumb-inner">
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>My Orders</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="">
          <section className="secmedium">
            <Container>
              <Row>
                <Accountsidebar />
                <Col lg={9}>
                  <div className="acpanel">
                    <div className="acpanel-header">
                      <h4>My Orders</h4>
                    </div>
                    <div className="acpanel-body">
                      {OrderData?.length > 0 ? (
                        OrderData?.map((items, index) => {
                          return (
                            <>

                              <div className="order-box">
                                <div className="info">
                                  <div className="info-delivery">
                                    <h6 class="mb-1 tx-14">Delivery Address</h6>
                                    <p class="mb-0 tx-13"><strong>{items?.trans_user_name}</strong></p>
                                    <p class="mb-0 tx-13"> {items?.trans_delivery_address}</p>
                                    <p class="mb-0 tx-13">Email Id : {items?.trans_user_email}</p>
                                    <p class="tx-13">Mobile No : {items?.trans_user_mobile}</p>
                                    {items?.trans_status?<>
                                    
                                      <div className="morderbox-status bg-light-yellow" style={{display:'inline-block'}}>
                                      {Orderstataus(items?.trans_status)}
                                      {/* {items?.trans_status=1?"Confirmed":items?.trans_status=2?"Order Received":items?.trans_status=3?"Preparing":items?.trans_status=4?"Ready":items?.trans_status=5?"On the Way":items?.trans_status=6?"Delivered":items?.trans_status=7?"Refunded":items?.trans_status=8?"Cancelled":""} */}
                                      </div>
                                    
                                    </>:""}
                                   
                                  </div>
                                </div>
                                <div className="bcode">
                                  <div class="orderid-box mb-5"><h6 class="mb-0">ORDER ID</h6><p class="mb-0 tx-13">{items?.trans_order_number}</p></div>
                                  <p class="tx-color-03 mb-0 tx-13">ORDER ON</p>
                                  <p class="tx-12">
                                  {moment(items?.created_at).format('MMM D, YYYY')}
                                    
                                    {/* {new Date(items?.created_at).getDate() +
                                    "/" +
                                    (new Date(items?.created_at).getMonth() +
                                      1) +
                                    "/" +
                                    new Date(
                                      items?.created_at
                                    ).getFullYear()}{" "}<br /> {items?.created_at
                                      ? getIndianTime(items?.created_at)
                                      : ""} */}
                                      
                                      </p>
                                  <Link
                                    to={`/order-details/${items?.trans_order_number}`}
                                    class="btn btn-primary-outline btn-medium btn-block">View Details</Link>
                                </div>
                              </div>



                            </>
                          );
                        })
                      ) : (
                        !showskeleton &&<>
                            <div className="noimg text-center">
                          <img
                            src="/img/no-orders.webp"
                            className="mb-5"
                            style={{ width: "250px" }}
                          ></img>
                          <h6 className="mb-0">No Order Found</h6>
                          <p>Look! Like you have not ordered yet</p>

                          <button
                            className="btn btn-primary-outline btn-medium"
                            type="button"
                            onClick={() => {
                              navigate("/");
                            }}
                          >
                            Order Now
                          </button>
                        </div>
                        
                        
                        </>
                    
                      )}
                      {showskeleton&& <>
                        <div className="row">
                        <div className="col-12">
                          <Skeleton width={"100%"} height={"150px"}></Skeleton>
                        </div>
                        <div className="col-12">
                          <Skeleton width={"100%"} height={"150px"}></Skeleton>
                        </div>
                        <div className="col-12">
                          <Skeleton width={"100%"} height={"150px"}></Skeleton>
                        </div>
                        <div className="col-12">
                          <Skeleton width={"100%"} height={"150px"}></Skeleton>
                        </div>
                       
                       
                      </div>
                      
                      
                      </>}



                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>

        <Footer />
      </BrowserView>
      <MobileView>
        <div className="mwapper">
          <header className="mheader">
            <div className="mheader-left">
              <i
                className="d-icon-arrow-left tx-22"
                onClick={() => {
                  navigate("/account/account-overview");
                }}
              ></i>
              <p className="mb-0 mt-1 ms-3">My Orders</p>
            </div>
          </header>
 {
            OrderData.length > 0 ? (
              OrderData.map((value, index) => (
                <div className="morderbox" key={index}>
                  <div className="morderbox-body">
                    <div className="row">
                      <div className="col-7">
                        <h6 className="mb-1 tx-12">Order Id: {value.trans_order_number}</h6>
                        <p className="mb-1 tx-13">Total Amount: ₹{value.trans_amt}</p>
                        <p className="mb-0 tx-13 tx-color-03">Total Items: {value.item_count}</p>
                      </div>
                      <div className="col-5 tx-right">
                        <p className="mb-1 tx-13 tx-color-03">Placed On</p>
                        <p className="mb-0 tx-12 tx-color-03"> {new Date(value?.created_at).getDate() +
                          "/" +
                          (new Date(value?.created_at).getMonth() +
                            1) +
                          "/" +
                          new Date(
                            value?.created_at
                          ).getFullYear()}{" "}
                          @{" "}
                          {value?.created_at
                            ? getIndianTime(value?.created_at)
                            : ""}</p>
                      </div>
                    </div>
                  </div>
                  <div className="morderbox-footer">
                  <div className="morderbox-status bg-light-yellow" style={{display:'inline-block'}}>
                                    {Orderstataus(value?.trans_status)}
                                    {/* {items?.trans_status=1?"Confirmed":items?.trans_status=2?"Order Received":items?.trans_status=3?"Preparing":items?.trans_status=4?"Ready":items?.trans_status=5?"On the Way":items?.trans_status=6?"Delivered":items?.trans_status=7?"Refunded":items?.trans_status=8?"Cancelled":""} */}
                                    </div>
                    <div className="morderbox-link">
                      <Link
                        to={`/order-details/${value?.trans_order_number}`}

                      >
                        View details<i class="d-icon-arrow-right ms-2"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            ) : 
            
            !showskeleton && <>
              <div className="noimg text-center">
              <img src="/img/no-orders.webp" style={{ width: '250px' }} className="mb-3" />
              <h6>No orders found!</h6>
              <p>Look like you haven't made your order yet</p>
              <button
                className="btn btn-primary-outline btn-medium" onClick={() => {
                  navigate("/");
                }}>Order Now</button>
            </div>
            
            </>
          
          }
        {showskeleton&& <>
                        <div className="row">
                        <div className="col-12">
                          <Skeleton width={"100%"} height={"150px"}></Skeleton>
                        </div>
                        <div className="col-12">
                          <Skeleton width={"100%"} height={"150px"}></Skeleton>
                        </div>
                        <div className="col-12">
                          <Skeleton width={"100%"} height={"150px"}></Skeleton>
                        </div>
                        <div className="col-12">
                          <Skeleton width={"100%"} height={"150px"}></Skeleton>
                        </div>
                        <div className="col-12">
                          <Skeleton width={"100%"} height={"150px"}></Skeleton>
                        </div>
                       
                       
                       
                      </div>
                      
                      
                      </>}
                 
         
        </div>
      </MobileView>
    </>
  );
};
export default MyOrder;
