import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import AddressModal from "../../component/address-modal";
import { BrowserView, MobileView } from "react-device-detect";
import Accountsidebar from "./account_sidebar";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../component/services/apiServices";
import SweetAlert from "react-bootstrap-sweetalert";
import SpinnerLoader from "../../component/Element/utils/spinnerLoader";
import Skeleton from "react-loading-skeleton";

function Address() {
  const [addressdata, setaddressdata] = useState([]);
  const [addressid, setaddressid] = useState("");
  const [show, setShow] = useState(false);
  const [Navshow, setNavShow] = useState(false);
  const [showskeleton, setshowskeleton] = useState(false);
  let apiServices = new ApiService();
  const handleNavClose = () => {
    setNavShow(false);
    fetchaddress();
  }
  const handleNavShow = () => setNavShow(true);
  const Userdata = JSON.parse(localStorage.getItem("USER_SESSION"));
  const navigate = useNavigate();
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
      fetchaddress();
    }
    didMountRef.current = false
  }, []);
  const showalert = (id) => {
    setaddressid(id);
    setShow(true);
  };
  const fetchaddress = () => {
    setshowskeleton(true)
    setSpinnerLoading(true);
    ApiService.commonGetRequest("get-address").then((res) => {
      if (res?.status == "success") {
        setaddressdata(res?.resUserAddress);
        setSpinnerLoading(false);
        setshowskeleton(false)
      } else if (res?.status == "session_expired") {
        localStorage.removeItem("USER_SESSION");
        setTimeout(() => {
          setSpinnerLoading(false);
          navigate("/");
        }, 1000);
        setshowskeleton(false)
      }
    })
  };
  const Confirm = () => {
    setShow(false);
    const dataString = {
      addr_id: addressid,
    };
    apiServices
      .getremoveaddresspostrequest(dataString)
      .then((response) => {
        if (response?.data?.status == "success") {
          fetchaddress();
        } else {
        }
      })
      .catch(() => { });
  };
  const Cancelalert = () => {
    setShow(false);
  };
  const editmodalhandle = (id) => {
    setaddressid(id);
    setNavShow(true);
  };
  return (
    <>
      {spinnerLoading ? <SpinnerLoader /> : null}
      <BrowserView>
        <Header />
        <div className="subheader">
          <Container>
            <Row>
              <Col lg={12}>
                <Breadcrumb className="breadcrumb-inner">
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>My Address</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="">
          <section className="secsmall">
            <Container>
              <Row>
                <Accountsidebar />
                <Col lg={9}>
                  <div className="acpanel">
                    <div className="acpanel-header">
                      <h4>My Address</h4>
                    </div>
                    <div className="acpanel-body">
                      {addressdata?.length > 0 ? (
                        <>
                          <div className="row">
                            {addressdata?.map((value, index) => {
                              return (
                                <div className="col-lg-6" key={index}>
                                  {/* <div className="addressbox mb-2 " style={{ padding: "15px" }}> */}

                                  <div className="addressbox mb-3" style={{ padding: '15px' }}>
                                    <h6 className="tx-14">
                                      {" "}
                                      {value.ua_name}{" "}
                                    </h6>
                                    {value.ua_house_no ? (
                                      <p className="tx-12 mb-0">
                                        House No : {value.ua_house_no}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    {value.ua_floor ? (
                                      <p className="tx-12 mb-0">
                                        Floor No : {value.ua_floor}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    {value.ua_complete_address ? (
                                      <p className="tx-12 mb-0">
                                        {value.ua_complete_address}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    <p className="tx-12 mb-0">
                                      {value.ua_area
                                        ? value?.ua_area + ","
                                        : ""}{" "}
                                      {value.ua_city_name
                                        ? value?.ua_city_name + ","
                                        : ""}{" "}
                                      {value.ua_state_name
                                        ? value?.us_state_name
                                        : ""}
                                      {value.ua_country_name
                                        ? value?.ua_country_name
                                        : ""}{" "}
                                      {value.ua_pincode
                                        ? value?.ua_pincode
                                        : ""}
                                    </p>
                                    {value?.ua_how_to_reach ? (
                                      <>
                                        <p className="tx-12 mb-0">
                                          How to Reach :{" "}
                                          {value?.ua_how_to_reach}
                                        </p>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <div className="addressbox-footer  mt-3">
                                      <a
                                        href="#"
                                        onClick={() => {
                                          editmodalhandle(value?.ua_id);
                                        }}
                                      >
                                        Edit
                                      </a>
                                      <a
                                        href="#"
                                        className="ms-3"
                                        onClick={() => {
                                          showalert(value?.ua_id);
                                        }}
                                      >
                                        Delete
                                      </a>
                                    </div>
                                  </div>

                                  {/* </div> */}
                                </div>
                              );
                            })}
                          </div>
                          <Button
                            className="btn btn-primary-outline btn-medium mt-2"
                            onClick={(e) => {
                              setaddressid("");
                              handleNavShow();
                            }}
                          >
                            Add New Address
                          </Button>
                        </>
                      ) :
                        (!spinnerLoading ? <>
                          <div className="noimg text-center">
                            <img
                              src="/img/noaddress.png"
                              className="mb-5"
                              style={{ width: "250px" }}
                            ></img>
                            <h6 className="mb-0">Save Your Address Now</h6>
                            <p>
                              Add your home and Office address and enjoy
                              faster checkout
                            </p>
                            <button
                              className="btn btn-primary-outline btn-medium"
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                setaddressid("");
                                handleNavShow();
                              }}
                            >
                              Add New Address
                            </button>
                          </div></> : "")
                      }
                      {showskeleton && <>
                        <div className="row">
                          <div className="col-3">
                            <Skeleton width={"100%"} height={"100px"}></Skeleton>
                          </div>
                          <div className="col-3">
                            <Skeleton width={"100%"} height={"100px"}></Skeleton>
                          </div>
                          <div className="col-3">
                            <Skeleton width={"100%"} height={"100px"}></Skeleton>
                          </div>
                          <div className="col-3">
                            <Skeleton width={"100%"} height={"100px"}></Skeleton>
                          </div>


                        </div>


                      </>}

                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
        <Footer />
      </BrowserView>
      <MobileView>
        <div className="mwapper">
          <header className="mheader">
            <div className="mheader-left">
              <i
                className="d-icon-arrow-left tx-22"
                onClick={() => {
                  navigate("/account/account-overview");
                }}
              ></i>
              <p className="mb-0 mt-1 ms-3">Address</p>
            </div>
          </header>
          {spinnerLoading ? (
            ""
          ) : (
            <>
              {addressdata.length > 0 ? (
                addressdata.map((value, index) => (
                  <div className="maddressbox" key={index}>
                    <div className="maddressbox-body">
                      <h6 className="mb-1 tx-13">
                        {value.ua_name}{" "}
                        {/* {value.ua_default_address == 1 ? "(Default)" : ""} */}
                      </h6>
                      {value.ua_house_no ? (
                        <p className="tx-12 mb-0">
                          House No : {value.ua_house_no}
                        </p>
                      ) : (
                        ""
                      )}
                      {value.ua_floor ? (
                        <p className="tx-12 mb-0">
                          Floor No : {value.ua_floor}
                        </p>
                      ) : (
                        ""
                      )}
                      {value.ua_complete_address ? (
                        <p className="tx-12 mb-0">
                          {value.ua_complete_address}
                        </p>
                      ) : (
                        ""
                      )}
                      <p className="tx-12 mb-0">
                        {value.ua_area
                          ? value?.ua_area + ","
                          : ""}{" "}
                        {value.ua_city_name
                          ? value?.ua_city_name + ","
                          : ""}{" "}
                        {value.ua_state_name
                          ? value?.us_state_name
                          : ""}
                        {value.ua_country_name
                          ? value?.ua_country_name
                          : ""}{" "}
                        {value.ua_pincode
                          ? value?.ua_pincode
                          : ""}
                      </p>
                      {value?.ua_how_to_reach ? (
                        <>
                          <p className="tx-12 mb-0">
                            How to Reach :{" "}
                            {value?.ua_how_to_reach}
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="maddressbox-footer">
                      <a
                        href="#"
                        onClick={() => {
                          editmodalhandle(value?.ua_id);
                        }}
                      >
                        Edit
                      </a>
                      <a
                        href="#"
                        className="ms-3"
                        onClick={() => {
                          showalert(value?.ua_id);
                        }}
                      >
                        Delete
                      </a>
                    </div>
                  </div>
                ))
              ) : (
                <div className="noimg text-center">
                  <img
                    src="/img/noaddress.png"
                    style={{ width: "250px" }}
                    className="mb-3"
                  />
                  <h6>Save Your Address Now!</h6>
                  <p>
                    Add your home and office addresses and enjoy faster checkout
                  </p>
                  <Button
                    className="btn btn-primary-outline btn-medium mt-2"
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavShow();
                    }}
                  >
                    Add New Address
                  </Button>
                </div>
              )}
              {addressdata.length > 0 ? (
                <div className="maddress-footer">
                  <Button
                    className="btn btn-primary-outline btn-medium btn-full"
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavShow();
                    }}
                  >
                    Add New Address
                  </Button>
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </MobileView>
      {Navshow ? (
        <AddressModal
          show={Navshow}
          setShow={setNavShow}
          handleClose={handleNavClose}
          handleShow={handleNavShow}
          addressid={addressid}
        />
      ) : null}
      <SweetAlert
        warning
        confirmBtnCssClass="alertpop"
        title={` Are you sure ? You want to delete the address `}
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
        show={show}
        onConfirm={Confirm}
        onCancel={Cancelalert}
        btnSize="md"
        showCancel
        cancelBtnBsStyle="danger"
      ></SweetAlert>
    </>
  );
}
export default Address;
