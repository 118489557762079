import React, { useEffect, useState, useRef } from 'react'
import {BrowserView,MobileView,} from "react-device-detect";
import Cartmodal from '../cart-modal/cart_modal';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import localStorageData from './utils/localStorageData';

function MobileHeader({page_name,cart_count,navigateroute}) {
  const didMountRef = useRef(true);
  const [showCart, setShowCart] = useState(false);
  const [showHeaderStatus,SetShowHeaderStatus] = useState(false)
  const navigate=useNavigate()
  const cartsession = JSON.parse(localStorage.getItem('CART_SESSION')); 
  const handleShowCart = () => setShowCart(true);
  const handleChildCartData = (status) => {
    setShowCart(status)
  };
  const handleClose = () => {
    setShowCart(false);
  };
  const dataArray = localStorageData();
  const parsedCartSession = dataArray['CartSession'];
  const backnavigate=()=>{
    navigate(navigateroute)
  }
  return (
    <>
    
    <MobileView>
    <header className="mheader">
        <div className="mheader-left"><i className="d-icon-arrow-left tx-22 me-3" onClick={() => { backnavigate() }}></i>{page_name}
        </div>
        <div className="mheader-right">
          <a className="msearch-link" href="/search" title="search">
            <i className="d-icon-search"></i>
          </a>
          <a
            className="mcart-link"
            href="javascript:void(0)"
            title="cart"
            onClick={(e) => {
              e.preventDefault();
              handleShowCart();
            }}
          >
            <i className="d-icon-bag"></i>
            <span className="cart-count">  {parsedCartSession!==""&&parsedCartSession!==null?parsedCartSession?.length:0}</span>   
          </a>

        </div>
      </header>
      <Cartmodal
        show={showCart}
        setShow={setShowCart}
        handleClose={handleClose}
        handleShow={handleShowCart}
      />

    </MobileView>
    
    
    </>
  )
}

export default MobileHeader