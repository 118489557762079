import React, { useEffect, useState, useRef } from "react";
import SpinnerLoader from "../../component/Element/utils/spinnerLoader";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import CartHeader from "../../component/cart_header";
import localStorageData from "../../component/Element/utils/localStorageData";
import { ApiService } from "../../component/services/apiServices";
import { showToast } from "../../component/Element/utils/toastUtils";
import Modal from "react-bootstrap/Modal";
import CouponModal from "../../component/Element/modal/coupon-modal/coupon_modal";
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import creditCardType from 'credit-card-type';
const CheckOut = () => {
    const didMountRef = useRef(true);
    const navigate = useNavigate();
    const [adminData, setadminData] = useState()
    const localStorageSession = localStorageData();
    const CartSession = localStorageSession['CartSession'];
    const CouponSession = localStorageSession['CouponSession'];
    const cartSummary = localStorageSession['CartSummary'];
    const userSession = localStorageSession['UserSession'];
    const AddressSession = localStorageSession['AddressSession'];
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [showcardstate, setshowcradstate] = useState(false)
    const [step, setStep] = useState(1)
    const [paybycard, setpaybycard] = useState(false)
    const [paybypaypal, setpaybypaypal] = useState(false)
    const [paymentmode, setpaymentmode] = useState("card")
    const [savedcarddata, setsavedcarddata] = useState([])
    const [selectedcardid, setselectedcardid] = useState("")
    const [walletblnce, setwalletblnce] = useState(0)
    const [addnewcard, setaddnewcard] = useState(false)
    const [shortNote, setShortNote] = useState('');
    const [showCoupon, setShowCoupon] = useState(false);
    const handleShowCoupon = () => setShowCoupon(true);
    const handleCloseCoupon = () => setShowCoupon(false);
    const handleChildCouponData = (status) => {
        setShowCoupon(status);
    };
    const removeCoupon = () => {
        localStorage.removeItem("COUPON_SESSION");
        window.location.reload();
    };
    const initialOptions = {
        clientId: "AbToFBAeDlezkwNZ9KlKwsO3eeQsBuB9jWGAaKK7mALuHeVWvGoBuxr_TPTNCJlgnFX4yp0evU5pbRxA",
        currency: "USD",
        intent: "capture",
    };
    let deliveryAmountTotal = 0;
    let deliveryAmountTotalMobile = 0;
    const mappedCartData = CartSession.reduce((acc, curr) => {
        if (!acc[curr.product_day]) {
            acc[curr.product_day] = [];
        }
        acc[curr.product_day].push(curr);
        return acc;
    }, {});

    useEffect(() => {
        if (didMountRef.current) {
            setSpinnerLoading(true)
            ApiService.commonGetRequest('setting-data').then(res => {
                if (res.status == 'success') {
                    setadminData(res?.data)
                    setTimeout(() => {
                        setSpinnerLoading(false)
                    }, 500);
                }
            }).catch((error) => {
                setTimeout(() => {
                    setSpinnerLoading(false)
                }, 500);
            })

            ApiService.commonGetRequest("user-savedc-cards").then((res) => {
                if (res?.status == "success") {
                    setsavedcarddata(res?.cards)
                }
            })

            ApiService.commonGetRequest("wallet-balance").then((res) => {
                if (res?.status == "success") {
                    setwalletblnce(res?.wallet_balance)
                }
            })
        }
        didMountRef.current = false;
    }, []);

    const toggleOrderSummary = () => {
        const orderSummaryContent = document.querySelector('.checkoutOrdersummary-content');
        const iconElement = document.querySelector('.checkoutOrdersummary-header i');

        if (orderSummaryContent.style.display === 'none' || orderSummaryContent.style.display === '') {
            orderSummaryContent.style.display = 'block';
            iconElement.classList.remove('d-icon-angle-right');
            iconElement.classList.add('d-icon-angle-down');
        } else {
            orderSummaryContent.style.display = 'none';
            iconElement.classList.remove('d-icon-angle-down');
            iconElement.classList.add('d-icon-angle-right');
        }
    }

    const [cardState, setCardState] = useState({
        number: "",
        name: "",
        expiry: "",
        cvc: "",
        name: "",
        focus: "",
        save_card: 0
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let updatedValue = value;
        if (name === 'number') {
            const numberWithoutSpaces = value.replace(/\s/g, '');
            updatedValue = numberWithoutSpaces.replace(/\D/g, '');
            updatedValue = updatedValue.replace(/[^\d]/g, '');
            updatedValue = updatedValue.replace(/(.{4})/g, '$1 ').trim();
            if (updatedValue.length > 20) {
                return false;
            }
        } else if (name === 'expiry') {
            updatedValue = value.replace(/[^\d\/]/g, '');

            if (updatedValue.length === 2 && !updatedValue.includes('/')) {
                updatedValue += '/';
            }
            if (updatedValue.length > 5) {
                return false;
            }
        } else if (name === 'cvc') {
            if (updatedValue.length > 4) {
                return false;
            }
        }
        setCardState({
            ...cardState,
            [name]: updatedValue
        });
    };
    const handlesavedcard = (e, item) => {
        if (cardState?.number == item?.ucd_four_number) {
            const { name, value } = e.target;
            let updatedValue = value;
            if (name === "cvc") {
                if (updatedValue.length > 3) {
                    showToast("error", "CVC number cannot exceed with 3 digits")
                    return false
                } else if (updatedValue.length == 3) {

                    setCardState((prevCardState) => ({
                        ...prevCardState,
                        cvc: updatedValue,
                        save_card: 1,
                    }));
                }
            }
        }
    }

    const handleInputFocus = (e) => {
        setCardState((prev) => ({ ...prev, focus: e.target.name }));
    };

    const goBack = () => {
        if (AddressSession && AddressSession['delivery'] && AddressSession['delivery'].ua_id > 0) {
            navigate('/cart-address')
        } else {
            navigate('/cart')
        }
    }

    const savecarddetail = (e) => {
        if (e.target.checked) {
            setCardState({
                ...cardState,
                ["save_card"]: 1
            });
        }
        else {
            setCardState({
                ...cardState,
                ["save_card"]: 0
            });
        }
    }

    const selectedcarddetail = (item) => {
        setaddnewcard(false);
        setselectedcardid(item?.ucd_four_number)
        const clickedCard = savedcarddata.find(card => card.ucd_four_number === item?.ucd_four_number);
        if (clickedCard) {
            setshowcradstate(true)
            //cardOption()
            setpaybycard(true);
            setpaymentmode("savedcard");
            setpaybypaypal(false)
            setCardState({
                number: clickedCard?.ucd_four_number,
                save_card: 0,
                ucd_id: clickedCard?.ucd_id
            })
        } else {
            showToast("error", "Card not found or Transaction not possible with that card ")
        }
    }

    const paypalOption = (e) => {
        setpaybypaypal(true); setpaymentmode("paypal"); setpaybycard(false)
    }

    const cardOption = () => {
        setpaybycard(true); setpaymentmode("card"); setpaybypaypal(false)
    }

    const walletOption = () => {
        setpaybycard(false); setpaymentmode("wallet"); setpaybypaypal(false)
    }

    const addnewcarddetailfn = () => {
        setaddnewcard(true);
        setselectedcardid("");
        setshowcradstate(false);
        setCardState({
            number: "",
            name: "",
            expiry: "",
            cvc: "",
            name: "",
            focus: "",
            save_card: 0
        })
    }

    const cardImage = (type) => {
        switch (type) {
            case 'Visa':
                return ('/img/vcard.png');
            case 'MasterCard':
                return ('/img/mcard.png');
            case 'American Express':
                return ('/img/amex.png');
            case 'Discover':
                return ('/img/dcard.png');
            case 'atm':
                return ('/img/atmcards.png');
            case 'JCB':
                return ('/img/jcard.png');
            default:
                return ('/img/ucard.png');
        }
    }

    const ProceedToPayment = () => {
        if (AddressSession && AddressSession['delivery'] && AddressSession['delivery'].ua_id > 0) {

        } else if (AddressSession && AddressSession['pickup']) {

        } else {
            showToast('error', 'Please select address');
            return false
        }

        if (Object.entries(mappedCartData).length == 0) {
            showToast('error', 'We cannot process the request currently.');
            return false
        }
        setStep(2)
    }

    const makePaymentProcess = (type) => {
        if (cardState.number == '') {
            showToast('error', 'Please enter card number')
            return false;
        } else if (cardState.number.length > 19 || cardState.number.length < 19) {
            showToast('error', 'Please enter valid card details')
            return false;
        }

        if (cardState.expiry == '') {
            showToast('error', 'Please enter name')
            return false;
        }

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear() % 100;
        const currentMonth = currentDate.getMonth() + 1;
        const [inputMonth, inputYear] = cardState.expiry.split('/').map(Number);
        const isValidDate = inputYear > currentYear || (inputYear === currentYear && inputMonth <= currentMonth);
        if (!isValidDate) {
            showToast('error', 'Please enter valid expiry')
            return false;
        }

        if (cardState.cvc.length > 3 || cardState.cvc.length < 3) {
            showToast('error', 'Please enter valid cvc')
            return false;
        }
        setSpinnerLoading(true)

        const dataString = {
            cart_response: CartSession,
            trans_payment: type == 1 ? (parseFloat(cartSummary.total_amount) + parseFloat(cartSummary.addon_amount) + parseFloat(deliveryAmountTotal) - parseFloat(CouponSession.discount_amount)).toFixed(2) : (parseFloat(cartSummary.total_amount) + parseFloat(cartSummary.addon_amount) + parseFloat(deliveryAmountTotalMobile) - parseFloat(CouponSession.discount_amount)).toFixed(2),
            user_id: userSession.user_id,
            promo_id: CouponSession.promo_id,
            ua_id: AddressSession['delivery'] ? AddressSession['delivery'].ua_id : 0,
            trans_payment_method: 'card',
            delivery_type: AddressSession['delivery'] ? 0 : 1,
            trans_shipping_amount: type == 1 ? parseFloat(deliveryAmountTotal) : parseFloat(deliveryAmountTotalMobile),
            total_discount_amount: parseFloat(cartSummary.discount),
            order_note: shortNote,
            promo_discount_amt: CouponSession.discount_amount,
            CouponSession: CouponSession,
            cartSummary: cartSummary,
            AddressSession: AddressSession,
            userSession: userSession,
            card_number: Number(cardState.number.replace(/\s/g, '')),
            exp_month: inputMonth,
            exp_year: inputYear,
            cvc: cardState.cvc,
            name: cardState.name,
            save_card: cardState.save_card,
            ucd_id: 0
        }


        ApiService.commonPostRequest('create-card-token-new', dataString).then((res) => {
            if (res.status == 'success') {
                localStorage.removeItem("ADDRESS_SESSION");
                localStorage.removeItem("COUPON_SESSION");
                localStorage.removeItem("CART_SESSION");
                localStorage.removeItem("RECENTLY_VIEWED");
                setSpinnerLoading(false)
                navigate('/thankyou/' + res.orderid)
            } else {
                setSpinnerLoading(false)
                showToast('error', res.message)
            }
        })
    }

    const proceedtopaybywallet = (type) => {
        const cartTotal = cartSummary?.total_amount
        const walletbalance = walletblnce
        if (walletbalance < cartTotal) {
            showToast("error", "Insufficient Balance , Please add Money to Wallet for Payment with wallet")
            return false;
        } else {
            setSpinnerLoading(true)
            const dataString = {
                cart_response: CartSession,
                trans_payment: type == 1 ? (parseFloat(cartSummary.total_amount) + parseFloat(cartSummary.addon_amount) + parseFloat(deliveryAmountTotal) - parseFloat(CouponSession.discount_amount)).toFixed(2) : (parseFloat(cartSummary.total_amount) + parseFloat(cartSummary.addon_amount) + parseFloat(deliveryAmountTotalMobile) - parseFloat(CouponSession.discount_amount)).toFixed(2),
                // user_id: userSession.user_id,
                promo_id: CouponSession.promo_id,
                ua_id: AddressSession['delivery'] ? AddressSession['delivery'].ua_id : 0,
                trans_payment_method: 'wallet',
                delivery_type: AddressSession['delivery'] ? 0 : 1,
                trans_shipping_amount: type == 1 ? parseFloat(deliveryAmountTotal) : parseFloat(deliveryAmountTotalMobile),
                total_discount_amount: parseFloat(cartSummary.discount),
                order_note: shortNote,
                promo_discount_amt: CouponSession.discount_amount,
                CouponSession: CouponSession,
                cartSummary: cartSummary,
                AddressSession: AddressSession,
                userSession: userSession,
            }
            ApiService.commonPostRequest('create-wallet-transaction', dataString).then((res) => {
                if (res.status == 'success') {
                    localStorage.removeItem("ADDRESS_SESSION");
                    localStorage.removeItem("COUPON_SESSION");
                    localStorage.removeItem("CART_SESSION");
                    localStorage.removeItem("RECENTLY_VIEWED");
                    setSpinnerLoading(false)
                    navigate('/thankyou/' + res.orderid)
                } else {
                    setSpinnerLoading(false)
                    showToast('error', res.message)
                }
            })

        }
    }

    const ProceedToPaymentbysavedcard = (type) => {
        if (cardState.cvc.length > 3 || cardState.cvc.length < 3) {
            showToast('error', 'Please enter valid cvc')
            return false;
        }
        setSpinnerLoading(true)

        const dataString = {
            cart_response: CartSession,
            trans_payment: type == 1 ? (parseFloat(cartSummary.total_amount) + parseFloat(cartSummary.addon_amount) + parseFloat(deliveryAmountTotal) - parseFloat(CouponSession.discount_amount)).toFixed(2) : (parseFloat(cartSummary.total_amount) + parseFloat(cartSummary.addon_amount) + parseFloat(deliveryAmountTotalMobile) - parseFloat(CouponSession.discount_amount)).toFixed(2),
            user_id: userSession.user_id,
            promo_id: CouponSession.promo_id,
            ua_id: AddressSession['delivery'] ? AddressSession['delivery'].ua_id : 0,
            trans_payment_method: 'card',
            delivery_type: AddressSession['delivery'] ? 0 : 1,
            trans_shipping_amount: type == 1 ? parseFloat(deliveryAmountTotal) : parseFloat(deliveryAmountTotalMobile),
            total_discount_amount: parseFloat(cartSummary.discount),
            order_note: shortNote,
            promo_discount_amt: CouponSession.discount_amount,
            CouponSession: CouponSession,
            cartSummary: cartSummary,
            AddressSession: AddressSession,
            userSession: userSession,
            cvc: cardState.cvc,
            save_card: cardState.save_card,
            ucd_id: cardState.ucd_id
        }


        ApiService.commonPostRequest('create-card-token-new', dataString).then((res) => {
            if (res.status == 'success') {
                localStorage.removeItem("ADDRESS_SESSION");
                localStorage.removeItem("COUPON_SESSION");
                localStorage.removeItem("CART_SESSION");
                localStorage.removeItem("RECENTLY_VIEWED");
                setSpinnerLoading(false)
                navigate('/thankyou/' + res.orderid)
            } else {
                setSpinnerLoading(false)
                showToast('error', res.message)
            }
        })
    }
    return (
        <>
            {spinnerLoading ? <SpinnerLoader /> : null}
            <BrowserView>
                <CartHeader />
                <section className="secsmall">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6">
                                <div className="checkoutPanel">
                                    {step == 1 ? <>
                                        {AddressSession && AddressSession['delivery'] && AddressSession['delivery'].ua_id > 0 ?
                                            <div className="checkoutPanel-address">
                                                <div className="changeaddress" onClick={(e) => { navigate('/cart-address') }}>Change</div>
                                                <h5 className="tx-15 mb-3">Shipping & Billing Address</h5>
                                                <h6 className="tx-14">{AddressSession['delivery'].ua_name}</h6>
                                                {AddressSession['delivery'].ua_house_no ? <p className="tx-14 mb-0">House No: {AddressSession['delivery'].ua_house_no}</p> : null}
                                                {AddressSession['delivery'].ua_floor ? <p className="tx-14 mb-0">Floor: {AddressSession['delivery'].ua_floor}</p> : null}
                                                <p className="tx-14 mb-0">{AddressSession['delivery'].ua_complete_address} {AddressSession['delivery'].ua_city_name ? ', ' + AddressSession['delivery'].ua_city_name : ''}</p>
                                            </div>
                                            :
                                            <div className="checkoutPanel-address">
                                                <div className="changeaddress" onClick={(e) => { navigate('/cart') }}>Change</div>
                                                <h5 className="tx-15 mb-3">Pickup From</h5>
                                                <h6 className="tx-14">Daadi's Kitchen</h6>
                                                <p className="tx-14 mb-0">{adminData?.address}</p>
                                            </div>
                                        }
                                        <div className="checkoutPanel-ordernote">
                                            <label>Add Order Note</label>
                                            <textarea name="shortnote" onChange={(e) => setShortNote(e.target.value)}></textarea>
                                        </div>
                                        <div className="checkoutOrdersummary">
                                            <div className="checkoutOrdersummary-header" onClick={(e) => toggleOrderSummary()}>
                                                <h6 className="mb-0 tx-14 mt-1">Order Summary</h6>
                                                <i className="d-icon-angle-right"></i>
                                            </div>
                                            <div className="checkoutOrdersummary-content" style={{ display: 'none' }}>
                                                {Object.entries(mappedCartData).map(([day, items], index) => {
                                                    let deliveryAmount = 0;
                                                    let deliverytotalSum = items.reduce((total, item) => {
                                                        const price =
                                                            (parseFloat(item.product_selling_price) +
                                                                parseFloat(item.product_addon_price)) *
                                                            Number(item.quantity);
                                                        return total + price;
                                                    }, 0);

                                                    const conditionExists = items.some(item => item.product_type === 1);

                                                    if (
                                                        AddressSession &&
                                                        AddressSession['delivery'] &&
                                                        AddressSession['delivery'].ua_id > 0
                                                    ) {
                                                        if (conditionExists) {
                                                            deliveryAmount =
                                                                deliverytotalSum > adminData?.shipping_amount_free
                                                                    ? 0
                                                                    : parseFloat(adminData?.shipping_amount_tiffin);
                                                        } else {
                                                            deliveryAmount =
                                                                deliverytotalSum > adminData?.shipping_amount_free
                                                                    ? 0
                                                                    : parseFloat(adminData?.shipping_amount_other);
                                                        }

                                                        deliveryAmountTotal += deliveryAmount;
                                                    } else {
                                                        deliveryAmountTotal = 0;
                                                    }
                                                    return (
                                                        <div className="cItembSection" key={index}>
                                                            {items.map((item, itemIndex) => (
                                                                <div className="cItemb" key={`${index}-${itemIndex}`}>
                                                                    <div className="cItemb-media">
                                                                        <img src={item.product_image} alt={item.product_name} />
                                                                    </div>
                                                                    <div className="cItemb-content">
                                                                        <h6 className="fw600 mb-0">{item.product_name}</h6>
                                                                        <p className="tx-gray mb-1">
                                                                            {item.product_meal_time === 'lunch'
                                                                                ? item.adminData.lunch_note
                                                                                : item.adminData.dinner_note}
                                                                        </p>
                                                                        <p className="fw600 mb-0 mt-1">
                                                                            ${(parseFloat(item.product_selling_price) +
                                                                                parseFloat(item.product_addon_price)).toFixed(2)}
                                                                        </p>
                                                                        <div className="cItemb-footer mt-1">
                                                                            <div className="qty-changer">
                                                                                <button>
                                                                                    <i className="fas fa-minus psbmiuns"></i>
                                                                                </button>
                                                                                <span>{item.quantity}</span>
                                                                                <button>
                                                                                    <i className="fas fa-plus psbplus"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    );
                                                })}

                                            </div>
                                        </div>
                                        {
                                            CouponSession.discount_amount === 0 ? (
                                                <div className="applyCoupons" onClick={(e) => handleShowCoupon(true)}>
                                                    <div className="d-flex align-items-center">
                                                        <img src="/img/presents.png" width={25} style={{ marginRight: '10px' }}></img>
                                                        <h6 className="mb-0 tx-14 mt-1">Apply Coupon</h6>
                                                    </div>
                                                    <div> <i className="d-icon-angle-right tx-22 mt-1"></i></div>
                                                </div>
                                            ) : (
                                                <div className="applyCoupons" onClick={(e) => removeCoupon()}>
                                                    <div className="d-flex align-items-center">

                                                        <h6 className="mb-0 tx-14 mt-1">{CouponSession.promo_code} Applied</h6>
                                                    </div>
                                                    <div> <i className="fas fa-trash tx-18 mt-1 tx-theme"></i></div>
                                                </div>
                                            )}
                                        <div className="cartsummary">
                                            <h6>Cart Summary</h6>
                                            <ul>
                                                <li>Items Total<span>${(parseFloat(cartSummary.itemTotal) + parseFloat(cartSummary.addon_amount)).toFixed(2)}</span></li>
                                                <li>Discount<span>${parseFloat(cartSummary.discount).toFixed(2)}</span></li>
                                                <li>Coupon Discount<span>$ {CouponSession.discount_amount > 0 ? CouponSession.discount_amount : 0.00}</span></li>
                                                <li>Delivery {deliveryAmountTotal > 0 ? <span>${deliveryAmountTotal}</span> : <span className="tx-green">Free</span>}</li>

                                                <hr></hr>
                                                <li style={{ fontWeight: 'bold' }}>Total Amount<span>${(parseFloat(cartSummary.total_amount) + parseFloat(cartSummary.addon_amount) + parseFloat(deliveryAmountTotal) - parseFloat(CouponSession.discount_amount)).toFixed(2)}</span></li>
                                            </ul>
                                        </div>

                                        <div className="cartsummary-footer">
                                            <button type="button" className="btn btn-primary btn-full btn-large" onClick={(e) => ProceedToPayment()}>Continue</button>
                                        </div>

                                    </> :
                                        <>
                                            {Object.entries(mappedCartData).map(([day, items], index) => {
                                                let deliveryAmount = 0;
                                                let deliverytotalSum = items.reduce((total, item) => {
                                                    const price =
                                                        (parseFloat(item.product_selling_price) +
                                                            parseFloat(item.product_addon_price)) *
                                                        Number(item.quantity);
                                                    return total + price;
                                                }, 0);

                                                const conditionExists = items.some(item => item.product_type === 1);

                                                if (
                                                    AddressSession &&
                                                    AddressSession['delivery'] &&
                                                    AddressSession['delivery'].ua_id > 0
                                                ) {
                                                    if (conditionExists) {
                                                        deliveryAmount =
                                                            deliverytotalSum > adminData?.shipping_amount_free
                                                                ? 0
                                                                : parseFloat(adminData?.shipping_amount_tiffin);
                                                    } else {
                                                        deliveryAmount =
                                                            deliverytotalSum > adminData?.shipping_amount_free
                                                                ? 0
                                                                : parseFloat(adminData?.shipping_amount_other);
                                                    }

                                                    deliveryAmountTotal += deliveryAmount;
                                                } else {
                                                    deliveryAmountTotal = 0;
                                                }
                                            })}
                                            <div className="cardselect">
                                                <h6>Select Payment Method</h6>
                                                <div className={`cardwallet ${paymentmode == "wallet" && !paybycard && !paybypaypal ? "active" : ""}`} onClick={walletOption}>
                                                    <div className="cardwallet-price">${Number(walletblnce)}</div>
                                                    <div>
                                                        <h6 className="mb-0 tx-14">Pay Using Wallet</h6>
                                                        <p className="mb-0 tx-12">Pay your order using wallet</p>
                                                    </div>
                                                    {paymentmode == "wallet" && !paybycard && !paybypaypal ? <div className="cardselectleft">
                                                        <img src="/img/correct.png" className="cardsaveboxarrow"></img>
                                                    </div> : ""}

                                                </div>
                                                <div className="cardselect-inner" >
                                                    <div className={`cardselectbox ${paymentmode == "card" ? "active" : ""}`} onClick={cardOption}>
                                                        <ul>
                                                            <li><img src="/img/visa.svg" height={38}></img></li>
                                                            <li><img src="/img/master.svg" height={38}></img></li>
                                                            <li><img src="/img/amex.svg" height={38}></img></li>
                                                        </ul>

                                                    </div>
                                                    {/*  <div className={`cardselectbox ${paymentmode == "paypal" ? "active" : ""}`} onClick={paypalOption}>
                                                    <ul>
                                                        <li><img src="/img/paypal.svg" height={38}></img></li>
                                                    </ul>


                                                </div> */}
                                                </div>

                                            </div>
                                            <div className="cardsection">
                                                {paymentmode == "card" || paymentmode == "savedcard" ? <>
                                                    {savedcarddata?.length > 0 ? <>
                                                        {savedcarddata?.map((items, index) => {
                                                            return (
                                                                <div className="cardsavebox" key={index}>

                                                                    <div className={selectedcardid == items.ucd_four_number ? "cardsaveboxnu active" : "cardsaveboxnu"} onClick={(e) => selectedcarddetail(items)}>
                                                                        <div className="cardsavebox-icon">
                                                                            <img src={cardImage(items?.ucd_card_type)} style={{ width: "40px", marginTop: "7px" }}></img>
                                                                        </div>
                                                                        <div className="cardsavebox-number">Ending with {items?.ucd_four_number}</div>
                                                                        <img src="/img/correct.png" className="cardsaveboxselect"></img>
                                                                        {selectedcardid !== items.ucd_four_number ? <>

                                                                            {/* <img src="/img/radio-button.png" width={"20px"} className=""></img> */}
                                                                        </> : ""}

                                                                    </div>
                                                                    {cardState?.number == items?.ucd_four_number ? <>
                                                                        <div className="cardsavebox-cvv">
                                                                            <input type="number" name="cvc"
                                                                                className="form-control"
                                                                                placeholder="CVC"
                                                                                pattern="\d{3,4}"
                                                                                value={cardState?.cvc}
                                                                                onChange={(e) => { handlesavedcard(e, items) }}
                                                                                onFocus={handleInputFocus}
                                                                                required></input>
                                                                            <img src="/img/cvv.svg" height={45} style={{ borderRadius: '4px' }}></img>
                                                                        </div>
                                                                    </> : ""}
                                                                </div>
                                                            )
                                                        })}

                                                        <div className="cardsavebox" onClick={() => { addnewcarddetailfn() }}>
                                                            <div className="cardsaveboxnu">
                                                                <div className="cardsavebox-icon"><img src="/img/atmcards.png" style={{ width: "40px", marginTop: "7px" }}></img></div>
                                                                <div className="cardsavebox-number">Use a different card</div>
                                                                <img src="/img/next.png" className="cardsaveboxarrow"></img>
                                                            </div>
                                                        </div>
                                                        {addnewcard ?
                                                            <div className="carddetal">
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="crdform crdform-icon mb-3">
                                                                            <label>Card Number</label>
                                                                            <input type="text"
                                                                                name="number"
                                                                                className="form-control"
                                                                                placeholder="Card Number"
                                                                                value={cardState.number}
                                                                                onChange={handleInputChange}
                                                                            ></input>
                                                                            <div className="cicon"><img src="/img/cradicon.svg" height={45} style={{ borderRadius: '4px' }}></img></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="crdform mb-3">
                                                                            <label>Expiry date</label>
                                                                            <input type="text"
                                                                                name="expiry"
                                                                                className="form-control"
                                                                                placeholder="Valid Thru"
                                                                                pattern="\d\d/\d\d"
                                                                                value={cardState.expiry}
                                                                                onChange={handleInputChange}
                                                                            ></input>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="crdform crdform-icon mb-3">
                                                                            <label>CVV</label>
                                                                            <input type="number"
                                                                                name="cvc"
                                                                                className="form-control"
                                                                                placeholder="CVC"
                                                                                pattern="\d{3,4}"
                                                                                value={cardState.cvc}
                                                                                onChange={handleInputChange}
                                                                            ></input>
                                                                            <div className="cicon"><img src="/img/cvv.svg" height={45} style={{ borderRadius: '4px' }}></img></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <div className="crdform checkboxinput mb-3">
                                                                            <input type="checkbox" onChange={(e) => { savecarddetail(e) }}></input>
                                                                            Save card for next time
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            : ''}
                                                    </> :
                                                        <div className="carddetal">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="crdform crdform-icon mb-3">
                                                                        <label>Card Number</label>
                                                                        <input type="text"
                                                                            name="number"
                                                                            className="form-control"
                                                                            placeholder="Card Number"
                                                                            value={cardState.number}
                                                                            onChange={handleInputChange}
                                                                        ></input>
                                                                        <div className="cicon"><img src="/img/cradicon.svg" height={45} style={{ borderRadius: '4px' }}></img></div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="crdform mb-3">
                                                                        <label>Expiry date</label>
                                                                        <input type="text"
                                                                            name="expiry"
                                                                            className="form-control"
                                                                            placeholder="Valid Thru"
                                                                            pattern="\d\d/\d\d"
                                                                            value={cardState.expiry}
                                                                            onChange={handleInputChange}
                                                                        ></input>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="crdform crdform-icon mb-3">
                                                                        <label>CVV</label>
                                                                        <input type="number"
                                                                            name="cvc"
                                                                            className="form-control"
                                                                            placeholder="CVC"
                                                                            pattern="\d{3,4}"
                                                                            value={cardState.cvc}
                                                                            onChange={handleInputChange}
                                                                        ></input>
                                                                        <div className="cicon"><img src="/img/cvv.svg" height={45} style={{ borderRadius: '4px' }}></img></div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="crdform checkboxinput mb-3">
                                                                        <input type="checkbox" onChange={(e) => { savecarddetail(e) }}></input>
                                                                        Save card for next time
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>} </>
                                                    : ''}

                                            </div>
                                            {paymentmode == "wallet" ? <>
                                                <div className="cartsummary-footer">
                                                    <button type="button" className="btn btn-primary btn-full btn-large" disabled={spinnerLoading} onClick={(e) => proceedtopaybywallet(1)}>Proceed to Payment (${(parseFloat(cartSummary.total_amount) + parseFloat(cartSummary.addon_amount) + parseFloat(deliveryAmountTotal) - parseFloat(CouponSession.discount_amount)).toFixed(2)})</button>
                                                </div>
                                            </>
                                                :
                                                paymentmode == "paypal" ? <div className="p-3">
                                                    {/* <PayPalButtons style={{ layout: "horizontal" }}
                                                /> */}
                                                    <PayPalScriptProvider options={initialOptions}>
                                                        <PayPalButtons style={{ layout: "horizontal" }}
                                                            createOrder={(data, actions) => {
                                                                // Define the createOrder function
                                                                return actions.order.create({
                                                                    // Additional order attributes
                                                                    purchase_units: [
                                                                        {
                                                                            amount: {
                                                                                currency_code: "USD",
                                                                                value: (
                                                                                    parseFloat(cartSummary.total_amount) +
                                                                                    parseFloat(cartSummary.addon_amount) +
                                                                                    parseFloat(deliveryAmountTotal) -
                                                                                    parseFloat(cartSummary.discount) -
                                                                                    parseFloat(CouponSession.discount_amount)
                                                                                ).toFixed(2),
                                                                            },
                                                                        },
                                                                    ],
                                                                });
                                                            }}
                                                            onApprove={(data, actions) => {
                                                                return actions.order.capture().then(function (details) {
                                                                    console.log("details", details);
                                                                });
                                                            }}
                                                        />
                                                    </PayPalScriptProvider>
                                                </div>
                                                    : paymentmode == "card" ?
                                                        <div className="cartsummary-footer">
                                                            <button type="button" className="btn btn-primary btn-full btn-large" disabled={spinnerLoading} onClick={(e) => makePaymentProcess(1)}>Proceed to Payment (${(parseFloat(cartSummary.total_amount) + parseFloat(cartSummary.addon_amount) + parseFloat(deliveryAmountTotal) - parseFloat(CouponSession.discount_amount)).toFixed(2)})</button>
                                                        </div>
                                                        : paymentmode == "savedcard" ?
                                                            <div className="cartsummary-footer">
                                                                <button type="button" className="btn btn-primary btn-full btn-large" disabled={spinnerLoading} onClick={(e) => ProceedToPaymentbysavedcard(1)}>Proceed to Payment (${(parseFloat(cartSummary.total_amount) + parseFloat(cartSummary.addon_amount) + parseFloat(deliveryAmountTotal) - parseFloat(CouponSession.discount_amount)).toFixed(2)})</button>
                                                            </div>
                                                            : ''}
                                        </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </BrowserView>
            <MobileView>
                <header className="mheader">
                    <div className="mheader-left"><i className="d-icon-arrow-left tx-22 me-3" onClick={() => { goBack() }}></i>Checkout
                    </div>
                    <div className="mheader-right">(3/3)</div>
                </header>
                {step == 1 ? <>
                    <div className="mwapper pb-5 mb-5">
                        <div className="checkoutPanel">

                            {AddressSession && AddressSession['delivery'] && AddressSession['delivery'].ua_id > 0 ?
                                <div className="checkoutPanel-address">
                                    <div className="changeaddress" onClick={(e) => { navigate('/cart-address') }}>Change</div>
                                    <h5 className="tx-15 mb-3">Shipping & Billing Address</h5>
                                    <h6 className="tx-14">{AddressSession['delivery'].ua_name}</h6>
                                    {AddressSession['delivery'].ua_house_no ? <p className="tx-14 mb-0">House No: {AddressSession['delivery'].ua_house_no}</p> : null}
                                    {AddressSession['delivery'].ua_floor ? <p className="tx-14 mb-0">Floor: {AddressSession['delivery'].ua_floor}</p> : null}
                                    <p className="tx-14 mb-0">{AddressSession['delivery'].ua_complete_address} {AddressSession['delivery'].ua_city_name ? ', ' + AddressSession['delivery'].ua_city_name : ''}</p>
                                </div>
                                :
                                <div className="checkoutPanel-address">
                                    <div className="changeaddress" onClick={(e) => { navigate('/cart') }}>Change</div>
                                    <h5 className="tx-15 mb-3">Pickup From</h5>
                                    <h6 className="tx-14">Daadi's Kitchen</h6>
                                    <p className="tx-14 mb-0">{adminData?.address}</p>
                                </div>
                            }
                            <div className="checkoutPanel-ordernote">
                                <label>Add Order Note</label>
                                <textarea name="shortnote" onChange={(e) => setShortNote(e.target.value)}></textarea>
                            </div>
                            <div className="checkoutOrdersummary">
                                <div className="checkoutOrdersummary-header" onClick={(e) => toggleOrderSummary()}>
                                    <h6 className="mb-0 tx-14 mt-1">Order Summary</h6>
                                    <i className="d-icon-angle-right"></i>
                                </div>
                                <div className="checkoutOrdersummary-content" style={{ display: 'none' }}>
                                    {Object.entries(mappedCartData).map(([day, items], index) => {
                                        let deliveryAmount = 0;
                                        let deliverytotalSum = items.reduce((total, item) => {
                                            const price =
                                                (parseFloat(item.product_selling_price) +
                                                    parseFloat(item.product_addon_price)) *
                                                Number(item.quantity);
                                            return total + price;
                                        }, 0);

                                        const conditionExists = items.some(item => item.product_type === 1);

                                        if (
                                            AddressSession &&
                                            AddressSession['delivery'] &&
                                            AddressSession['delivery'].ua_id > 0
                                        ) {
                                            if (conditionExists) {
                                                deliveryAmount =
                                                    deliverytotalSum > adminData?.shipping_amount_free
                                                        ? 0
                                                        : parseFloat(adminData?.shipping_amount_tiffin);
                                            } else {
                                                deliveryAmount =
                                                    deliverytotalSum > adminData?.shipping_amount_free
                                                        ? 0
                                                        : parseFloat(adminData?.shipping_amount_other);
                                            }

                                            deliveryAmountTotalMobile += deliveryAmount;
                                        } else {
                                            deliveryAmountTotalMobile = 0;
                                        }

                                        return (
                                            <div className="cItembSection" key={index}>
                                                {items.map((item, itemIndex) => (
                                                    <div className="cItemb" key={`${index}-${itemIndex}`}>
                                                        <div className="cItemb-media">
                                                            <img src={item.product_image} alt={item.product_name} />
                                                        </div>
                                                        <div className="cItemb-content">
                                                            <h6 className="fw600 mb-0">{item.product_name}</h6>
                                                            <p className="tx-gray mb-1">
                                                                {item.product_meal_time === 'lunch'
                                                                    ? item.adminData.lunch_note
                                                                    : item.adminData.dinner_note}
                                                            </p>
                                                            <p className="fw600 mb-0 mt-1">
                                                                ${(parseFloat(item.product_selling_price) +
                                                                    parseFloat(item.product_addon_price)).toFixed(2)}
                                                            </p>
                                                            <div className="cItemb-footer mt-1">
                                                                <div className="qty-changer">
                                                                    <button>
                                                                        <i className="fas fa-minus psbmiuns"></i>
                                                                    </button>
                                                                    <span>{item.quantity}</span>
                                                                    <button>
                                                                        <i className="fas fa-plus psbplus"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        );
                                    })}

                                </div>
                            </div>
                            {
                                CouponSession.discount_amount === 0 ? (
                                    <div className="applyCoupons" onClick={(e) => handleShowCoupon(true)}>
                                        <div className="d-flex align-items-center">
                                            <img src="/img/presents.png" width={25} style={{ marginRight: '10px' }}></img>
                                            <h6 className="mb-0 tx-14 mt-1">Apply Coupon</h6>
                                        </div>
                                        <div> <i className="d-icon-angle-right tx-22 mt-1"></i></div>
                                    </div>
                                ) : (
                                    <div className="applyCoupons" onClick={(e) => removeCoupon()}>
                                        <div className="d-flex align-items-center">

                                            <h6 className="mb-0 tx-14 mt-1">{CouponSession.promo_code} Applied</h6>
                                        </div>
                                        <div> <i className="fas fa-trash tx-18 mt-1 tx-theme"></i></div>
                                    </div>
                                )}
                            <div className="cartsummary">
                                <h6>Cart Summary</h6>
                                <ul>
                                    <li>Items Total<span>${(parseFloat(cartSummary.itemTotal) + parseFloat(cartSummary.addon_amount)).toFixed(2)}</span></li>
                                    <li>Discount<span>${parseFloat(cartSummary.discount).toFixed(2)}</span></li>
                                    <li>Coupon Discount<span>$ {CouponSession.discount_amount > 0 ? CouponSession.discount_amount : 0.00}</span></li>
                                    <li>Delivery {deliveryAmountTotalMobile > 0 ? <span>${deliveryAmountTotalMobile}</span> : <span className="tx-green">Free</span>}</li>
                                    <hr></hr>
                                    <li style={{ fontWeight: 'bold' }}>Total Amount<span>${(parseFloat(cartSummary.total_amount) + parseFloat(cartSummary.addon_amount) + parseFloat(deliveryAmountTotalMobile) - parseFloat(CouponSession.discount_amount)).toFixed(2)}</span></li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    <div className="mproduct-footer">
                        <button type="button" className="btn btn-primary btn-full btn-large" onClick={(e) => ProceedToPayment()}>Continue</button>
                    </div>
                </> :
                    <>
                        {Object.entries(mappedCartData).map(([day, items], index) => {
                            let deliveryAmount = 0;
                            let deliverytotalSum = items.reduce((total, item) => {
                                const price =
                                    (parseFloat(item.product_selling_price) +
                                        parseFloat(item.product_addon_price)) *
                                    Number(item.quantity);
                                return total + price;
                            }, 0);

                            const conditionExists = items.some(item => item.product_type === 1);

                            if (
                                AddressSession &&
                                AddressSession['delivery'] &&
                                AddressSession['delivery'].ua_id > 0
                            ) {
                                if (conditionExists) {
                                    deliveryAmount =
                                        deliverytotalSum > adminData?.shipping_amount_free
                                            ? 0
                                            : parseFloat(adminData?.shipping_amount_tiffin);
                                } else {
                                    deliveryAmount =
                                        deliverytotalSum > adminData?.shipping_amount_free
                                            ? 0
                                            : parseFloat(adminData?.shipping_amount_other);
                                }

                                deliveryAmountTotalMobile += deliveryAmount;
                            } else {
                                deliveryAmountTotalMobile = 0;
                            }
                        })}
                        <div className="mwapper pb-5 mb-5">
                            <div className="checkoutPanel">
                                <div className="cardselect">
                                    <h6>Select Payment Method</h6>
                                    <div className={`cardwallet ${paymentmode == "wallet" && !paybycard && !paybypaypal ? "active" : ""}`} onClick={walletOption}>
                                        <div className="cardwallet-price">${Number(walletblnce)}</div>
                                        <div>
                                            <h6 className="mb-0 tx-14">Pay Using Wallet</h6>
                                            <p className="mb-0 tx-12">Pay your order using wallet</p>
                                        </div>
                                        {paymentmode == "wallet" && !paybycard && !paybypaypal ? <div className="cardselectleft">
                                            <img src="/img/correct.png" className="cardsaveboxarrow"></img>
                                        </div> : ""}

                                    </div>
                                    <div className="cardselect-inner" >
                                        <div className={`cardselectbox ${paymentmode == "card" ? "active" : ""}`} onClick={cardOption}>
                                            <ul>
                                                <li><img src="/img/visa.svg" height={38}></img></li>
                                                <li><img src="/img/master.svg" height={38}></img></li>
                                                <li><img src="/img/amex.svg" height={38}></img></li>
                                            </ul>

                                        </div>
                                        {/*  <div className={`cardselectbox ${paymentmode == "paypal" ? "active" : ""}`} onClick={paypalOption}>
                                                    <ul>
                                                        <li><img src="/img/paypal.svg" height={38}></img></li>
                                                    </ul>


                                                </div> */}
                                    </div>

                                </div>
                                <div className="cardsection">
                                    {paymentmode == "card" || paymentmode == "savedcard" ? <>
                                        {savedcarddata?.length > 0 ? <>
                                            {savedcarddata?.map((items, index) => {
                                                return (
                                                    <div className="cardsavebox" key={index}>

                                                        <div className={selectedcardid == items.ucd_four_number ? "cardsaveboxnu active" : "cardsaveboxnu"} onClick={(e) => selectedcarddetail(items)}>
                                                            <div className="cardsavebox-icon">
                                                                <img src={cardImage(items?.ucd_card_type)} style={{ width: "40px", marginTop: "7px" }}></img>
                                                            </div>
                                                            <div className="cardsavebox-number">Ending with {items?.ucd_four_number}</div>
                                                            <img src="/img/correct.png" className="cardsaveboxselect"></img>
                                                            {selectedcardid !== items.ucd_four_number ? <>

                                                                {/* <img src="/img/radio-button.png" width={"20px"} className=""></img> */}
                                                            </> : ""}

                                                        </div>
                                                        {cardState?.number == items?.ucd_four_number ? <>
                                                            <div className="cardsavebox-cvv" style={{ width: "40%" }}>
                                                                <input type="number" name="cvc"
                                                                    className="form-control"
                                                                    placeholder="CVC"
                                                                    pattern="\d{3,4}"
                                                                    value={cardState?.cvc}
                                                                    onChange={(e) => { handlesavedcard(e, items) }}
                                                                    onFocus={handleInputFocus}
                                                                    required></input>
                                                                <img src="/img/cvv.svg" height={25} style={{ borderRadius: '4px' }}></img>
                                                            </div>
                                                        </> : ""}
                                                    </div>
                                                )
                                            })}

                                            <div className="cardsavebox" onClick={() => { addnewcarddetailfn() }}>
                                                <div className="cardsaveboxnu">
                                                    <div className="cardsavebox-icon"><img src="/img/atmcards.png" style={{ width: "40px", marginTop: "7px" }}></img></div>
                                                    <div className="cardsavebox-number">Use a different card</div>
                                                    <img src="/img/next.png" className="cardsaveboxarrow"></img>
                                                </div>
                                            </div>
                                            {addnewcard ?
                                                <div className="carddetal">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="crdform crdform-icon mb-3">
                                                                <label>Card Number</label>
                                                                <input type="text"
                                                                    name="number"
                                                                    className="form-control"
                                                                    placeholder="Card Number"
                                                                    value={cardState.number}
                                                                    onChange={handleInputChange}
                                                                ></input>
                                                                <div className="cicon"><img src="/img/cradicon.svg" height={45} style={{ borderRadius: '4px' }}></img></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="crdform mb-3">
                                                                <label>Expiry date</label>
                                                                <input type="text"
                                                                    name="expiry"
                                                                    className="form-control"
                                                                    placeholder="Valid Thru"
                                                                    pattern="\d\d/\d\d"
                                                                    value={cardState.expiry}
                                                                    onChange={handleInputChange}
                                                                ></input>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="crdform crdform-icon mb-3">
                                                                <label>CVV</label>
                                                                <input type="number"
                                                                    name="cvc"
                                                                    className="form-control"
                                                                    placeholder="CVC"
                                                                    pattern="\d{3,4}"
                                                                    value={cardState.cvc}
                                                                    onChange={handleInputChange}
                                                                ></input>
                                                                <div className="cicon"><img src="/img/cvv.svg" height={45} style={{ borderRadius: '4px' }}></img></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="crdform checkboxinput mb-3">
                                                                <input type="checkbox" onChange={(e) => { savecarddetail(e) }}></input>
                                                                Save card for next time
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                : ''}
                                        </> : <div className="carddetal">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="crdform crdform-icon mb-3">
                                                        <label>Card Number</label>
                                                        <input type="text"
                                                            name="number"
                                                            className="form-control"
                                                            placeholder="Card Number"
                                                            value={cardState.number}
                                                            onChange={handleInputChange}
                                                        ></input>
                                                        <div className="cicon"><img src="/img/cradicon.svg" height={45} style={{ borderRadius: '4px' }}></img></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="crdform mb-3">
                                                        <label>Expiry date</label>
                                                        <input type="text"
                                                            name="expiry"
                                                            className="form-control"
                                                            placeholder="Valid Thru"
                                                            pattern="\d\d/\d\d"
                                                            value={cardState.expiry}
                                                            onChange={handleInputChange}
                                                        ></input>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="crdform crdform-icon mb-3">
                                                        <label>CVV</label>
                                                        <input type="number"
                                                            name="cvc"
                                                            className="form-control"
                                                            placeholder="CVC"
                                                            pattern="\d{3,4}"
                                                            value={cardState.cvc}
                                                            onChange={handleInputChange}
                                                        ></input>
                                                        <div className="cicon"><img src="/img/cvv.svg" height={45} style={{ borderRadius: '4px' }}></img></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="crdform checkboxinput mb-3">
                                                        <input type="checkbox" onChange={(e) => { savecarddetail(e) }}></input>
                                                        Save card for next time
                                                    </div>
                                                </div>
                                            </div>

                                        </div>} </>
                                        : ''}

                                </div>
                            </div>
                        </div>
                        {paymentmode == "wallet" ? <>
                            <div className="mproduct-footer">
                                <button type="button" className="btn btn-primary btn-full btn-large" disabled={spinnerLoading} onClick={(e) => proceedtopaybywallet(0)}>Proceed to Payment (${(parseFloat(cartSummary.total_amount) + parseFloat(cartSummary.addon_amount) + parseFloat(deliveryAmountTotalMobile) - parseFloat(CouponSession.discount_amount)).toFixed(2)})</button>
                            </div>
                        </>
                            :
                            paymentmode == "paypal" ? <div className="p-3">
                                {/* <PayPalButtons style={{ layout: "horizontal" }}
                           /> */}
                                <PayPalScriptProvider options={initialOptions}>
                                    <PayPalButtons style={{ layout: "horizontal" }}
                                        createOrder={(data, actions) => {
                                            // Define the createOrder function
                                            return actions.order.create({
                                                // Additional order attributes
                                                purchase_units: [
                                                    {
                                                        amount: {
                                                            currency_code: "USD",
                                                            value: (
                                                                parseFloat(cartSummary.total_amount) +
                                                                parseFloat(cartSummary.addon_amount) +
                                                                parseFloat(deliveryAmountTotal) -
                                                                parseFloat(cartSummary.discount) -
                                                                parseFloat(CouponSession.discount_amount)
                                                            ).toFixed(2),
                                                        },
                                                    },
                                                ],
                                            });
                                        }}
                                        onApprove={(data, actions) => {
                                            return actions.order.capture().then(function (details) {
                                                console.log("details", details);
                                            });
                                        }}
                                    />
                                </PayPalScriptProvider>
                            </div>
                                : paymentmode == "card" ?
                                    <div className="mproduct-footer">
                                        <button type="button" className="btn btn-primary btn-full btn-large" disabled={spinnerLoading} onClick={(e) => makePaymentProcess(0)}>Proceed to Payment (${(parseFloat(cartSummary.total_amount) + parseFloat(cartSummary.addon_amount) + parseFloat(deliveryAmountTotalMobile) - parseFloat(CouponSession.discount_amount)).toFixed(2)})</button>
                                    </div>
                                    : paymentmode == "savedcard" ?
                                        <div className="mproduct-footer">
                                            <button type="button" className="btn btn-primary btn-full btn-large" disabled={spinnerLoading} onClick={(e) => ProceedToPaymentbysavedcard(0)}>Proceed to Payment (${(parseFloat(cartSummary.total_amount) + parseFloat(cartSummary.addon_amount) + parseFloat(deliveryAmountTotalMobile) - parseFloat(CouponSession.discount_amount)).toFixed(2)})</button>
                                        </div>
                                        : ''}
                    </>
                }

            </MobileView>
            <Modal show={showCoupon} onHide={handleCloseCoupon} className="couponModal">
                {showCoupon && <CouponModal
                    showCouponmodal={showCoupon}
                    onChildCouponData={handleChildCouponData} />}

            </Modal>
        </>
    );
};

export default CheckOut;