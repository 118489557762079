import Header from "../../component/Header";
import Footer from "../../component/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import {
  BrowserView,
  MobileView,
} from "react-device-detect";
import { ApiService } from "../../component/services/apiServices";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import MobileHeader from "../../component/Element/mobile_header";

const MenuList = () => {
  const navigate = useNavigate();
  let apiServices = new ApiService();
  const [categorydata, setcatagorydata] = useState("");
  const [menulistdata, setmenulistdata] = useState([]);
  const [baseimgUrl, setbaseimageUrl] = useState("");
  const didMountRef = useRef(true);
  const Userdata = JSON.parse(localStorage.getItem("USER_SESSION"));
  const { id } = useParams();
  useEffect(() => {
    if (didMountRef.current) {

      ApiService.commonGetRequest("menu-list").then((res) => {
        if (res?.status == "success") {
          setcatagorydata(res?.categoryData);
          setmenulistdata(res?.categoryData);
          setbaseimageUrl(res?.slider_img_path);
        }
      }).catch((error) => {

      });

    }
    didMountRef.current = false;
  });
  const getdiscountprice = (discount, mrp, sp) => {
    const discountprice = Number(discount);
    const mrpprice = Number(mrp);
    const discountperc = (discountprice / mrpprice) * 100;
    return discountperc;
  };
  return (
    <>

      <MobileView>

        <MobileHeader page_name={"Menu"} cart_count={3} navigateroute={"/"} />
        <div className="mwapper" >
          {menulistdata?.length > 0 ? (
            <>
              {menulistdata?.length > 0 &&
                menulistdata?.map((items, index) => {
                  return (
                    <>
                      <div className="menuname">{items?.cat_name}</div>
                      {items?.menuData.length > 0 ? (
                        <>
                          {items?.menuData.length > 0 &&
                            items?.menuData.map((items, index) => {
                              return (
                                <>
                                  <div
                                    className="hproduct"
                                    onClick={() => {
                                      navigate(
                                        `/product/${items?.product_slug}`
                                      );
                                    }}
                                  >
                                    <div className="row align-items-center">
                                      <div className="col-8">
                                        <h2 className="hproduct-name">
                                          {items?.product_name}
                                        </h2>
                                        <div className="hproduct-price">
                                          <ins className="new-price">
                                            ${items?.product_selling_price}
                                          </ins>
                                          {items?.product_discount > 0 ? (
                                            <>
                                              {" "}
                                              <del className="old-price">
                                                ${items?.product_price}
                                              </del>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {/* <del className="old-price">
                                            ${items?.product_price}
                                          </del> */}
                                        </div>
                                        {/* <div className="ratings-container">
                                          <div className="ratings-full">
                                            <span
                                              className="ratings"
                                              style={{ width: "60%" }}
                                            ></span>
                                          </div>
                                        </div> */}
                                      </div>
                                      <div className="col-4">
                                        <figure className="hproduct-media">
                                          <img
                                            src={
                                              items?.product_image
                                                ? items?.product_image
                                                : "./img/no_img1.jpg"
                                            }
                                          ></img>
                                          <div className="product-label-group">
                                            {items?.product_label_name?.split(', ')?.length > 0 ? (

                                              <>
                                                {items?.product_label_name?.split(', ')?.map((items) => {
                                                  return (<>
                                                    <label className="product-label label-new" key={items}>
                                                      {items}
                                                    </label>
                                                  </>)
                                                })}

                                              </>
                                            ) : (
                                              ""
                                            )}
                                            {getdiscountprice(items?.product_discount, items?.product_price, items?.product_selling_price) !== 0 && (
                                              <label className="product-label label-sale">
                                                {getdiscountprice(items?.product_discount, items?.product_price, items?.product_selling_price)}%
                                              </label>
                                            )}
                                          </div>
                                        </figure>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
            </>
          ) : (
            <>
              <div className="row align-items-center">
                <div className="col-8">
                  <div>
                    <Skeleton width={425} height={120} count={10} />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </MobileView>
    </>
  );
};
export default MenuList;
