import React, { useState, useEffect } from "react";
import {
  MobileView,
} from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../component/services/apiServices";

const Search = () => {
  const [searchdata, setsearchdata] = useState([]);
  const [productsearch, setproductsearch] = useState("");
  let apiServices = new ApiService();

  const navigate = useNavigate()
  const handlesearch = (e) => {
    let value = e.target.value;
    if (value == "" || value.length < 2) {
      setsearchdata("");
      return;
    }
    if (value.length > 2 || value.length == 2) {
      let dataString = {
        query: value,
      };
      apiServices.getsearchdataPostRequest(dataString).then((result) => {

        if (result.data.status == "success") {
          setsearchdata(result.data.data);
        }
      });
    }
  };

  const handleemptysearch = (e) => {
    e.preventDefault();
    if (searchdata == "" || searchdata == null) {

    } else {
    }
  };

  return (
    <>


      <MobileView>








        <header className="mheader">
          <div className="mheader-search">
            <input type="text" placeholder="Search for products, categories &amp; more..." autocomplete="off" onChange={(e) => {
              setproductsearch(e.target.value);
              handlesearch(e);
            }} />
            <div className="mheader-search-icon">
              <i className="d-icon-search"></i></div><div className="mheader-search-close" onClick={() => { navigate("/") }}><i className="d-icon-times"></i>
            </div>
          </div>
        </header>
        {searchdata && searchdata.length > 0 ? (
          <>

            <div className="msearch-list mt-5 pt-4">
              <ul>

                {searchdata?.map((items, index) => {
                  return (
                    <>
                     <a href={items?.redirect}>
                     <li
                        onClick={() => {
                          setsearchdata("");
                        }}
                        key={index}
                      >
                        {items?.name}
                      </li>
                      
                      
                      
                      </a> 
                    </>
                  );
                })}

              </ul>
            </div>







          </>) : ("")}


      </MobileView>
    </>
  );
};

export default Search;
