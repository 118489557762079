import moment from 'moment';

export const formatDate = (date) => {
    const formattedDateLong = moment(date, 'YYYY-MM-DD').format('LL');
    return { formattedDateLong };
};

export const formatTime = (time) => {
    const formattedHourMin = moment(time, 'HH:mm:ss').format('h:mm A');
    return { formattedHourMin };
};
