const localStorageData = () => {
    const addressSession = localStorage.getItem("ADDRESS_SESSION");
    const parsedAddressSession = addressSession ? JSON.parse(addressSession) : {};
  
    const couponSession = localStorage.getItem("COUPON_SESSION");
    const parsedCouponSession = couponSession ? JSON.parse(couponSession) : {discount_amount: 0.0,
      promo_id: 0,
      promo_code: "",
      cart_amount: 0.0,
    };
  
    const cartSession = localStorage.getItem("CART_SESSION");
    const parsedCartSession = cartSession ? JSON.parse(cartSession) : [];
    const parsedCartSummary = {
      itemTotal: 0.0,
      discount: 0,
      total_amount: 0.0,
      shipping_charge: 0,
      addon_amount: 0,
    };
    parsedCartSession.forEach((value) => {
      parsedCartSummary.itemTotal += parseFloat(value.product_price) * Number(value.quantity)
      parsedCartSummary.discount += Number(value.product_discount) * Number(value.quantity)
      parsedCartSummary.total_amount += parseFloat(parseFloat(value.product_selling_price) * Number(value.quantity))
      parsedCartSummary.addon_amount += parseFloat(value.product_addon_price) * Number(value.quantity)
    });
  
    const recentlyProductsSession = localStorage.getItem("RECENTLY_VIEWED");
    const parsedRecentlyProductsSession = recentlyProductsSession ? JSON.parse(recentlyProductsSession) : [];
  
    const userSessionData = localStorage.getItem("USER_SESSION");
    const parsedUserSession = userSessionData ? JSON.parse(userSessionData) : null;

    const dataArr = [];
    dataArr['AddressSession'] = parsedAddressSession;
    dataArr['CartSession'] = parsedCartSession;
    dataArr['CouponSession'] = parsedCouponSession;
    dataArr['CartSummary'] = parsedCartSummary;
    dataArr['RecentlyProductsSession'] = parsedRecentlyProductsSession;
    dataArr['UserSession'] = parsedUserSession;
  
    return dataArr;
  };
  
  export default localStorageData;
  