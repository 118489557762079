import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import { BrowserView, MobileView } from "react-device-detect";
import Accountsidebar from "../account_sidebar";
import Header from "../../../component/Header";
import Footer from "../../../component/Footer";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../../component/services/apiServices";
import SweetAlert from "react-bootstrap-sweetalert";
import SpinnerLoader from "../../../component/Element/utils/spinnerLoader";
import Skeleton from "react-loading-skeleton";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import Walletmodal from "../../../component/walletmodal";
import moment from "moment";



const UserWallet = () => {
  const [addwallet, setaddwallet] = useState(false)
  const [showwallethistory, setshowwallethistory] = useState(false)
  const [walletblnce, setwalletblnce] = useState()
  const [wallethistory, setwallethistory] = useState([])
  const navigate = useNavigate()
  const handleClose = () => {
    setaddwallet(false)
  }
  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {


      ApiService.commonGetRequest("wallet-balance").then((res) => {
        if (res?.status == "success") {
          setwalletblnce(res?.wallet_balance)
        }
      })

      ApiService.commonGetRequest("wallet-history").then((res) => {
        console.log(res, "response")
        if (res?.status == "success") {
          setwallethistory(res?.rowWalletInfo)
        }
      })
    }
    didMountRef.current = false;


  }, [])
  return (<>
    <BrowserView>
      <Header></Header>
      <div className="subheader">
        <Container>
          <Row>
            <Col lg={12}>
              <Breadcrumb className="breadcrumb-inner">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>My Wallet</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="">
        <section className="secsmall">
          <Container>
            <Row>
              <Accountsidebar />
              <Col lg={9}>
                <div className="acpanel">
                  <div className="acpanel-header">
                    <h4>My Wallet</h4>
                  </div>
                  <div className="acpanel-body">
                    <div className="d-flex justify-content-between">
                      <div>
                        Total Wallet Balance: ${Number(walletblnce)}  <span></span>
                      </div>
                      {wallethistory?.length > 0 ? <>
                        <div >
                          <button className="btn btn-primary" onClick={() => { setshowwallethistory(true) }}>View History</button>
                        </div>


                      </> : ""}

                    </div>


                    <button className="btn btn-primary" onClick={() => {
                      setaddwallet(true)
                    }} >Add Money To Wallet</button>

                    {wallethistory?.length > 0 && showwallethistory ? <>
                      <div class="card-table-section">
                        <table class="table table-hover">
                          <thead>
                            <tr>
                              <th class="text-center">S.No</th>
                              <th>Transaction Id</th>
                              <th>Type</th>
                              <th class="text-center">Mode</th>
                              <th class="text-center">Date</th>
                              <th class="text-center">Opening Balance</th>
                              <th class="text-center">Amount</th>
                              <th class="text-center">Closing Balance</th>
                            </tr>
                          </thead>
                          <tbody>
                            {wallethistory?.map((items, index) => {
                              return (<>
                                <tr>
                                  <td class="text-center">{index + 1}</td>

                                  <td class="text-center">
                                    {items?.uw_order_id}

                                  </td>
                                  <td class="text-center">{items?.uw_trans_type == "P" ? "Credit" : "Debit"}</td>
                                  <td class="text-center">{items?.uw_add_type == 1 ? "Card" : "Paypal"}</td>
                                  <td class="text-center">{moment(items?.created_at).format(`DD-MM-YYYY`)}</td>
                                  <td class="text-center"> {items?.uw_last_bal}</td>
                                  <td class="text-center">{(items?.uw_trans_type == "P" ? "+" : "-") + items?.uw_amount_add}</td>
                                  <td class="text-center">{items?.uw_after_bal}</td>
                                </tr>
                              </>)
                            })}

                          </tbody>
                        </table>
                        <div><button className="btn btn-primary" onClick={() => { setshowwallethistory(false) }} >Close</button></div>
                      </div>
                    </> : ""}


                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <Footer></Footer>

    </BrowserView>
    <MobileView>
      <header className="mheader">
        <div className="mheader-left">
          <i
            className="d-icon-arrow-left tx-22"
            onClick={() => {
              navigate("/account/account-overview");
            }}
          ></i>
          <p className="mb-0 mt-1 ms-3">My Wallet</p>
        </div>
      </header>
      <div className="morderbox mt-5 pt-4" key={5}>
        <div className="morderbox-body">
          <div className="row">
            <div className="col-7">
              <div className="d-flex justify-content-between">
                <div>
                  Total Wallet Balance: ${Number(walletblnce)}  <span></span>
                </div>
                {wallethistory?.length > 0 ? <>
                  <div >
                    <button className="btn btn-primary" onClick={() => { setshowwallethistory(true) }}>View History</button>
                  </div>
                </> : ""}
              </div>
              {wallethistory?.length > 0 && showwallethistory ? <>
                <div class="card-table-section">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th class="text-center">S.No</th>
                        <th>Transaction Id</th>
                        <th>Type</th>
                        <th class="text-center">Mode</th>
                        <th class="text-center">Date</th>
                        <th class="text-center">Opening Balance</th>
                        <th class="text-center">Amount</th>
                        <th class="text-center">Closing Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {wallethistory?.map((items, index) => {
                        return (<>
                          <tr>
                            <td class="text-center">{index + 1}</td>

                            <td class="text-center">
                              {items?.uw_order_id}

                            </td>
                            <td class="text-center">{items?.uw_trans_type == "P" ? "Credit" : "Debit"}</td>
                            <td class="text-center">{items?.uw_add_type == 1 ? "Card" : "Paypal"}</td>
                            <td class="text-center">{moment(items?.created_at).format(`DD-MM-YYYY`)}</td>
                            <td class="text-center"> {items?.uw_last_bal}</td>
                            <td class="text-center">{(items?.uw_trans_type == "P" ? "+" : "-") + items?.uw_amount_add}</td>
                            <td class="text-center">{items?.uw_after_bal}</td>
                          </tr>
                        </>)
                      })}

                    </tbody>
                  </table>
                  <div><button className="btn btn-primary" onClick={() => { setshowwallethistory(false) }} >Close</button></div>
                </div>
              </> : ""}
            </div>
          </div>
        </div>
        <div className="morderbox-status " style={{ display: 'inline-block' }}>
          <button className="btn btn-primary" onClick={() => {
            setaddwallet(true)
          }} >Add Money To Wallet</button>
        </div>
      </div>
      <Footer></Footer>
    </MobileView>
    {addwallet ? <>

      <Walletmodal show={addwallet} handleClose={handleClose}></Walletmodal>

    </> : null}

  </>)
}

export default UserWallet