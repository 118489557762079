import React, { useEffect, useState, useRef } from "react";
import { BrowserView } from "react-device-detect";
import localStorageData from "../Element/utils/localStorageData";

const CartHeader = ({step}) => {
  const didMountRef = useRef(true);
  const dataArray = localStorageData();
  //console.log("dataArray",dataArray);
  const parsedCartSession = dataArray['CartSession'];
  const userSession = dataArray['UserSession'];
  const AddressSession = dataArray['AddressSession'];

  const [activeStates, setActiveStates] = useState({
    cartActive: '',
    addressActive: '',
    checkoutActive: ''
  });

  useEffect(() => {
    if (didMountRef.current) {
      const baseURLString = window.location.pathname.replace(/\//g, '');
      let cartActive = '';
      let addressActive = '';
      let checkoutActive = '';

      if (baseURLString === 'cart' || baseURLString === 'cart-address' || baseURLString === 'checkout') {
        cartActive = baseURLString === 'cart' || baseURLString === 'cart-address' || baseURLString === 'checkout' ? 'active' : '';
        addressActive = baseURLString === 'cart-address' || baseURLString === 'checkout' ? 'active' : '';
        checkoutActive = baseURLString === 'checkout' ? 'active' : '';
      }

      setActiveStates({
        cartActive,
        addressActive,
        checkoutActive
      });
    }
    didMountRef.current = false;
  }, []);

  return (
    <>
      <BrowserView>
        <header className="header cartheader">
          <div className="container">
            <div className="header-left">
              <a href="/" className="logo">
                <img src="/logo192.png" alt="" />
              </a>
            </div>
            <div className="header-center">
              <div className="step-by">
                <h3 className={`title-simple title-step title-step1 ${activeStates.cartActive}`}>
                  <a href="/cart">My Cart</a>
                </h3>
                <h3 className={`title-simple title-step title-step1 ${activeStates.addressActive}`}>
                  <a href="javascript:void(0)">Address</a>
                </h3>
                <h3 className={`title-simple title-step title-step1 ${activeStates.checkoutActive}`}>
                  <a href="javascript:void(0)">Payment</a>
                </h3>
                {step==1 ?<></>:""}
              </div>
            </div>
            <div className="header-right justify-content-end">
              <img src="/img/100SECURE.png" className="secure-img" alt="Secure" />
            </div>
          </div>
        </header>
      </BrowserView>
    </>
  );
}

export default CartHeader;
