import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DataProvider } from './component/Element/context/index';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

const root = ReactDOM.createRoot(document.getElementById('root'));
const initialOptions = {
  clientId: "AbToFBAeDlezkwNZ9KlKwsO3eeQsBuB9jWGAaKK7mALuHeVWvGoBuxr_TPTNCJlgnFX4yp0evU5pbRxA",
  currency: "USD",
  intent: "capture",
}; 
root.render(
  <React.StrictMode>
       <DataProvider>
        <App />
        </DataProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
